// Import libraries
import React from "react";
import PropTypes from "prop-types";

// Import high order components
import { withLanguage } from "../../../../../contexts/language/index";

// Import widgets
import TestStepCounter from "../../../../widgets/test_step_counter/TestStepCounter";

// Import components
import TestStepHeader from "../shared/TestStepHeader";
import TestStepButton from "../shared/TestStepButton";

// Import CSS
import "../../../../../styles/components/private/test_battery/test_steps/common/TestStepFinishView.css";

class TestStepFinishView extends React.Component {
  constructor() {
    super();
    this.state = {
      button_text: "",
      information_text: "",
    };
  };

  componentDidMount() {
    if (this.props.test_step < 2) {
      this.setState({
        button_text: this.props.language.retrieve_string("testBattery.buttons.nextTestStep"),
        information_text: this.props.language.retrieve_string("testBattery.testStepFinish.next_step_information"),
      });
    } else {
      this.setState({
        button_text: this.props.language.retrieve_string("testBattery.buttons.finishTestBattery"),
        information_text: this.props.language.retrieve_string("testBattery.testStepFinish.test_finalise_information"),
      });
    }
  };

  componentWillUnmount() {
    this.setState({ button_text: "", button_action: () => {}, information_text: "" });
  };

  render() {
    return (
      <div id="test_battery_step_finish_container" className="col-12">
        <TestStepHeader test_step={this.props.test_step} />
        <div id="test_battery_step_finish_text_container" className="row mx-auto align-content-center">
          <div className="container">
            <div id="test_battery_step_finish_explanation_text_container" className="row mx-auto align-content-center">
              <h1 className="test_battery_step_finish_participation_thank row align-content-center justify-content-center text-center">
                {this.props.language.retrieve_string("testBattery.testStepFinish.participation_thank")}
              </h1>
              <h1 className="test_battery_step_finish_finalised row align-content-center justify-content-center text-center">
                {this.props.language.retrieve_string("testBattery.testStepFinish.step_finalised")}
              </h1>
              <h1 className="test_battery_step_finish_information row align-content-center justify-content-center text-center">{this.state.information_text}</h1>
            </div>
            <TestStepCounter test_step={this.props.test_step} explanation={false} />
          </div>
        </div>
        <TestStepButton onClick_action={this.props.button_action} button_text={this.state.button_text} />
      </div>
    );
  }
};

TestStepFinishView.propTypes = {
  test_step: PropTypes.number.isRequired,
  button_action: PropTypes.func.isRequired,
};

export default withLanguage(TestStepFinishView);
