function TestBatteryAudioFiles() {
  return {
    Conversation_audio_file: null,
    Conversation_audio_playing: false,
    SVF_audio_file: null,
    SVF_audio_playing: false,
    Image_Description_audio_file: null,
    Image_Description_audio_playing: false,
  };
}

export default TestBatteryAudioFiles;
