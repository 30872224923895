// Import libraries
import React from 'react';
import PropTypes from 'prop-types';

// Import context
import LanguageContext from '../../../contexts/language/context/LanguageContext';

// Import locales
import es from '../../../resources/locales/es';
import en from '../../../resources/locales/en';

class Text extends React.Component {
    constructor() {
        super();
        this.getPropertyValue = this.getPropertyValue.bind(this);
    }

    static contextType = LanguageContext;

    getPropertyValue(obj1, dataToRetrieve) {
        return dataToRetrieve
            .split('.')
            .reduce(function (o, k) {
                return o && o[k];
            }, obj1)
    }

    render() {
        if (this.context.language === 'en-UK') {
            return (this.getPropertyValue(en, this.props.path))
        } else {
            return (this.getPropertyValue(es, this.props.path))
        }
    }
}

Text.propTypes = {
    path: PropTypes.string.isRequired
}

export default Text;