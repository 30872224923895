// Import libraries
import React from 'react';
import PropTypes from 'prop-types';

// Import CSS
import '../../../styles/widgets/sound_visualizer/SoundVisualizer.css';

class SoundVisualizer extends React.Component {
    constructor() {
        super();

        this.function_get_bar_height = this.function_get_bar_height.bind(this);
        this.function_get_bar_color = this.function_get_bar_color.bind(this);
    }

    function_get_bar_height = (sound_value) => {
        if (sound_value > 100) {
            return '100%'
        } else if (sound_value <= 1) {
            return '1%';
        } else {
            return `${sound_value}%`
        }
    }

    function_get_bar_color = (sound_value) => {
        if (sound_value <= 0) {
            return 'transparent';
        } else if (sound_value > 0 && sound_value <= 20) {
            return '#B6D7FF';
        } else if (sound_value > 20 && sound_value <= 40) {
            return '#A5C6FF';
        } else if (sound_value > 40 && sound_value <= 60) {
            return '#7293FD';
        } else if (sound_value > 60 && sound_value <= 80) {
            return '#3F60CA';
        } else if (sound_value > 80) {
            return '#0C2D97';
        }
    }

    render() {
        const sound_bars = []

        for (let i = 0; i < 50; i++) {
            if (i !== 49) {
                sound_bars.push(<div key={`sound_bar_${i}`} className="widget_sound_visualizer_sound_bar justify-content-center align-content-center" style={{ height: this.function_get_bar_height(this.props.sound_values[i]), backgroundColor: this.function_get_bar_color(this.props.sound_values[i]) }}></div>);
                sound_bars.push(<div key={`sound_bar_interstitial_${i}`} className="widget_sound_visualizer_sound_bar_interstitial"></div>);
            } else {
                sound_bars.push(<div key={`sound_bar_${i}`} className="widget_sound_visualizer_sound_bar justify-content-center align-content-center" style={{ height: this.function_get_bar_height(this.props.sound_values[i]), backgroundColor: this.function_get_bar_color(this.props.sound_values[i]) }}></div>);
            }
        }

        return (
            <div id="widget_sound_visualizer_container" style={{ height: `${this.props.height}%` }} className="row">{sound_bars}</div >
        )
    }
}

SoundVisualizer.propTypes = {
    height: PropTypes.number.isRequired,
    sound_values: PropTypes.array.isRequired
}

export default SoundVisualizer;