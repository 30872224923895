// Import libraries
import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

// Import high order components
import { withLanguage } from "../../../../../contexts/language/index";

// Import components
import TestStepCounter from "../../../../widgets/test_step_counter/TestStepCounter";

// Import CSS
import "../../../../../styles/components/private/test_battery/test_steps/shared/TestStepExplanation.css";

class TestStepExplanation extends React.Component {
  render() {
    switch (this.props.test_step) {
      case 0:
        return (
          <div id="test_step_explanation_view_explanation_container" className="row mx-auto justify-content-center align-content-center">
            <div className="container">
              <div id="test_step_explanation_text_container" className="row mx-auto align-content-center">
                <p className="test_step_explanation_text text-justify">{ReactHtmlParser(this.props.language.retrieve_string("testBattery.testStep.SVF.explanation"))}</p>
                <p className="test_step_subexplanation justify-content-center">{ReactHtmlParser(this.props.language.retrieve_string("testBattery.testStep.SVF.explanation_1"))}</p>
              </div>
              <TestStepCounter test_step={this.props.test_step} explanation={true} />
            </div>
          </div>
        );
      case 1:
        return (
          <div id="test_step_explanation_view_explanation_container" className="row mx-auto justify-content-center align-content-center">
            <div className="container">
              <div id="test_step_explanation_text_container" className="row mx-auto align-content-center">
                <p className="test_step_explanation_text text-justify">{this.props.language.retrieve_string("testBattery.testStep.Image_Description.explanation")}</p>
                <p className="test_step_subexplanation justify-content-center">{ReactHtmlParser(this.props.language.retrieve_string("testBattery.testStep.Image_Description.explanation_1"))}</p>
              </div>
              <TestStepCounter test_step={this.props.test_step} explanation={true} />
            </div>
          </div>
        );
      case 2:
        return (
          <div id="test_step_explanation_view_explanation_container" className="row mx-auto justify-content-center align-content-center">
            <div className="container">
              <div id="test_step_explanation_text_container" className="row mx-auto align-content-center">
                <p className="test_step_explanation_text text-justify">{this.props.language.retrieve_string("testBattery.testStep.Conversation.explanation")}</p>
                <p className="test_step_subexplanation justify-content-center">{ReactHtmlParser(this.props.language.retrieve_string("testBattery.testStep.Conversation.explanation_1"))}</p>
              </div>
              <TestStepCounter test_step={this.props.test_step} explanation={true} />
            </div>
          </div>
        );

      default:
        return <div></div>;
    }
  }
};

TestStepExplanation.propTypes = {
  test_step: PropTypes.number.isRequired,
};

export default withLanguage(TestStepExplanation);
