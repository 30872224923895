// Import libraries
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Form } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, Button } from "@material-ui/core";

// Import high order components
import { withLanguage } from "../../../contexts/language/index";
import { withFirebase } from "../../../contexts/firebase/index";

// Import resources
import * as ROUTES from "../../../resources/constants/routes";
import background_image from "../../../resources/images/background-image/BackgroundImage.jpg";

// Import CSS
import "../../../styles/components/authentication/signin.css";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email_address: "",
      password: "",
      sign_in_error: false,
      sign_in_loading: false,
      sign_up_modal_show: false,
      new_user_email_address: "",
      new_user_password: "",
      new_user_repeat_password: "",
      new_user_terms_and_conditions: false,
      new_user_email_error: false,
      new_user_password_error: false,
      new_user_password_repeat_error: false,
      new_user_terms_and_conditions_error: false,
      new_user_submit_error: false,
    };
    this.function_open_new_user_modal = this.function_open_new_user_modal.bind(this);
    this.function_close_new_user_modal = this.function_close_new_user_modal.bind(this);
    this.function_create_new_user_account = this.function_create_new_user_account.bind(this);
  }

  // Common functions
  componentWillUnmount() {
    this.setState({
      email_address: "",
      password: "",
      sign_in_error: false,
      new_user_email_address: "",
      new_user_password: "",
      new_user_repeat_password: "",
      newUserTermsAndCondition: false,
      new_user_email_error: false,
      new_user_password_error: false,
      new_user_password_repeat_error: false,
      new_user_terms_and_conditions_error: false,
      new_user_submit_error: false,
    });
  }

  // Function that updates the state values of the component when the values in the form change.
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Function that opens the modal view of the sign up formulary.
  function_open_new_user_modal() {
    this.setState({ sign_up_modal_show: true });
  }

  // Function that closes the modal view of the sign up formulary.
  function_close_new_user_modal() {
    this.setState({
      new_user_email_address: "",
      new_user_password: "",
      new_user_repeat_password: "",
      new_user_terms_and_conditions: false,
      new_user_email_error: false,
      new_user_password_error: false,
      new_user_password_repeat_error: false,
      new_user_terms_and_conditions_error: false,
      new_user_submit_error: false,
      sign_up_modal_show: false,
    });
  }

  // SIGN IN FUNCTIONS
  // Function that handles the submission of the sign in formulary.
  function_submit_sign_in_form = (event) => {
    event.preventDefault();

    this.setState({ sign_in_error: false, sign_in_errorMessage: "", sign_in_loading: true });

    this.props.firebase
      .SignInWithEmailAndPassword(this.state.email_address, this.state.password)
      .then(() => {
        this.setState({ ...this.state });
        this.setState({ sign_in_loading: false });
        this.props.history.push(ROUTES.PROFILE);
      })
      .catch((error) => {
        this.setState({ sign_in_error: true, sign_in_loading: false });
      });
  };

  // Function that handles the submission of the sign up formulary.
  function_create_new_user_account = (event) => {
    event.preventDefault();

    // Restarts the validation values in the state of the component.
    this.setState({ new_user_email_error: false, new_user_password_error: false, new_user_password_repeat_error: false, new_user_terms_and_conditions_error: false, new_user_submit_error: false });

    // Validates all the fields
    this.email_addressFormatValidation();
    this.passwordFormatValidation();
    this.passwordRepeatValidation();
    this.termsAndConditionsValidation();

    if (!this.state.new_user_email_error && !this.state.new_user_password_error && !this.state.new_user_password_repeat_error && !this.state.new_user_terms_and_conditions_error) {
      this.props.firebase
        .CreateUserWithEmailAndPassword(this.state.new_user_email_address, this.state.new_user_password)
        .then((authUser) => {
          // Refreshes the state of the component
          this.props.history.push(ROUTES.PROFILE);
        })
        .catch((error) => {
          this.setState({ new_user_submit_error: true });
        });
    }

    event.preventDefault();
  };

  // Function that checks if the format of the email address is correct.
  email_addressFormatValidation() {
    let emailValidation = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(
      String(this.state.new_user_email_address).toLowerCase()
    );
    this.setState({ new_user_email_error: !emailValidation });
  }

  // Fuction that checks if the format of the password is correct.
  passwordFormatValidation() {
    let passwordValidation = new RegExp(process.env.REACT_APP_PASSWORDREGEX).test(String(this.state.new_user_password));
    this.setState({ new_user_password_error: !passwordValidation });
  }

  // Function that checks if the value of the 'Repeat password' field matches de value of the 'Password' field.
  passwordRepeatValidation() {
    if (this.state.new_user_password !== this.state.new_user_repeat_password) {
      this.setState({ new_user_password_repeat_error: true });
    }
  }

  // Function that checks if the user has accepted the terms and conditions of use of the web app.
  termsAndConditionsValidation() {
    if (!this.state.new_user_terms_and_conditions) {
      this.setState({ new_user_terms_and_conditions_error: true });
    }
  }

  render() {
    return (
      <div id="sign_in_page" className="container-fluid">
        <img id="sign_in_page_background_image" src={background_image} alt="acceXible background" />
        <div id="sign_in_page_container" className="row align-items-center">
          <div className="col-12 col-xs-12 col-sm-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-3 col-xl-6">
            <Form id="sign_in_form_container">
              {this.props.language.retrieve_string("authentication.sign_in.welcome")}
              <FormControl fullWidth style={{ marginTop: "1.25vw", marginBottom: "0.75vw" }}>
                <TextField
                  error={this.state.sign_in_error}
                  id="standard-basic"
                  label={this.props.language.retrieve_string("authentication.sign_in.email_address")}
                  name="email_address"
                  onChange={this.onChange}
                  style={{ backgroundColor: "transparent" }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginTop: "0.75vw", marginBottom: "1.25vw" }}>
                <TextField
                  error={this.state.sign_in_error}
                  helperText={this.state.sign_in_error ? this.props.language.retrieve_string("authentication.sign_in.invalid_data_error") : null}
                  id="standard-error-helper-text"
                  type="password"
                  label={this.props.language.retrieve_string("authentication.sign_in.password")}
                  name="password"
                  onChange={this.onChange}
                />
              </FormControl>
              <Button id="sign_in_form_log_in_button" variant="contained" onClick={this.function_submit_sign_in_form} disabled={this.state.sign_in_loading}>
                {this.state.sign_in_loading && (
                  <CircularProgress
                    style={{ color: "#f8f8ff", width: document.documentElement.clientWidth > 992 ? "2.625vw" : "7.5vw", height: document.documentElement.clientWidth > 992 ? "2.625vw" : "7.5vw" }}
                  />
                )}
                {!this.state.sign_in_loading && this.props.language.retrieve_string("common.buttons.sign_in")}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter, withFirebase, withLanguage)(SignIn);
