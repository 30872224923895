// Import libraries
import React from "react";
import { Nav, NavItem } from "react-bootstrap";

// Import high order components
import { withFirebase } from "../../../contexts/firebase/index";

// Import widgets
import Text from "../../widgets/text/Text";

const SignOut = ({ firebase }) => (
  <NavItem eventkey={0}>
    <Nav.Link id="widget_navbar_sign_out_link" onClick={firebase.SignOut}>
      <Text path="common.navigationBar.sign_out" />
    </Nav.Link>
  </NavItem>
);

export default withFirebase(SignOut);
