// Import libraries
import React from "react";
import PropTypes from "prop-types";
import { Button, ProgressBar } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { FaVolumeUp, FaPlayCircle, FaStopCircle, FaCloudUploadAlt } from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";

// Import Firebase
import * as firebase from "firebase/app";
import "firebase/storage";

// Import services
import FirestoreService from "../../../../services/firestore/FirestoreService";

// Import objects
import FileUploadInformation from "../../../../objects/file_upload_information/Object_FileUploadInformation";
import TestBatteryAudioFiles from "../../../../objects/test_battery_audio_files/TestBatteryAudioFiles";

// Import high order components
import { withLanguage } from "../../../../contexts/language/index";

// Import CSS
import "../../../../styles/components/private/test_battery/test_battery_results/TestBatteryResults.css";

class TestBatteryResults extends React.Component {
  constructor() {
    super();
    this.state = {
      table_height: 0,
      upload_information: FileUploadInformation(),
      audio_files: TestBatteryAudioFiles(),
      open_modal: false,
      sequence_number: 0,
      timestamp: 0,
      all_files_uploaded: false,
    };
    this.function_open_modal = this.function_open_modal.bind(this);
    this.function_close_modal = this.function_close_modal.bind(this);
    this.function_play_audio_file = this.function_play_audio_file.bind(this);
    this.function_stop_audio_file = this.function_stop_audio_file.bind(this);
    this.function_play_json_file = this.function_play_json_file.bind(this);
    this.function_upload_audio_file = this.function_upload_audio_file.bind(this);
    this.function_upload_json_file = this.function_upload_json_file.bind(this);
    this.function_upload_all_files = this.function_upload_all_files.bind(this);
    this.function_progress_bar_color = this.function_progress_bar_color.bind(this);
  }

  componentDidMount() {
    this.setState({
      table_height: this.refs.test_battery_results_table_container.clientHeight,
    });
    this.props.update_test_battery("BatteryCompleted", true);
    // Create record of the test battery in the database
    new FirestoreService().create_record_firestore(this.props.test_battery);
  }

  componentDidUpdate() {
    if (this.state.upload_information.Conversation_success && this.state.upload_information.SVF_success && !this.state.all_files_uploaded) {
      this.setState({ all_files_uploaded: true });
    }
  }

  componentWillUnmount() {
    if (this.interval_paint_json) {
      clearInterval(this.interval_paint_json);
    }

    if (this.modal_open_timer) {
      clearTimeout(this.modal_open_timer);
      this.modal_open_timer = 0;
    }

    this.setState({
      upload_information: FileUploadInformation(),
      audio_files: TestBatteryAudioFiles(),
      open_modal: 0,
      sequence_number: 0,
      timestamp: 0,
    });
  }

  function_open_modal = () => {
    this.setState({ open_modal: true });
    this.modal_open_timer = setTimeout(() => {
      this.modal_open_timer = 0;
      let canvas_container = document.getElementById("test_battery_results_watch_picture_canvas");
      this.canvas.height = canvas_container.offsetHeight;
      this.canvas.width = canvas_container.offsetWidth;
      this.ctx = this.canvas.getContext("2d");
      this.ctx.lineJoin = "round";
      this.ctx.lineCap = "round";
      this.ctx.lineWidth = 2.5;
    }, 10);
  };

  function_close_modal = () => {
    this.setState({ open_modal: false, sequence_number: 0, timestamp: 0 });
  };

  function_play_audio_file = (audio_file_reference, audio_url, audio_playing_reference) => {
    let updated_audio_files = this.state.audio_files;

    if (updated_audio_files[audio_file_reference] === null) {
      updated_audio_files[audio_file_reference] = new Audio(audio_url);
    }

    updated_audio_files[audio_playing_reference] = true;

    this.setState({ audio_files: updated_audio_files });
    this.state.audio_files[audio_file_reference].play();
    this.state.audio_files[audio_file_reference].addEventListener("ended", () => {
      this.function_stop_audio_file(null, audio_playing_reference);
    });
  };

  function_stop_audio_file = (audio_file_reference, audio_playing_reference) => {
    let updated_audio_files = this.state.audio_files;

    if (audio_file_reference === null) {
      updated_audio_files[audio_playing_reference] = false;
    } else {
      if (this.state.audio_files[audio_playing_reference]) {
        this.state.audio_files[audio_file_reference].pause();

        let updated_audio_files = this.state.audio_files;
        updated_audio_files[audio_file_reference].currentTime = 0;
        updated_audio_files[audio_playing_reference] = false;
      }
    }
    this.setState({ audio_files: updated_audio_files });
  };

  function_play_json_file = () => {
    if (this.interval_paint_json) {
      clearInterval(this.interval_paint_json);
    }
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    let sequence_number = 0;
    let timestamp = 0;

    fetch(this.props.test_battery_urls.WatchPicture_url).then((result) => {
      result.blob().then((blob) => {
        let json_file = null;
        let reader = new FileReader();
        reader.readAsText(blob);
        reader.onload = () => {
          json_file = JSON.parse(reader.result);

          this.interval_paint_json = setInterval(() => {
            let selected_point = json_file[sequence_number];
            timestamp = timestamp + 1;

            if (timestamp === selected_point.timestamp_difference) {
              this.function_paint(selected_point.position_data.start, selected_point.position_data.stop);

              if (sequence_number === json_file.length - 1) {
                if (this.interval_paint_json) {
                  clearInterval(this.interval_paint_json);
                }
              } else {
                sequence_number = sequence_number + 1;
              }
            }
          }, 0.001);
        };
      });
    });
  };

  function_paint = (previousPosition, currentPosition) => {
    const { offsetX, offsetY } = currentPosition;
    const { offsetX: x, offsetY: y } = previousPosition;

    this.ctx.beginPath();
    this.ctx.strokeStyle = "#515fc9";
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(offsetX, offsetY);
    this.ctx.stroke();
  };

  function_upload_audio_file = (file_name, file_blob, test_battery_success, progress_variable, success_variable, error_variable) => {
    let storage_reference = firebase.storage().ref();

    let audio_storage_reference = storage_reference.child(`AcceXibleApp/Audios/${this.props.test_battery.PatientID}/${this.props.test_battery.BatteryID}/${file_name}`);

    fetch(file_blob).then((result) => {
      result.blob().then((blob) => {
        let file_upload_task = audio_storage_reference.put(blob, {
          contentType: "audio/wav",
        });

        file_upload_task.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            let updated_upload_information = this.state.upload_information;

            switch (snapshot.state) {
              case firebase.storage.TaskState.SUCCESS:
                break;
              case firebase.storage.TaskState.ERROR:
                updated_upload_information[error_variable] = true;
                updated_upload_information[progress_variable] = 100;
                break;
              case firebase.storage.TaskState.RUNNING:
                updated_upload_information[progress_variable] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                break;
              default:
                break;
            }

            this.setState({ upload_information: updated_upload_information });
          },
          (error) => {
            let updated_upload_information = this.state.upload_information;

            console.log("Error uploading file", error);
            updated_upload_information[error_variable] = true;
            updated_upload_information[progress_variable] = 100;

            this.setState({ upload_information: updated_upload_information });
          },
          () => {
            let updated_upload_information = this.state.upload_information;

            this.props.update_test_battery(test_battery_success, true);
            new FirestoreService().update_record_firestore(test_battery_success, true, this.props.test_battery.BatteryID, this.props.test_battery.PatientID, this.props.test_battery.BatteryUploaded);
            updated_upload_information[progress_variable] = 100;
            updated_upload_information[success_variable] = true;

            this.setState({ upload_information: updated_upload_information });
          }
        );
      });
    });
  };

  function_upload_json_file = (file_name, file_blob, test_battery_success, progress_variable, success_variable, error_variable) => {
    let storage_reference = firebase.storage().ref();
    let json_storage_reference = storage_reference.child(`AcceXibleApp/JSON/${this.props.test_battery.PatientID}/${this.props.test_battery.BatteryID}/${file_name}`);

    fetch(file_blob).then((result) => {
      result.blob().then((blob) => {
        let file_upload_task = json_storage_reference.put(blob, {
          contentType: "application/octet-stream",
        });

        file_upload_task.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            let updated_upload_information = this.state.upload_information;

            switch (snapshot.state) {
              case firebase.storage.TaskState.SUCCESS:
                break;
              case firebase.storage.TaskState.ERROR:
                updated_upload_information[error_variable] = true;
                updated_upload_information[progress_variable] = 100;
                break;
              case firebase.storage.TaskState.RUNNING:
                updated_upload_information[progress_variable] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                break;
              default:
                break;
            }

            this.setState({ upload_information: updated_upload_information });
          },
          (error) => {
            let updated_upload_information = this.state.upload_information;

            console.log("Error uploading file", error);
            updated_upload_information[error_variable] = true;
            updated_upload_information[progress_variable] = 100;

            this.setState({ upload_information: updated_upload_information });
          },
          () => {
            let updated_upload_information = this.state.upload_information;

            this.props.update_test_battery(test_battery_success, true);
            new FirestoreService().update_record_firestore(test_battery_success, true, this.props.test_battery.BatteryID, this.props.test_battery.PatientID, this.props.test_battery.BatteryUploaded);

            updated_upload_information[progress_variable] = 100;
            updated_upload_information[success_variable] = true;

            this.setState({ upload_information: updated_upload_information });
          }
        );
      });
    });
  };

  function_upload_all_files = () => {
    if (!this.state.upload_information.Conversation_success) {
      this.function_upload_audio_file(
        this.props.test_battery.Conversation_Filename,
        this.props.test_battery_urls.Conversation_url,
        "Conversation_UploadSuccess",
        "Conversation_progress",
        "Conversation_success",
        "Conversation_error"
      );
    }

    if (!this.state.upload_information.SVF_success) {
      this.function_upload_audio_file(this.props.test_battery.SVF_Filename, this.props.test_battery_urls.SVF_url, "SVF_UploadSuccess", "SVF_progress", "SVF_success", "SVF_error");
    }

    if (!this.state.upload_information.ImageDescription_success) {
      this.function_upload_audio_file(
        this.props.test_battery.Image_Description_Filename,
        this.props.test_battery_urls.ImageDescription_url,
        "Image_Description_UploadSuccess",
        "ImageDescription_progress",
        "ImageDescription_success",
        "ImageDescription_error"
      );
    }
  };

  function_progress_bar_color = (progress, success, error) => {
    if (success) {
      return "progress_bar_success";
    } else if (error) {
      return "progress_bar_error";
    } else {
      if (progress <= 0) {
        return "progress_bar_minus_0";
      } else if (progress > 0 && progress < 5) {
        return "progress_bar_0_5";
      } else if (progress >= 5 && progress < 10) {
        return "progress_bar_5_10";
      } else if (progress >= 10 && progress < 15) {
        return "progress_bar_10_15";
      } else if (progress >= 15 && progress < 20) {
        return "progress_bar_15_20";
      } else if (progress >= 20 && progress < 25) {
        return "progress_bar_20_25";
      } else if (progress >= 25 && progress < 30) {
        return "progress_bar_25_30";
      } else if (progress >= 30 && progress < 35) {
        return "progress_bar_30_35";
      } else if (progress >= 35 && progress < 40) {
        return "progress_bar_35_40";
      } else if (progress >= 40 && progress < 45) {
        return "progress_bar_40_45";
      } else if (progress >= 45 && progress < 50) {
        return "progress_bar_45_50";
      } else if (progress >= 50 && progress < 55) {
        return "progress_bar_50_55";
      } else if (progress >= 55 && progress < 60) {
        return "progress_bar_55_60";
      } else if (progress >= 60 && progress < 65) {
        return "progress_bar_60_65";
      } else if (progress >= 65 && progress < 70) {
        return "progress_bar_65_70";
      } else if (progress >= 70 && progress < 75) {
        return "progress_bar_70_75";
      } else if (progress >= 75 && progress < 80) {
        return "progress_bar_75_80";
      } else if (progress >= 80 && progress < 85) {
        return "progress_bar_80_85";
      } else if (progress >= 85 && progress < 90) {
        return "progress_bar_85_90";
      } else if (progress >= 90 && progress < 95) {
        return "progress_bar_90_95";
      } else if (progress >= 95) {
        return "progress_bar_95_plus";
      }
    }
  };

  render() {
    return (
      <div id="test_battery_results_view_container" className="col-12">
        <div id="test_battery_results_header_container" className="row justify-content-center align-content-center">
          <h1 className="text-center">{this.props.language.retrieve_string("testBattery.results.header")}</h1>
        </div>
        <div id="test_battery_results_explanation" className="row justify-content-center align-content-center">
          <p className="text-justify test_battery_results_text">{ReactHtmlParser(this.props.language.retrieve_string("testBattery.results.explanation"))}</p>
        </div>
        <div id="test_battery_results_table_container" ref="test_battery_results_table_container" className="row justify-content-center align-content-center">
          <Paper>
            <TableContainer id="test_battery_results_table" style={{ height: this.state.table_height + "px" }}>
              <Table stickyHeader size="small" aria-label="sticky a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell id="test_battery_results_table_header_cell_text_file_name" align="center">
                      {this.props.language.retrieve_string("testBattery.results.results_table.header.file_name")}
                    </TableCell>
                    <TableCell id="test_battery_results_table_header_cell_text_upload_progress" align="center">
                      {this.props.language.retrieve_string("testBattery.results.results_table.header.upload_progress")}
                    </TableCell>
                    <TableCell id="test_battery_results_table_header_cell_volume_icon" align="center">
                      <FaVolumeUp />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* SVF */}
                  <TableRow>
                    <TableCell id="test_battery_results_table_SVF_header" colSpan={4}>
                      {this.props.language.retrieve_string("testBattery.testStep.SVF.name")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="test_battery_results_table_SVF_file_name" align="left">
                      {this.props.language.retrieve_string("testBattery.results.results_table.body.unique")}
                    </TableCell>
                    <TableCell id="test_battery_results_table_SVF_upload_progress" align="center">
                      <ProgressBar
                        animated={!this.state.upload_information.SVF_success}
                        variant={this.function_progress_bar_color(this.state.upload_information.SVF_progress, this.state.upload_information.SVF_success, this.state.upload_information.SVF_error)}
                        now={this.state.upload_information.SVF_progress}
                        label={
                          this.state.upload_information.SVF_error
                            ? this.props.language.retrieve_string("testBattery.results.results_table.body.upload_error")
                            : `${this.state.upload_information.SVF_progress.toFixed(2)}%`
                        }
                      />
                    </TableCell>
                    <TableCell id="test_battery_results_table_SVF_audio" align="center">
                      <Button
                        variant="custom"
                        id={this.state.audio_files["SVF_audio_playing"] ? "test_battery_results_table_SVF_audio_stop" : "test_battery_results_table_SVF_audio_play"}
                        onClick={() => {
                          this.state.audio_files["SVF_audio_playing"]
                            ? this.function_stop_audio_file("SVF_audio_file", "SVF_audio_playing")
                            : this.function_play_audio_file("SVF_audio_file", this.props.test_battery_urls.SVF_url, "SVF_audio_playing");
                        }}
                      >
                        {this.state.audio_files["SVF_audio_playing"] ? <FaStopCircle /> : <FaPlayCircle />}
                      </Button>
                    </TableCell>
                  </TableRow>

                  {/* Image description */}
                  <TableRow>
                    <TableCell id="test_battery_results_table_Image_Description_header" colSpan={4}>
                      {this.props.language.retrieve_string("testBattery.testStep.Image_Description.name")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="test_battery_results_table_Image_Description_file_name" align="left">
                      {this.props.language.retrieve_string("testBattery.results.results_table.body.unique")}
                    </TableCell>
                    <TableCell id="test_battery_results_table_Image_Description_upload_progress" align="center">
                      <ProgressBar
                        animated={!this.state.upload_information.ImageDescription_success}
                        variant={this.function_progress_bar_color(
                          this.state.upload_information.ImageDescription_progress,
                          this.state.upload_information.ImageDescription_success,
                          this.state.upload_information.ImageDescription_error
                        )}
                        now={this.state.upload_information.ImageDescription_progress}
                        label={
                          this.state.upload_information.ImageDescription_error
                            ? this.props.language.retrieve_string("testBattery.results.results_table.body.upload_error")
                            : `${this.state.upload_information.ImageDescription_progress.toFixed(2)}%`
                        }
                      />
                    </TableCell>
                    <TableCell id="test_battery_results_table_Image_Description_audio" align="center">
                      <Button
                        variant="custom"
                        id={
                          this.state.audio_files["Image_Description_audio_playing"]
                            ? "test_battery_results_table_Image_Description_audio_stop"
                            : "test_battery_results_table_Image_Description_audio_play"
                        }
                        onClick={() => {
                          this.state.audio_files["Image_Description_audio_playing"]
                            ? this.function_stop_audio_file("Image_Description_audio_file", "Image_Description_audio_playing")
                            : this.function_play_audio_file("Image_Description_audio_file", this.props.test_battery_urls.ImageDescription_url, "Image_Description_audio_playing");
                        }}
                      >
                        {this.state.audio_files["Image_Description_audio_playing"] ? <FaStopCircle /> : <FaPlayCircle />}
                      </Button>
                    </TableCell>
                  </TableRow>

                  {/* Conversation */}
                  <TableRow>
                    <TableCell id="test_battery_results_table_Conversation_header" colSpan={4}>
                      {this.props.language.retrieve_string("testBattery.testStep.Conversation.name")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="test_battery_results_table_Conversation_file_name" align="left">
                      {this.props.language.retrieve_string("testBattery.results.results_table.body.unique")}
                    </TableCell>
                    <TableCell id="test_battery_results_table_Conversation_upload_progress" align="center">
                      <ProgressBar
                        animated={!this.state.upload_information.Conversation_success}
                        variant={this.function_progress_bar_color(
                          this.state.upload_information.Conversation_progress,
                          this.state.upload_information.Conversation_success,
                          this.state.upload_information.Conversation_error
                        )}
                        now={this.state.upload_information.Conversation_progress}
                        label={
                          this.state.upload_information.Conversation_error
                            ? this.props.language.retrieve_string("testBattery.results.results_table.body.upload_error")
                            : `${this.state.upload_information.Conversation_progress.toFixed(2)}%`
                        }
                      />
                    </TableCell>
                    <TableCell id="test_battery_results_table_Conversation_audio" align="center">
                      <Button
                        variant="custom"
                        id={this.state.audio_files["Conversation_audio_playing"] ? "test_battery_results_table_Conversation_audio_stop" : "test_battery_results_table_Conversation_audio_play"}
                        onClick={() => {
                          this.state.audio_files["Conversation_audio_playing"]
                            ? this.function_stop_audio_file("Conversation_audio_file", "Conversation_audio_playing")
                            : this.function_play_audio_file("Conversation_audio_file", this.props.test_battery_urls.Conversation_url, "Conversation_audio_playing");
                        }}
                      >
                        {this.state.audio_files["Conversation_audio_playing"] ? <FaStopCircle /> : <FaPlayCircle />}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div id="test_battery_results_button_container" className="row justify-content-center align-content-center">
          {this.state.all_files_uploaded ? (
            <Button variant="custom" id="test_battery_results_button" onClick={this.props.end_test_battery}>
              {this.props.language.retrieve_string("testBattery.buttons.end")}
            </Button>
          ) : (
            <Button variant="custom" id="test_battery_results_button" onClick={this.function_upload_all_files}>
              <FaCloudUploadAlt /> {this.props.language.retrieve_string("testBattery.buttons.uploadFiles")}
            </Button>
          )}
        </div>
        <Dialog open={this.state.open_modal} onClose={this.function_close_modal}>
          <div id="test_battery_results_watch_picture_viewer">
            <DialogContent id="test_battery_results_watch_picture_canvas">
              <canvas
                ref={(ref) => {
                  this.canvas = ref;
                }}
              ></canvas>
            </DialogContent>
            <DialogActions>
              <div className="row mx-auto">
                <div className="col-6 col-xs-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                  <Button variant="custom" id="test_results_modal_play_button" onClick={this.function_play_json_file}>
                    {this.props.language.retrieve_string("testBattery.buttons.play")}
                  </Button>
                </div>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}

TestBatteryResults.propTypes = {
  end_test_battery: PropTypes.func.isRequired,
  test_battery: PropTypes.object.isRequired,
  test_battery_urls: PropTypes.object.isRequired,
  update_test_battery: PropTypes.func.isRequired,
};

export default withLanguage(TestBatteryResults);
