// Import libraries
import React from 'react';
import { FaUserFriends } from 'react-icons/fa';

// Import high order components
import { withLanguage } from '../../../../../contexts/language/index';

// Import widgets
import SectionAnimation from '../../../../widgets/animations/SectionAnimation';

// Impor components
import Profile from '../profile/Profile';

class Advisors extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <SectionAnimation>
          <div className="row">
            <div className="home_about_us_section_header_container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <span className="home_about_us_section_header_icon_container"><FaUserFriends /></span>
              <span className="home_about_us_section_header_text">{this.props.language.retrieve_string('home.about_us.advisors.header')}</span>
            </div>
          </div>
        </SectionAnimation>
        <SectionAnimation>
          <div className="row">
            <Profile is_team_member={true} container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"} photo_container_class={"home_about_us_section_profile_javier_jimenez_photo_container"} name={this.props.language.retrieve_string("home.about_us.advisors.advisors_members.javier_jimenez.name")}
              position={this.props.language.retrieve_string("home.about_us.advisors.advisors_members.javier_jimenez.position")} description={this.props.language.retrieve_string(
                "home.about_us.advisors.advisors_members.javier_jimenez.description")} disable_description={false}
            />
            <Profile is_team_member={true} container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"} photo_container_class={ "home_about_us_section_profile_pablo_de_la_guardia_photo_container"} name={this.props.language.retrieve_string("home.about_us.advisors.advisors_members.pablo_delaguardia.name")}
              position={this.props.language.retrieve_string("home.about_us.advisors.advisors_members.pablo_delaguardia.position")} description={this.props.language.retrieve_string("home.about_us.advisors.advisors_members.pablo_delaguardia.description")} disable_description={true}
            />
						<Profile is_team_member={true} container_class={'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'} photo_container_class={'home_about_us_section_profile_donald_a_davidoff_photo_container'} name={this.props.language.retrieve_string('home.about_us.advisors.advisors_members.donald_a_davidoff.name')} position={this.props.language.retrieve_string('home.about_us.advisors.advisors_members.donald_a_davidoff.position')} description={this.props.language.retrieve_string('home.about_us.advisors.advisors_members.donald_a_davidoff.description')} disable_description={false} />
						<Profile is_team_member={true} container_class={'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'} photo_container_class={'home_about_us_section_profile_pedro_montejo_photo_container'} name={this.props.language.retrieve_string('home.about_us.advisors.advisors_members.pedro_montejo.name')} position={this.props.language.retrieve_string('home.about_us.advisors.advisors_members.pedro_montejo.position')} description={this.props.language.retrieve_string('home.about_us.advisors.advisors_members.pedro_montejo.description')} disable_description={false} />
          </div>
        </SectionAnimation>
      </div>
    )
  }
};

export default withLanguage(Advisors);