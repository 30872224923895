// Import libraries
import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";

// Import Firebase
import app from "firebase/app";
import "firebase/firestore";

// Import authentication context
import AuthUserContext, { withAuthorization } from "../../../contexts/session/index";
import { withLanguage } from "../../../contexts/language/index";

// Import components
import TestBattery from "../test_battery/TestBattery";
import PatientList from "./navigation_components/medical_staff_version/PatientList";
import PatientDetails from "./navigation_components/medical_staff_version/PatientDetails";
import TestBatteryList from "./navigation_components/patient_version/TestBatteryList";
import TestBatteryDetails from "./navigation_components/patient_version/TestBatteryDetails";

// Import resources
import background_image from "../../../resources/images/background-image/BackgroundImage.jpg";

// Import CSS
import "../../../styles/components/private/profile/Profile.css";

class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      testBatteryStarted: false,
      medical_staff_version: false,
      selected_test_battery_profile: null,
      selected_patient_profile: null,
      custom_patient_id: "",
      test_battery_patient_id: "",
      test_battery_custom_patient_id: "",
      test_battery_user_id: "",
      show_test_battery_details: false,
      show_permission_modal: false,
    };
    this.function_start_test_battery = this.function_start_test_battery.bind(this);
    this.function_end_test_battery = this.function_end_test_battery.bind(this);
    this.function_cancel_test_battery = this.function_cancel_test_battery.bind(this);
    this.function_select_test_battery_profile = this.function_select_test_battery_profile.bind(this);
    this.function_toggle_show_test_battery_details = this.function_toggle_show_test_battery_details.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.database = app.firestore();
    this.database
      .collection("Users")
      .where("UserID", "==", this.context.uid)
      .limit(1)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          this.setState({ medical_staff_version: false });
        } else {
          snapshot.forEach((document) => {
            this.setState({
              medical_staff_version: document.data().MedicalStaff,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    // Start recording of the audio
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          sampleSize: 16,
          channelCount: 1,
          sampleRate: 44100,
          echoCancellation: true,
        },
        video: false,
      })
      .then((stream) => {
        // Sets the audio recorder
        this.audioRecorder = RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/wav",
          recorderType: StereoAudioRecorder,
          numberOfAudioChannels: 1,
          bitrate: 320000,
        });

        // Sets the audio context to get the db from the audio recording
        this.audio_context = new window.AudioContext();
        this.audio_analyser = this.audio_context.createAnalyser();
        this.source = this.audio_context.createMediaStreamSource(stream);
        this.audio_analyser.smoothingTimeConstant = 0.8;
        this.audio_analyser.fftSize = 1024;

        this.source.connect(this.audio_analyser);

        // Starts recording the audio file
        this.audioRecorder.startRecording();

        // Sets the timer to finish the test step
        this.audio_recording_timer = setTimeout(() => {
          this.test_step_timer = 0;
          this.audioRecorder.stopRecording();
        }, 1000);
      })
      .catch(console.error);
  }

  componentWillUnmount() {
    if (this.audio_recording_timer) {
      clearTimeout(this.audio_recording_timer);
      this.audio_recording_timer = 0;
    }
  }

  function_start_test_battery = (patient_id, custom_patient_id, user_id) => {
    let permission_granted = false;

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices.forEach((device) => {
        if (device.kind === "audioinput") {
          if (device.label !== "") {
            permission_granted = true;
          }
        }
      });

      if (permission_granted) {
        if (custom_patient_id === null) {
          this.database = app.firestore();

          this.database
            .collection("Patients")
            .where("PatientID", "==", patient_id)
            .limit(1)
            .get()
            .then((snapshot) => {
              if (!snapshot.empty) {
                snapshot.forEach((document) => {
                  this.setState({
                    test_battery_patient_id: patient_id,
                    test_battery_custom_patient_id: document.data().CustomPatientID,
                    test_battery_user_id: document.data().UserID,
                  });
                });

                this.setState({ testBatteryStarted: true });
                this.props.function_test_initialized();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.setState({
            test_battery_patient_id: patient_id,
            test_battery_custom_patient_id: custom_patient_id,
            test_battery_user_id: user_id,
          });
          this.setState({ testBatteryStarted: true });
          this.props.function_test_initialized();
        }
      } else {
        this.setState({ show_permission_modal: true });
      }
    });
  };

  function_end_test_battery = () => {
    this.setState({
      testBatteryStarted: false,
      test_battery_patient_id: "",
      test_battery_custom_patient_id: "",
      test_battery_user_id: "",
    });
    this.props.function_test_finalised();
  };

  function_cancel_test_battery = () => {
    this.setState({
      testBatteryStarted: false,
      test_battery_patient_id: "",
      test_battery_custom_patient_id: "",
      test_battery_user_id: "",
    });
    this.props.function_test_finalised();
  };

  function_select_test_battery_profile = (batteryID) => {
    this.setState({ selected_test_battery_profile: batteryID });
  };

  function_select_patient_profile = (patientID) => {
    this.setState({
      show_test_battery_details: false,
      selected_patient_profile: patientID,
    });
  };

  function_toggle_show_test_battery_details = () => {
    this.setState((prevState) => ({
      show_test_battery_details: !prevState.show_test_battery_details,
    }));
  };

  render() {
    if (this.state.testBatteryStarted === false) {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => (
            <div id="profile_page" className="container-fluid">
              <img id="profile_background_image" src={background_image} alt="acceXible background" />
              <div id="profile_page_container" className="row align-items-center">
                <div id="profile_page_patient_version_container" className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div id="profile_page_patient_version_explanation_container" className="row align-items-center">
                    {this.state.medical_staff_version === true ? (
                      <p>{this.props.language.retrieve_string("profile.medical_staff.home_information")}</p>
                    ) : (
                      <p>{this.props.language.retrieve_string("profile.patient.home_information")}</p>
                    )}
                  </div>
                  <div id="profile_page_patient_version_navigation_container" className="row align-items-center">
                    {this.state.medical_staff_version === true ? (
                      <PatientList function_select_patient_profile={this.function_select_patient_profile} selected_patient_id={this.state.selected_patient_profile} />
                    ) : (
                      <TestBatteryList function_select_test_battery_profile={this.function_select_test_battery_profile} selected_battery_id={this.state.selected_test_battery_profile} />
                    )}
                    {this.state.medical_staff_version === true ? (
                      <PatientDetails
                        function_select_patient_profile={this.function_select_patient_profile}
                        function_start_test_battery={this.function_start_test_battery}
                        selected_patient_profile={this.state.selected_patient_profile}
                        show_test_battery_details={this.state.show_test_battery_details}
                        function_toggle_show_test_battery_details={this.function_toggle_show_test_battery_details}
                      />
                    ) : (
                      <TestBatteryDetails selected_battery_id={this.state.selected_test_battery_profile} selected_patient_id={this.context.uid} />
                    )}
                  </div>
                  {this.state.medical_staff_version === false ? (
                    <div id="profile_page_patient_version_start_test_battery_button_container" className="row align-items-center">
                      <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                        <Button id="profile_start_test_battery_button" onClick={() => this.function_start_test_battery(this.context.uid, null)}>
                          {this.props.language.retrieve_string("common.buttons.start_test_battery")}
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Dialog open={this.state.show_permission_modal} onClose={this.function_close_new_user_modal}>
                <DialogContent>
                  <p id="profile_permission_modal_text">{this.props.language.retrieve_string("common.errors.permission_error")}</p>
                </DialogContent>
                <DialogActions>
                  <div id="profile_permission_modal_reload_button_container" className="row mx-auto">
                    <div className="col-6 col-xs-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                      <Button
                        onClick={() => {
                          window.location.reload();
                        }}
                        id="profile_permission_modal_reload_button"
                      >
                        {this.props.language.retrieve_string("common.buttons.reload")}
                      </Button>
                    </div>
                  </div>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </AuthUserContext.Consumer>
      );
    } else {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => (
            <TestBattery
              cancel_test_battery={this.function_cancel_test_battery}
              end_test_battery={this.function_end_test_battery}
              test_battery_patient_id={this.state.test_battery_patient_id}
              test_battery_custom_patient_id={this.state.test_battery_custom_patient_id}
              test_battery_user_id={this.state.test_battery_user_id}
            />
          )}
        </AuthUserContext.Consumer>
      );
    }
  }
}

Profile.contextType = AuthUserContext;

Profile.propTypes = {
  function_test_initialized: PropTypes.func.isRequired,
  function_test_finalised: PropTypes.func.isRequired,
};

const condition = (authUser) => !!authUser;

export default compose(withLanguage, withAuthorization(condition))(Profile);
