// Import libraries
import React from "react";
import PropTypes from "prop-types";

// Import high order components
import { withLanguage } from "../../../../../contexts/language/index";

// Import CSS
import "../../../../../styles/components/private/test_battery/test_steps/shared/TestStepHeader.css";

class TestStepHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      test_name: "",
    };
  };

  componentDidMount() {
    switch (this.props.test_step) {
      case 0:
        this.setState({
          test_name: this.props.language.retrieve_string("testBattery.testStep.SVF.name"),
        });
        break;
      case 1:
        this.setState({
          test_name: this.props.language.retrieve_string("testBattery.testStep.Image_Description.name"),
        });
        break;
      case 2:
        this.setState({
          test_name: this.props.language.retrieve_string("testBattery.testStep.Conversation.name"),
        });
        break;

      default:
        break;
    }
  };

  componentWillUnmount() {
    this.setState({ test_name: "" });
  };

  render() {
    return (
      <div id="test_step_common_view_header_container" className="row mx-auto justify-content-center align-content-center">
        <h1 className="text-center">{this.state.test_name}</h1>
      </div>
    );
  }
};

TestStepHeader.propTypes = {
  test_step: PropTypes.number.isRequired,
};

export default withLanguage(TestStepHeader);
