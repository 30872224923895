import React from 'react';

import LanguageContext from '../context/LanguageContext';

const withLanguage = Component => props => (
    <LanguageContext.Consumer>
        {language => <Component {...props} language={language} />}
    </LanguageContext.Consumer>
)

export default withLanguage;