// Import libraries
import React from 'react';

// Import components
import HomeHeader from './header/HomeHeader';
import Application from './application/Application';
import Prevention from './prevention/Prevention';
import Targets from './targets/Targets';
import AboutUs from './about_us/AboutUs';

class Home extends React.Component {
    render() {
        return (
            <div>
                <div className="row">
                    <HomeHeader />
                </div>
                <div id="home_prevention_section_row" className="row">
                    <Prevention />
                </div>
                <div id="home_application_section_row" className="row">
                    <Application />
                </div>
                <div className="row">
                    <Targets />
                </div>
                <div id="home_about_us_section_row" className="row">
                    <AboutUs />
                </div>
            </div>
        )
    }
}

export default Home;