// Import libraries
import React from "react";
import PropTypes from "prop-types";

// Import components
import Conversation03 from "../test_steps/steps/Conversation_03";
import SVF07 from "../test_steps/steps/SVF_07";
import ImageDescription09 from "../test_steps/steps/Image_Description_09";

class TestStepView extends React.Component {
  render() {
    switch (this.props.test_step) {
      case 0:
        return (
          <SVF07
            test_battery_ID={this.props.test_battery_ID}
            test_battery_user_ID={this.props.test_battery_user_ID}
            finish_test_step={this.props.finish_test_step}
            update_test_battery={this.props.update_test_battery}
            update_test_battery_blob={this.props.update_test_battery_blob}
          />
        );
      case 1:
        return (
          <ImageDescription09
            test_battery_ID={this.props.test_battery_ID}
            test_battery_user_ID={this.props.test_battery_user_ID}
            finish_test_step={this.props.finish_test_step}
            update_test_battery={this.props.update_test_battery}
            update_test_battery_blob={this.props.update_test_battery_blob}
          />
        );
      case 2:
        return (
          <Conversation03
            test_battery_ID={this.props.test_battery_ID}
            test_battery_user_ID={this.props.test_battery_user_ID}
            finish_test_step={this.props.finish_test_step}
            update_test_battery={this.props.update_test_battery}
            update_test_battery_blob={this.props.update_test_battery_blob}
          />
        );

      default:
        return <div></div>;
    }
  }
};

TestStepView.propTypes = {
  test_step: PropTypes.number.isRequired,
  test_battery_ID: PropTypes.string.isRequired,
  test_battery_user_ID: PropTypes.string.isRequired,
  finish_test_step: PropTypes.func.isRequired,
  update_test_battery: PropTypes.func.isRequired,
  update_test_battery_blob: PropTypes.func.isRequired,
};

export default TestStepView;
