// Import libraries
import React from 'react';
//import { FaMicrophoneAlt, FaLanguage, FaCommentMedical } from 'react-icons/fa';

import internet from "../../../../resources/images/tags/internet.png";
import habla from "../../../../resources/images/tags/habla.png";
import resultado from "../../../../resources/images/tags/resultado.png";

// Import high order components
import { withLanguage } from '../../../../contexts/language/index';

// Import widgets
import SectionAnimation from '../../../widgets/animations/SectionAnimation';

// Import CSS
import '../../../../styles/components/public/home/prevention/Prevention.css';

class Prevention extends React.Component {
    render() {
        return (
            <SectionAnimation>
            <div id="home_prevention_section_container" className="container-fluid">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <SectionAnimation>
                    <div className="row">
                      <div className="container-fluid">
                        <div
                          id="home_prevention_section_header_container"
                          className="row"
                        >
                          <div className="col-12">
                            <h1 id="home_prevention_section_container_header">
                              {this.props.language.retrieve_string(
                                "home.prevention.header"
                              )}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SectionAnimation>
                  <SectionAnimation>
                    <div
                      id="home_prevention_section_text_container"
                      className="row"
                    >
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <SectionAnimation>
                          <div className="row">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-12">
                                  <h4 id="home_prevention_section_explanation">
                                    {this.props.language.retrieve_string(
                                      "home.prevention.explanation"
                                    )}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SectionAnimation>
                        <SectionAnimation>
                          <div
                            id="home_prevention_section_panel_container"
                            className="row mx-auto align-items-center justify-content-center"
                          >
                            <div className="home_prevention_section_panel_icon_container col-12 col-xs-12 col-sm-12 col-md-12 col-xl-4 col-lg-4">
                              <div className="row">
                                <div className="home_prevention_section_icon_container col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-center">
                                  {/*  <FaMicrophoneAlt /> */}
                                  <img src={internet} alt="" className="col-6" />
                                  <div className="home_prevention_section_panel_number">
                                    1
                                  </div>
                                </div>
                              </div>
                              <div className="row mx-auto align-items-start justify-content-center text-center home_prevention_section_panel_text">
                                {this.props.language.retrieve_string(
                                  "home.prevention.panel_1"
                                )}
                              </div>
                            </div>
                            <div className="home_prevention_section_panel_icon_container col-12 col-xs-12 col-sm-12 col-md-12 col-xl-4 col-lg-4">
                              <div className="row">
                                <div className="home_prevention_section_icon_container col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-center">
                                  {/* <FaLanguage /> */}
                                  <img src={habla} alt="" className="col-6" />
                                  <div className="home_prevention_section_panel_number">
                                    2
                                  </div>
                                </div>
                              </div>
                              <div className="row mx-auto align-items-start justify-content-center text-center home_prevention_section_panel_text">
                                {this.props.language.retrieve_string(
                                  "home.prevention.panel_2"
                                )}
                              </div>
                            </div>
                            <div className="home_prevention_section_panel_icon_container col-12 col-xs-12 col-sm-12 col-md-12 col-xl-4 col-lg-4">
                              <div className="row">
                                <div className="home_prevention_section_icon_container col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-center">
                                  {/* <FaCommentMedical /> */}
                                  <img
                                    src={resultado}
                                    alt=""
                                    className="col-6"
                                    /* style={{ width: "40%" }} */
                                  />
                                  <div className="home_prevention_section_panel_number">
                                    3
                                  </div>
                                </div>
                              </div>
                              <div className="row mx-auto align-items-start justify-content-center text-center home_prevention_section_panel_text">
                                {this.props.language.retrieve_string(
                                  "home.prevention.panel_3"
                                )}
                              </div>
                            </div>
                          </div>
                        </SectionAnimation>
                      </div>
                    </div>
                  </SectionAnimation>
                </div>
              </div>
            </div>
          </SectionAnimation>
        )
    }
}

export default withLanguage(Prevention);