// Import libraries
import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

// Import high order components
import { withLanguage } from "../../../../contexts/language/index";

// Import CSS
import "../../../../styles/components/private/test_battery/common/TestBatteryCancelButton.css";

class TestBatteryCancelButton extends React.Component {
  render() {
    return (
      <div id="test_battery_cancel_test_battery_button_container" className="row justify-content-end align-content-center">
        <Button id="test_battery_cancel_test_battery_button" onClick={this.props.cancel_test_battery}>
          {this.props.language.retrieve_string("testBattery.buttons.cancelTestBattery")}
        </Button>
      </div>
    );
  }
}

TestBatteryCancelButton.propTypes = {
  cancel_test_battery: PropTypes.func.isRequired,
};

export default withLanguage(TestBatteryCancelButton);
