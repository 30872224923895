function TestBattery() {
  return {
    ID: "",
    BatteryID: "",
    PatientID: "",
    CustomPatientID: "",
    BatteryTestDate: null,
    Score: null,
    iOS: false,
    Conversation_Filename: "",
    Conversation_UploadSuccess: false,
    SVF_Filename: "",
    SVF_UploadSuccess: false,
    Image_Description_Filename: "",
    Image_Description_UploadSuccess: false,
    BatteryCompleted: false,
    get BatteryUploaded() {
      if (this.Conversation_UploadSuccess && this.SVF_UploadSuccess && this.Image_Description_UploadSuccess) {
        return true;
      } else {
        return false;
      }
    },
    UserID: ""
  };
};

export default TestBattery;
