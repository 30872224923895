// Import libraries
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import 'date-fns';
import { Navbar, Nav, NavItem } from 'react-bootstrap';

// Import contexts
import AuthUserContext from '../../../contexts/session/index';

// Import resources
import * as ROUTES from '../../../resources/constants/routes';
import logo from '../../../resources/images/logo/accexible_logo.png';

// Import widgets
import Text from '../text/Text';
import LanguageSettings from '../language/LanguageSettings';

// Import components
import SignOut from '../../authentication/sign_out/SignOut';

// Import CSS
import '../../../styles/widgets/navigation/Navigation.css';

const Navigation = ({ authUser }) => (
    <div>
        <AuthUserContext.Consumer>
            {authUser => authUser ? <NavigationAuth /> : <NavigationNonAuth />}
        </AuthUserContext.Consumer>
    </div>
);

class NavigationAuth extends React.Component {
    render() {
        return (
            <div>
                <Navbar fixed="top" expand="lg">
                    <Navbar.Brand as={Link} to={ROUTES.HOME}><img src={logo} alt='acceXible logo' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <NavItem eventkey={1} href={ROUTES.HOME}>
                                <NavLink id='widget_navbar_home_link' className="nav-link" exact to={ROUTES.HOME}>
                                    <Text path='common.navigationBar.home' />
                                </NavLink>
                            </NavItem>
                            <NavItem eventkey={2} href={ROUTES.PROFILE}>
                                <NavLink id='widget_navbar_profile_link' className="nav-link" exact to={ROUTES.PROFILE}>
                                    <Text path='common.navigationBar.profile' />
                                </NavLink>
                            </NavItem>
                            <SignOut />
                        </Nav>
                        <Nav className="ml-auto">
                            <NavItem>
                                <LanguageSettings />
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

class NavigationNonAuth extends React.Component {
    render() {
        return (
            <div>
                <Navbar fixed="top" expand="lg">
                    <Navbar.Brand as={Link} to={ROUTES.HOME}><img src={logo} id='navbar_acceXibleLogo' alt='acceXible logo' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <NavItem eventkey={1} href={ROUTES.HOME}>
                                <NavLink id='widget_navbar_home_link' className="nav-link" exact to={ROUTES.HOME}>
                                    <Text path='common.navigationBar.home' />
                                </NavLink>
                            </NavItem>
                            <NavItem eventkey={2} href={ROUTES.SIGN_IN}>
                                <NavLink id='widget_navbar_sign_in_link' className="nav-link" exact to={ROUTES.SIGN_IN}>
                                    <Text path='common.navigationBar.sign_in' />
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Nav className="ml-auto">
                            <NavItem>
                                <LanguageSettings />
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default Navigation;