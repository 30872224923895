function FileUploadInformation() {
  return {
    Conversation_success: false,
    Conversation_progress: 0,
    Conversation_error: false,
    SVF_success: false,
    SVF_progress: 0,
    SVF_error: false,
    ImageDescription_success: false,
    ImageDescription_progress: 0,
    ImageDescription_error: false,
  };
};

export default FileUploadInformation;
