// Import libraries
import React from 'react';
import PropTypes from 'prop-types';

class PartnershipPanel extends React.Component {
    render() {
        return (
            <div className={this.props.container_class} onClick={this.function_handle_toggle}>
                <div className="home_about_us_section_partnership_container" >
                    <div className="row mx-auto align-items-center">
                        <div className="col-12">
                            <div className="row home_about_us_section_partnership_header_container">
                                <div className="home_about_us_section_partnership_header text-left">{this.props.name}</div>
                            </div>
                            <div className="home_about_us_section_partnership_image_container row mx-auto align-items-center">
                                {this.props.image}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

PartnershipPanel.propTypes = {
    container_class: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.element.isRequired
}

export default PartnershipPanel;