// Import libraries
import React from 'react';

// Import CSS
import '../../../styles/widgets/animations/SectionAnimation.css';

function SectionAnimation(props) {
    const [is_visible, set_visible] = React.useState(true);
    const domRef = React.useRef();

    React.useEffect(() => {
      let domRefValue = null;

      const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => set_visible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
      domRefValue = domRef.current;
      return () => {
        if (domRefValue) observer.unobserve(domRefValue);
      }
    }, []);

    return (
        <div
            className={`widget_section_animation_fade_in ${is_visible ? 'widget_section_animation_is_visible' : ''}`}
            ref={domRef}
        >
            {props.children}
        </div>
    );
}

export default SectionAnimation;
