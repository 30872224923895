// Import libraries
import React from "react";
import { Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { FaCheckCircle, FaTasks, FaCloudUploadAlt } from "react-icons/fa";
import { CircularProgress, Button } from "@material-ui/core";

// Import Firebase
import * as firebase from "firebase/app";
import "firebase/firestore";

// Import high order components
import { withLanguage } from "../../../../contexts/language/index";

// Import widgets
import SectionAnimation from "../../../widgets/animations/SectionAnimation";

// Import components
import ApplicationCard from "./ApplicationCard";

// Import resources
import accexible_platform_english from "../../../../resources/videos/home/accexible_platform_english.mp4";
import accexible_platform_spanish from "../../../../resources/videos/home/accexible_platform_spanish.mp4";

// Import CSS
import "../../../../styles/components/public/home/application/Application.css";

class Application extends React.Component {
  constructor() {
    super();

    this.state = {
      show_schedule_modal: false,
      email_address: "",
      schedule_selected_date: Date.now(),
      schedule_selected_hour: Date.now(),
      email_format_error: false,
      email_send_loading: false,
    };

    this.function_on_change = this.function_on_change.bind(this);
    this.function_submit_schedule_demo_formulary = this.function_submit_schedule_demo_formulary.bind(
      this
    );
    this.function_open_schedule_modal = this.function_open_schedule_modal.bind(
      this
    );
    this.function_close_schedule_modal = this.function_close_schedule_modal.bind(
      this
    );
    this.function_select_schedule_date = this.function_select_schedule_date.bind(
      this
    );
    this.function_select_schedule_date = this.function_select_schedule_date.bind(
      this
    );
  }

  function_on_change = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  function_submit_schedule_demo_formulary = () => {
    this.setState({ email_send_loading: true, email_format_error: false });

    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email_address)
    ) {
      const database = firebase.firestore();

      database
        .collection("RequestDemo")
        .add({
          email_address: this.state.email_address,
          demo_date: new Date(this.state.schedule_selected_date).toDateString(),
          demo_hour: new Date(this.state.schedule_selected_hour).toTimeString(),
        })
        .then(() => {
          this.setState({
            show_schedule_modal: false,
            email_send_loading: false,
          });
        })
        .catch((error) => {
          this.setState({ email_send_loading: false });
          console.error("Error adding document: ", error);
        });
    } else {
      this.setState({ email_format_error: true, email_send_loading: false });
    }
  };

  function_send_email(templateId, variables) {
    window.emailjs
      .send("gmail", templateId, variables)
      .then((res) => {
        this.setState({
          show_schedule_modal: false,
          email_send_loading: false,
        });
      })
      .catch((err) => {
        this.setState({ email_send_loading: false });
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
      });
  }

  function_open_schedule_modal = () => {
    this.setState({
      show_schedule_modal: true,
      schedule_selected_date: Date.now(),
      schedule_selected_hour: Date.now(),
    });
  };

  function_close_schedule_modal = () => {
    this.setState({ show_schedule_modal: false });
  };

  function_select_schedule_date = (date) => {
    this.setState({ schedule_selected_date: date });
  };

  function_select_schedule_date = (date) => {
    this.setState({ schedule_selected_hour: date });
  };

  render() {
    return (
      <SectionAnimation>
        <div
          id="home_application_section_container"
          className="container-fluid"
        >
          <div id="home_section_aplication_text_container" className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <SectionAnimation>
                <div className="row">
                  <div className="container-fluid">
                    <div
                      id="home_application_section_header_container"
                      className="row"
                    >
                      <div className="col-12">
                        <h1 id="home_application_section_container_header">
                          {this.props.language.retrieve_string(
                            "home.application.header"
                          )}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </SectionAnimation>
              <SectionAnimation>
                <SectionAnimation>
                  <div
                    id="home_application_section_text_container"
                    className="row"
                  >
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <SectionAnimation>
                        <div
                          id="home_application_section_video_container"
                          className="row"
                        >
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-12 col-xs-12 col-sm-12 col-md-12 offset-xl-2 col-xl-8 offset-lg-2 col-lg-8">
                                {this.props.language.language === "es-ES" ? (
                                  <video
                                    key={accexible_platform_spanish}
                                    id="home_application_section_video"
                                    controls
                                    controlsList="nodownload"
                                  >
                                    <source
                                      src={accexible_platform_spanish}
                                      type="video/mp4"
                                    />
                                  </video>
                                ) : (
                                  <video
                                    key={accexible_platform_english}
                                    id="home_application_section_video"
                                    controls
                                    controlsList="nodownload"
                                  >
                                    <source
                                      src={accexible_platform_english}
                                      type="video/mp4"
                                    />
                                  </video>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </SectionAnimation>
                      <SectionAnimation>
                        <div className="row">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-12">
                                <h4 id="home_application_section_explanation">
                                  {this.props.language.retrieve_string(
                                    "home.application.explanation"
                                  )}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SectionAnimation>
                      <SectionAnimation>
                        <div
                          id="home_application_section_card_container"
                          className="row"
                        >
                          <div className="container-fluid">
                            <div className="row mx-auto justify-content-center">
                              <ApplicationCard
                                container_class={
                                  "col-8 col-xs-8 col-sm-8 col-md-8 col-xl-4 col-lg-4"
                                }
                                icon={<FaCheckCircle />}
                                header={this.props.language.retrieve_string(
                                  "home.application.cards.accurate_effective.header"
                                )}
                                information={this.props.language.retrieve_string(
                                  "home.application.cards.accurate_effective.explanation"
                                )}
                              />
                              <ApplicationCard
                                container_class={
                                  "col-8 col-xs-8 col-sm-8 col-md-8 col-xl-4 col-lg-4"
                                }
                                icon={<FaTasks />}
                                header={this.props.language.retrieve_string(
                                  "home.application.cards.evolution.header"
                                )}
                                information={this.props.language.retrieve_string(
                                  "home.application.cards.evolution.explanation"
                                )}
                              />
                              <ApplicationCard
                                container_class={
                                  "col-8 col-xs-8 col-sm-8 col-md-8 col-xl-4 col-lg-4"
                                }
                                icon={<FaCloudUploadAlt />}
                                header={this.props.language.retrieve_string(
                                  "home.application.cards.digital.header"
                                )}
                                information={this.props.language.retrieve_string(
                                  "home.application.cards.digital.explanation"
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </SectionAnimation>
                      <SectionAnimation>
                        <div className="row mx-auto justify-content-center">
                          <Button
                            id="home_application_section_schedule_demo_button"
                            onClick={this.function_open_schedule_modal}
                          >
                            {this.props.language.retrieve_string(
                              "home.home_header.schedule_demo"
                            )}
                          </Button>
                        </div>
                      </SectionAnimation>

                      {/*
                                            <SectionAnimation>
                                                <div className="row">
                                                    <div id="organizational_chart">
                                                        <div id="organizational_chart_container">
                                                            <ol className="application_organizational_chart">
                                                                <li>
                                                                    <div>
                                                                        <h1 className="organizational_chart_primary_text">{this.props.language.retrieve_string('home.application.flowchart.primary.header')}</h1>
                                                                    </div>
                                                                    <ol>
                                                                        <li>
                                                                            <div className="organizational_chart_explanation_container">
                                                                                <div className="col-12">
                                                                                    <div className="row mx-auto align-items-center justify-content-center organizational_chart_secondary_header_container">
                                                                                        <h2>{this.props.language.retrieve_string('home.application.flowchart.secondary.cost_effective.header')}</h2>
                                                                                    </div>
                                                                                    <div className="row mx-auto justify-content-center organizational_chart_secondary_explanation_container">
                                                                                        <p>{this.props.language.retrieve_string('home.application.flowchart.secondary.cost_effective.explanation')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="organizational_chart_explanation_container">
                                                                                <div className="col-12">
                                                                                    <div className="row mx-auto align-items-center justify-content-center organizational_chart_secondary_header_container">
                                                                                        <h2>{this.props.language.retrieve_string('home.application.flowchart.secondary.quality_life.header')}</h2>
                                                                                    </div>
                                                                                    <div className="row mx-auto justify-content-center organizational_chart_secondary_explanation_container">
                                                                                        <p>{this.props.language.retrieve_string('home.application.flowchart.secondary.quality_life.explanation')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="organizational_chart_explanation_container">
                                                                                <div className="col-12">
                                                                                    <div className="row mx-auto align-items-center justify-content-center organizational_chart_secondary_header_container">
                                                                                        <h2>{this.props.language.retrieve_string('home.application.flowchart.secondary.personalised.header')}</h2>
                                                                                    </div>
                                                                                    <div className="row mx-auto justify-content-center organizational_chart_secondary_explanation_container">
                                                                                        <p>{this.props.language.retrieve_string('home.application.flowchart.secondary.personalised.explanation')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="organizational_chart_explanation_container">
                                                                                <div className="col-12">
                                                                                    <div className="row mx-auto align-items-center justify-content-center organizational_chart_secondary_header_container">
                                                                                        <h2>{this.props.language.retrieve_string('home.application.flowchart.secondary.accurate.header')}</h2>
                                                                                    </div>
                                                                                    <div className="row mx-auto justify-content-center organizational_chart_secondary_explanation_container">
                                                                                        <p>{this.props.language.retrieve_string('home.application.flowchart.secondary.accurate.explanation')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="organizational_chart_explanation_container">
                                                                                <div className="col-12">
                                                                                    <div className="row mx-auto align-items-center justify-content-center organizational_chart_secondary_header_container">
                                                                                        <h2>{this.props.language.retrieve_string('home.application.flowchart.secondary.evolution.header')}</h2>
                                                                                    </div>
                                                                                    <div className="row mx-auto justify-content-center organizational_chart_secondary_explanation_container">
                                                                                        <p>{this.props.language.retrieve_string('home.application.flowchart.secondary.evolution.explanation')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="organizational_chart_explanation_container">
                                                                                <div className="col-12">
                                                                                    <div className="row mx-auto align-items-center justify-content-center organizational_chart_secondary_header_container">
                                                                                        <h2>{this.props.language.retrieve_string('home.application.flowchart.secondary.digital.header')}</h2>
                                                                                    </div>
                                                                                    <div className="row mx-auto justify-content-center organizational_chart_secondary_explanation_container">
                                                                                        <p>{this.props.language.retrieve_string('home.application.flowchart.secondary.digital.explanation')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SectionAnimation>
                                             */}
                    </div>
                  </div>
                </SectionAnimation>
              </SectionAnimation>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.show_schedule_modal}
          onClose={this.function_close_schedule_modal}
        >
          <DialogTitle
            className="mx-auto"
            style={{ color: "#515fc9", fontWeight: "500" }}
          >
            {this.props.language.retrieve_string(
              "home.home_header.schedule_demo"
            )}
          </DialogTitle>
          <div id="home_header_schedule_demo_explanation" className="container">
            <DialogContentText
              className="text-justify"
              style={{
                color: "#7f7f7f",
                fontSize:
                  document.documentElement.clientWidth > 992
                    ? "1.125vw"
                    : "2.5vw",
              }}
            >
              {this.props.language.retrieve_string(
                "home.home_header.schedule_demo_explanation"
              )}
            </DialogContentText>
          </div>
          <DialogContent>
            <Form>
              <FormControl fullWidth style={{ marginBottom: "0.75vw" }}>
                <TextField
                  error={this.state.email_format_error}
                  id="standard-basic"
                  label={this.props.language.retrieve_string(
                    "authentication.sign_in.email_address"
                  )}
                  name="email_address"
                  onChange={this.function_on_change}
                  helperText={
                    this.state.email_format_error
                      ? this.props.language.retrieve_string(
                          "home.home_header.email_format_error"
                        )
                      : null
                  }
                  style={{ backgroundColor: "transparent" }}
                />
              </FormControl>
              <KeyboardDatePicker
                label={this.props.language.retrieve_string(
                  "home.home_header.demo_date"
                )}
                minDate={Date.now()}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={this.state.schedule_selected_date}
                onChange={this.function_select_schedule_date}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                style={{
                  display: "inline-flex",
                  width: "50%",
                  paddingRight: "1vw",
                }}
              />
              <KeyboardTimePicker
                label={this.props.language.retrieve_string(
                  "home.home_header.demo_hour"
                )}
                margin="normal"
                id="time-picker"
                value={this.state.schedule_selected_hour}
                onChange={this.function_select_schedule_date}
                KeyboardButtonProps={{ "aria-label": "change time" }}
                style={{ display: "inline-flex", width: "50%" }}
              />
            </Form>
          </DialogContent>
          <DialogActions>
            <div className="row mx-auto" style={{ width: "100%" }}>
              <div className="col-6 col-xs-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                <Button
                  id="home_header_schedule_demo_modal_view_request_button"
                  variant="contained"
                  onClick={this.function_submit_schedule_demo_formulary}
                  disabled={this.state.email_send_loading}
                >
                  {this.state.email_send_loading && (
                    <CircularProgress
                      style={{
                        color: "#f8f8ff",
                        width: "1.75rem",
                        height: "1.75rem",
                      }}
                    />
                  )}
                  {!this.state.email_send_loading &&
                    this.props.language.retrieve_string(
                      "common.buttons.request"
                    )}
                </Button>
              </div>
              <div className="col-6 col-xs-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                <Button
                  id="home_header_schedule_demo_modal_view_close_button"
                  onClick={this.function_close_schedule_modal}
                >
                  {this.props.language.retrieve_string("common.buttons.close")}
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </SectionAnimation>
    );
  }
}

export default withLanguage(Application);
