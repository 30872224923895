// Import libraries
import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { FaVolumeUp, FaPlayCircle, FaStopCircle, FaCheckCircle, FaTimesCircle } from "react-icons/fa";

// Import Firebase
import app from "firebase/app";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

// Import authentication context
import AuthUserContext from "../../../../../contexts/session/index";

// Import contexts
import { withLanguage } from "../../../../../contexts/language/index";

// Import constants
import { testRefs } from "../utils/constants";

// Import objects
import TestBatteryAudioFiles from "../../../../../objects/test_battery_audio_files/TestBatteryAudioFiles";
import TestBatteryUrls from "../../../../../objects/test_battery_urls/TestBatteryUrls";

// Import CSS
import "../../../../../styles/components/private/profile/navigation_components/patient_version/TestBatteryDetails.css";

// Import resources
import noGraphic from "../../../../../resources/images/test_resources/no-graphic.png";

class TestBatteryDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      open_result_modal: false,
      test_battery: null,
      test_battery_date: null,
      table_height: 0,
      test_battery_urls: TestBatteryUrls(),
      audio_files: TestBatteryAudioFiles(),
      download_modal_shown: false,
      download_progress: 0,
    };
    this.function_open_modal = this.function_open_modal.bind(this);
    this.function_close_modal = this.function_close_modal.bind(this);
    this.function_play_audio_file = this.function_play_audio_file.bind(this);
    this.function_stop_audio_file = this.function_stop_audio_file.bind(this);
    this.function_stop_audio_files = this.function_stop_audio_files.bind(this);
  }

  componentDidMount() {
    if (this.refs.profile_patient_test_battery_details_file_list_container !== undefined) {
      this.setState({
        table_height: this.refs.profile_patient_test_battery_details_file_list_container.clientHeight,
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.function_retrieve_test_battery_information();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected_battery_id !== this.props.selected_battery_id) {
      this.function_stop_audio_files();
      this.function_retrieve_test_battery_information();
      if (this.state.table_height === 0) {
        if (this.refs.profile_patient_test_battery_details_file_list_container !== undefined) {
          this.setState({
            table_height: this.refs.profile_patient_test_battery_details_file_list_container.clientHeight,
          });
        }
      }
    }
  }

  function_open_modal = () => {
    this.setState({ open_result_modal: true });
  };

  function_close_modal = () => {
    this.setState({ open_result_modal: false });
  };

  function_retrieve_test_battery_information = () => {
    this.database = app.firestore();

    this.database
      .collection("TestBatteries")
      .where("BatteryID", "==", this.props.selected_battery_id)
      .where("PatientID", "==", this.context.uid)
      .limit(1)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((document) => {
            let data = document.data();
            //console.log(data);

            let date = new Date(data.BatteryTestDate);
            this.setState({
              test_battery: data,
              test_battery_date: date.toLocaleDateString(),
            });

            const tBattery = this.state.test_battery;
            if (Array.isArray(tBattery.Image_Score)) {
              tBattery.Image_Score.map((image, i) => {
                if (!tBattery.Image_Score[i] || tBattery.Image_Score[i] === null || tBattery.Image_Score[i] === "") {
                  tBattery.Image_Score[i] = noGraphic;
                }
                return null;
              });
            } else {
              if (!tBattery.Image_Score || tBattery.Image_Score === null || tBattery.Image_Score === "") {
                tBattery.Image_Score = noGraphic;
              }
            }
            this.setState({
              test_battery: tBattery,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function_play_audio_file = (audio_file_reference, audio_url, audio_playing_reference) => {
    this.function_stop_audio_files();
    let updated_audio_files = this.state.audio_files;

    if (this.state.test_battery_urls[audio_url] === null) {
      let storage_reference = firebase.storage().ref();

      storage_reference
        .child(`AcceXibleApp/Audios/${this.props.selected_patient_id}/${this.props.selected_battery_id}/${this.state.test_battery[audio_file_reference]}`)
        .getDownloadURL()
        .then((url) => {
          this.setState({ download_modal_shown: true });

          let xhr = new XMLHttpRequest();
          xhr.responseType = "blob";

          xhr.onprogress = (event) => {
            this.setState({
              download_progress: (event.loaded / event.total) * 100,
            });
          };

          xhr.onerror = (event) => {
            this.setState({
              download_modal_shown: false,
              download_progress: 0,
            });
          };

          xhr.onload = (event) => {
            let blob = xhr.response;
            const dataUrl = URL.createObjectURL(blob);

            let updated_test_battery_urls = this.state.test_battery_urls;
            updated_test_battery_urls[audio_url] = dataUrl;
            this.setState({ test_battery_urls: updated_test_battery_urls });

            updated_audio_files[audio_file_reference] = new Audio(this.state.test_battery_urls[audio_url]);
            updated_audio_files[audio_playing_reference] = true;

            this.setState({ audio_files: updated_audio_files });

            this.setState({ download_modal_shown: false });

            this.state.audio_files[audio_file_reference].play();

            this.setState({ download_progress: 0 });

            this.state.audio_files[audio_file_reference].addEventListener("ended", () => {
              this.function_stop_audio_file(null, audio_playing_reference);
            });
          };
          xhr.open("GET", url);
          xhr.send();
        });
    } else {
      updated_audio_files[audio_file_reference] = new Audio(this.state.test_battery_urls[audio_url]);
      updated_audio_files[audio_playing_reference] = true;

      this.setState({ audio_files: updated_audio_files });

      this.state.audio_files[audio_file_reference].play();

      this.state.audio_files[audio_file_reference].addEventListener("ended", () => {
        this.function_stop_audio_file(null, audio_playing_reference);
      });
    }
  };

  function_stop_audio_file = (audio_file_reference, audio_playing_reference) => {
    let updated_audio_files = this.state.audio_files;

    if (audio_file_reference === null) {
      updated_audio_files[audio_playing_reference] = false;
    } else {
      if (this.state.audio_files[audio_playing_reference]) {
        this.state.audio_files[audio_file_reference].pause();

        let updated_audio_files = this.state.audio_files;
        updated_audio_files[audio_file_reference].currentTime = 0;
        updated_audio_files[audio_playing_reference] = false;
      }
    }

    this.setState({ audio_files: updated_audio_files });
  };

  function_stop_audio_files = () => {
    testRefs.forEach((elem) => this.function_stop_audio_file(elem.file_ref, elem.playing_ref));
  };

  componentWillUnmount() {
    this.function_stop_audio_files();
  }

  render() {
    return this.props.selected_battery_id === null ? (
      <div></div>
    ) : (
      <div id="profile_page_patient_version_navigation_profile_details_container" className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
        <div id="profile_patient_test_battery_details_header_container" className="row justify-content-center text-center align-content-center">
          {this.state.test_battery !== null ? this.state.test_battery.BatteryID : ""}
        </div>
        <div id="profile_patient_test_battery_details_information_container" className="row justify-content-center align-content-center">
          <div className="col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
            <div id="profile_patient_test_battery_details_information_header" className="row justify-content-center align-content-center">
              {this.props.language.retrieve_string("profile.test_battery_details.information")}
            </div>
            <div className="row justify-content-center align-content-center">
              <Paper>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell id="profile_patient_test_battery_details_information_table_date">{this.props.language.retrieve_string("profile.test_battery_details.date")}</TableCell>
                        <TableCell align="center">{this.state.test_battery_date !== null ? this.state.test_battery_date : ""}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell id="profile_patient_test_battery_details_information_table_completed">
                          {this.props.language.retrieve_string("profile.test_battery_details.test_battery_completed")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: this.state.test_battery !== null ? (this.state.test_battery.BatteryCompleted ? "green" : "red") : "",
                          }}
                        >
                          {this.state.test_battery !== null ? this.state.test_battery.BatteryCompleted ? <FaCheckCircle /> : <FaTimesCircle /> : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell id="profile_patient_test_battery_details_information_table_uploaded">
                          {this.props.language.retrieve_string("profile.test_battery_details.test_battery_uploaded")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: this.state.test_battery !== null ? (this.state.test_battery.BatteryUploaded ? "green" : "red") : "",
                          }}
                        >
                          {this.state.test_battery !== null ? this.state.test_battery.BatteryUploaded ? <FaCheckCircle /> : <FaTimesCircle /> : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell id="profile_patient_test_battery_details_information_table_result">
                          {this.props.language.retrieve_string("profile.test_battery_details.test_battery_result")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: this.state.test_battery !== null ? (this.state.test_battery.Score ? "" : "red") : "",
                          }}
                        >
                          {this.state.test_battery !== null ? (
                            this.state.test_battery.Score ? (
                              <Button onClick={this.function_open_modal} id="profile_patient_test_battery_details_information_table_show_score_button">
                                {this.props.language.retrieve_string("profile.test_battery_details.show_score")}
                              </Button>
                            ) : (
                              <FaTimesCircle />
                            )
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>

          <div className="col-6 col-xs-6 col-md-6 col-lg-6 col-xl-6">
            <div id="profile_patient_test_battery_details_information_header" className="row justify-content-start align-content-start">
              {this.props.language.retrieve_string("profile.test_battery_details.graphic_score")}
            </div>
            <div className="row justify-content-start align-content-start">
              <Paper>
                <TableContainer>
                  {!Array.isArray(this.state.test_battery.Image_Score) && <img id="patient_score_image" src={this.state.score_result.Image_Score} alt="graphic score" />}
                  {Array.isArray(this.state.test_battery.Image_Score) &&
                    this.state.test_battery.Image_Score.map((image, i) => {
                      return <img id={`patient_score_image${i + 1}`} src={image} alt="graphic score" />;
                    })}
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
        <div id="profile_patient_test_battery_details_file_list_container_header" className="row justify-content-center align-content-center">
          {this.props.language.retrieve_string("profile.test_battery_details.file_list")}
        </div>
        <div id="profile_patient_test_battery_details_file_list_container" ref="profile_patient_test_battery_details_file_list_container">
          <Paper>
            <TableContainer id="test_battery_results_table" style={{ height: this.state.table_height + "px" }}>
              <Table stickyHeader size="small" aria-label="sticky a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell id="test_battery_results_table_header_cell_text_file_name" align="center">
                      {this.props.language.retrieve_string("testBattery.results.results_table.header.file_name")}
                    </TableCell>
                    <TableCell id="test_battery_results_table_header_cell_volume_icon" align="center">
                      <FaVolumeUp />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* SVF */}
                  <TableRow>
                    <TableCell id="test_battery_results_table_SVF_header" colSpan={2}>
                      {this.props.language.retrieve_string("testBattery.testStep.SVF.name")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="test_battery_results_table_SVF_file_name" align="left">
                      {this.props.language.retrieve_string("testBattery.results.results_table.body.unique")}
                    </TableCell>
                    <TableCell
                      id="test_battery_results_table_SVF_audio"
                      align="center"
                      style={{
                        color: this.state.test_battery !== null ? (this.state.test_battery.SVF_UploadSuccess ? "" : "red") : "",
                        fontSize: this.state.test_battery !== null ? (this.state.test_battery.SVF_UploadSuccess ? "" : document.documentElement.clientWidth > 992 ? "1.75vw" : "4vw") : "",
                      }}
                    >
                      {this.state.test_battery !== null ? (
                        this.state.test_battery.SVF_UploadSuccess ? (
                          <Button
                            variant="text"
                            id={this.state.audio_files["SVF_audio_playing"] ? "test_battery_results_table_SVF_audio_stop" : "test_battery_results_table_SVF_audio_play"}
                            onClick={() => {
                              this.state.audio_files["SVF_audio_playing"]
                                ? this.function_stop_audio_file("SVF_Filename", "SVF_audio_playing")
                                : this.function_play_audio_file("SVF_Filename", "SVF_url", "SVF_audio_playing");
                            }}
                          >
                            {this.state.audio_files["SVF_audio_playing"] ? <FaStopCircle /> : <FaPlayCircle />}
                          </Button>
                        ) : (
                          <FaTimesCircle />
                        )
                      ) : (
                        <FaTimesCircle />
                      )}
                    </TableCell>
                  </TableRow>

                  {/* Image description */}
                  <TableRow>
                    <TableCell id="test_battery_results_table_Image_Description_header" colSpan={2}>
                      {this.props.language.retrieve_string("testBattery.testStep.Image_Description.name")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="test_battery_results_table_Image_Description_file_name" align="left">
                      {this.props.language.retrieve_string("testBattery.results.results_table.body.unique")}
                    </TableCell>
                    <TableCell
                      id="test_battery_results_table_Image_Description_audio"
                      align="center"
                      style={{
                        color: this.state.test_battery !== null ? (this.state.test_battery.Image_Description_UploadSuccess ? "" : "red") : "",
                        fontSize:
                          this.state.test_battery !== null ? (this.state.test_battery.Image_Description_UploadSuccess ? "" : document.documentElement.clientWidth > 992 ? "1.75vw" : "4vw") : "",
                      }}
                    >
                      {this.state.test_battery !== null ? (
                        this.state.test_battery.ASVF_UploadSuccess ? (
                          <Button
                            variant="text"
                            id={
                              this.state.audio_files["Image_Description_audio_playing"]
                                ? "test_battery_results_table_Image_Description_audio_stop"
                                : "test_battery_results_table_Image_Description_audio_play"
                            }
                            onClick={() => {
                              this.state.audio_files["Image_Description_audio_playing"]
                                ? this.function_stop_audio_file("Image_Description_Filename", "Image_Description_audio_playing")
                                : this.function_play_audio_file("Image_Description_Filename", "ImageDescription_url", "Image_Description_audio_playing");
                            }}
                          >
                            {this.state.audio_files["Image_Description_audio_playing"] ? <FaStopCircle /> : <FaPlayCircle />}
                          </Button>
                        ) : (
                          <FaTimesCircle />
                        )
                      ) : (
                        <FaTimesCircle />
                      )}
                    </TableCell>
                  </TableRow>

                  {/* Conversation */}
                  <TableRow>
                    <TableCell id="test_battery_results_table_Conversation_header" colSpan={2}>
                      {this.props.language.retrieve_string("testBattery.testStep.Conversation.name")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="test_battery_results_table_Conversation_file_name" align="left">
                      {this.props.language.retrieve_string("testBattery.results.results_table.body.unique")}
                    </TableCell>
                    <TableCell
                      id="test_battery_results_table_Conversation_audio"
                      align="center"
                      style={{
                        color: this.state.test_battery !== null ? (this.state.test_battery.Conversation_UploadSuccess ? "" : "red") : "",
                        fontSize: this.state.test_battery !== null ? (this.state.test_battery.Conversation_UploadSuccess ? "" : document.documentElement.clientWidth > 992 ? "1.75vw" : "4vw") : "",
                      }}
                    >
                      {this.state.test_battery !== null ? (
                        this.state.test_battery.Conversation_UploadSuccess ? (
                          <Button
                            variant="text"
                            id={this.state.audio_files["Conversation_audio_playing"] ? "test_battery_results_table_Conversation_audio_stop" : "test_battery_results_table_Conversation_audio_play"}
                            onClick={() => {
                              this.state.audio_files["Conversation_audio_playing"]
                                ? this.function_stop_audio_file("Conversation_Filename", "Conversation_audio_playing")
                                : this.function_play_audio_file("Conversation_Filename", "Conversation_url", "Conversation_audio_playing");
                            }}
                          >
                            {this.state.audio_files["Conversation_audio_playing"] ? <FaStopCircle /> : <FaPlayCircle />}
                          </Button>
                        ) : (
                          <FaTimesCircle />
                        )
                      ) : (
                        <FaTimesCircle />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>

        <Dialog open={this.state.open_result_modal} onClose={this.function_close_modal}>
          <div>
            <DialogContent>
              {this.state.test_battery !== null ? (
                this.state.test_battery.Score >= 0.49 ? (
                  <div>
                    <p className="profile_patient_test_battery_details_score_modal_text">
                      {this.props.language.retrieve_string("profile.test_battery_details.test_battery_result_modal.DCL.explanation_1")}
                    </p>
                    <p className="profile_patient_test_battery_details_score_modal_text">
                      {this.props.language.retrieve_string("profile.test_battery_details.test_battery_result_modal.DCL.explanation_2")}
                    </p>
                    <p className="profile_patient_test_battery_details_score_modal_text">
                      {this.props.language.retrieve_string("profile.test_battery_details.test_battery_result_modal.DCL.explanation_3")}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="profile_patient_test_battery_details_score_modal_text">
                      {this.props.language.retrieve_string("profile.test_battery_details.test_battery_result_modal.non_DCL.explanation_1")}
                    </p>
                    <p className="profile_patient_test_battery_details_score_modal_text">
                      {this.props.language.retrieve_string("profile.test_battery_details.test_battery_result_modal.non_DCL.explanation_2")}
                    </p>
                    <p className="profile_patient_test_battery_details_score_modal_text">
                      {this.props.language.retrieve_string("profile.test_battery_details.test_battery_result_modal.non_DCL.explanation_3")}
                    </p>
                    <p className="profile_patient_test_battery_details_score_modal_text">
                      {this.props.language.retrieve_string("profile.test_battery_details.test_battery_result_modal.non_DCL.explanation_4")}
                    </p>
                  </div>
                )
              ) : (
                ""
              )}
            </DialogContent>
            <DialogActions>
              <div className="row mx-auto">
                <div className="col-6 col-xs-6 col-md-6 col-xl-6 col-lg-6">
                  <Button variant="custom" id="profile_patient_test_battery_details_score_modal_close_button" onClick={this.function_close_modal}>
                    {this.props.language.retrieve_string("testBattery.buttons.close")}
                  </Button>
                </div>
              </div>
            </DialogActions>
          </div>
        </Dialog>

        <Dialog open={this.state.download_modal_shown}>
          <div>
            <DialogContent>
              <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" value={this.state.download_progress} style={{ width: "20vw", height: "20vw" }} thickness={2.5} />
                <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="caption" component="div" color="textSecondary" style={{ fontSize: "2vw" }}>{`${Math.round(this.state.download_progress)}%`}</Typography>
                </Box>
              </Box>
            </DialogContent>
          </div>
        </Dialog>
      </div>
    );
  }
}

TestBatteryDetails.contextType = AuthUserContext;

TestBatteryDetails.propTypes = {
  selected_battery_id: PropTypes.string,
  selected_patient_id: PropTypes.string,
};

export default withLanguage(TestBatteryDetails);
