// Import libraries
import React from "react";
import PropTypes from "prop-types";

// Import context
import AuthUserContext from "../../../contexts/session/index";

// Import objects
import TestBattery from "../../../objects/test_battery/Object_TestBattery";
import TestBatteryUrls from "../../../objects/test_battery_urls/TestBatteryUrls";

// Import components
import TestBatteryCancelButton from "./common/TestBatteryCancelButton";
import TestBatteryExplanation from "./test_battery_explanation/TestBatteryExplanation";
import TestStepExplanationView from "./test_steps/common/TestStepExplanationView";
import TestStepView from "./test_steps/TestStepView";
import TestStepFinishView from "./test_steps/common/TestStepFinishView";
import TestBatteryResults from "./test_battery_results/TestBatteryResults";

// Import resources
import background_image from "../../../resources/images/background-image/BackgroundImage.jpg";

// Import CSS
import "../../../styles/components/private/test_battery/TestBattery.css";

class TestBatteryView extends React.Component {
  constructor() {
    super();
    this.state = {
      test_battery: TestBattery(),
      test_battery_urls: TestBatteryUrls(),
      test_battery_explanation: true,
      test_battery_finish: false,
      test_battery_step: 0,
      test_step_explanation: false,
      test_step_finish: false,
    };
    this.function_start_test_battery = this.function_start_test_battery.bind(this);
    this.function_start_test_step = this.function_start_test_step.bind(this);
    this.function_finish_test_step = this.function_finish_test_step.bind(this);
    this.function_next_test_step = this.function_next_test_step.bind(this);
    this.function_update_information = this.function_update_information.bind(this);
    this.function_update_blob_url = this.function_update_blob_url.bind(this);
  }

  componentDidMount() {
    let max = 999999;
    let min = 0;
    let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    let randomBatteryID = String(randomNumber).padStart(6, "0");
    let testBattery = this.state.test_battery;
    testBattery.PatientID = this.props.test_battery_patient_id;
    testBattery.CustomPatientID = this.props.test_battery_custom_patient_id;
    testBattery.UserID = this.props.test_battery_user_id;
    testBattery.BatteryTestDate = new Date().getTime();
    testBattery.BatteryID = randomBatteryID;

    this.setState({ test_battery: testBattery });
  }

  componentWillUnmount() {
    this.setState({
      test_battery: TestBattery(),
      test_battery_urls: TestBatteryUrls(),
      test_battery_explanation: true,
      test_battery_finish: false,
      test_battery_step: 0,
      test_step_explanation: false,
      test_step_finish: false,
    });
  }

  function_start_test_battery = () => {
    this.setState({ test_battery_explanation: false, test_step_explanation: true });
  };

  function_start_test_step = () => {
    this.setState({ test_step_explanation: false });
  };

  function_finish_test_step = () => {
    this.setState({ test_step_finish: true });
  };

  function_next_test_step = () => {
    if (this.state.test_battery_step === 2) {
      this.setState({ test_step_finish: false, test_battery_finish: true });
    } else {
      this.setState((prevState) => ({ test_battery_step: prevState.test_battery_step + 1, test_step_finish: false, test_step_explanation: true }));
    }
  };

  function_update_information = (parameter, value) => {
    let updated_test_battery = this.state.test_battery;
    updated_test_battery[parameter] = value;
    this.setState({ test_battery: updated_test_battery });
  };

  function_update_blob_url(parameter, value) {
    let updated_test_battery_urls = this.state.test_battery_urls;
    updated_test_battery_urls[parameter] = value;
    this.setState({ test_battery_urls: updated_test_battery_urls });
  }

  render() {
    if (this.state.test_battery_explanation) {
      return (
        <div className="container-fluid" id="test_battery_page">
          <img id="test_battery_page_background_image" src={background_image} alt="acceXible background" />
          <div id="test_battery_container" className="col-12 col-xs-12 col-sm-12 col-md-12 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8">
            <TestBatteryCancelButton cancel_test_battery={this.props.cancel_test_battery} />
            <TestBatteryExplanation start_test_battery={this.function_start_test_battery} />
          </div>
        </div>
      );
    } else if (this.state.test_battery_finish) {
      return (
        <div className="container-fluid" id="test_battery_page">
          <img id="test_battery_page_background_image" src={background_image} alt="acceXible background" />
          <div id="test_battery_container" className="col-12 col-xs-12 col-sm-12 col-md-12 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8">
            <TestBatteryResults
              end_test_battery={this.props.end_test_battery}
              test_battery={this.state.test_battery}
              update_test_battery={this.function_update_information}
              test_battery_urls={this.state.test_battery_urls}
            />
          </div>
        </div>
      );
    } else {
      if (this.state.test_step_explanation) {
        return (
          <div className="container-fluid" id="test_battery_page">
            <img id="test_battery_page_background_image" src={background_image} alt="acceXible background" />
            <div id="test_battery_container" className="col-12 col-xs-12 col-sm-12 col-md-12 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8">
              <TestBatteryCancelButton cancel_test_battery={this.props.cancel_test_battery} />
              <TestStepExplanationView test_step={this.state.test_battery_step} start_test_step={this.function_start_test_step} />
            </div>
          </div>
        );
      } else if (this.state.test_step_finish) {
        return (
          <div className="container-fluid" id="test_battery_page">
            <img id="test_battery_page_background_image" src={background_image} alt="acceXible background" />
            <div id="test_battery_container" className="col-12 col-xs-12 col-sm-12 col-md-12 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8">
              <TestBatteryCancelButton cancel_test_battery={this.props.cancel_test_battery} />
              <TestStepFinishView test_step={this.state.test_battery_step} button_action={this.function_next_test_step} />
            </div>
          </div>
        );
      } else {
        return (
          <div className="container-fluid" id="test_battery_page">
            <img id="test_battery_page_background_image" src={background_image} alt="acceXible background" />
            <div id="test_battery_container" className="col-12 col-xs-12 col-sm-12 col-md-12 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8">
              <TestBatteryCancelButton cancel_test_battery={this.props.cancel_test_battery} />
              <TestStepView
                test_battery_ID={this.state.test_battery.BatteryID}
                test_battery_user_ID={this.state.test_battery.PatientID}
                test_step={this.state.test_battery_step}
                finish_test_step={this.function_finish_test_step}
                update_test_battery={this.function_update_information}
                update_test_battery_blob={this.function_update_blob_url}
              />
            </div>
          </div>
        );
      }
    }
  }
}

TestBatteryView.propTypes = {
  end_test_battery: PropTypes.func.isRequired,
  cancel_test_battery: PropTypes.func.isRequired,
  test_battery_patient_id: PropTypes.string.isRequired,
  test_battery_custom_patient_id: PropTypes.string.isRequired,
  test_battery_user_id: PropTypes.string.isRequired,
};

TestBatteryView.contextType = AuthUserContext;

export default TestBatteryView;
