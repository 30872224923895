// Import libraries
import React from 'react';

// Import high order components
import { withLanguage } from '../../../../contexts/language/index';

// Import widgets
import SectionAnimation from '../../../widgets/animations/SectionAnimation';

// Import components
import Team from './team/Team';
import Advisors from './advisors/Advisors';
import Partnerships from './partnerships/Partnerships';

// Import CSS
import '../../../../styles/components/public/home/about_us/AboutUs.css';

class AboutUs extends React.Component {
    render() {
        return (
            <SectionAnimation>
                <div className="container-fluid">
                    <div id="home_section_about_us_text_container" className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <SectionAnimation>
                                <div className="row about_us_section">
                                    <div className="container-fluid">
                                        <div id="home_about_us_section_header_container" className="row">
                                            <div className="col-12">
                                                <h1 id="home_about_us_section_container_header">{this.props.language.retrieve_string('home.about_us.header')}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SectionAnimation>
                            <SectionAnimation>
                                <div id="home_about_us_section_text_container" className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <SectionAnimation>
                                            <div className="row home_about_us_section_container">
                                                <p className="home_about_us_section_explanation">{this.props.language.retrieve_string('home.about_us.explanation_1')}</p>
                                                <p className="home_about_us_section_explanation">{this.props.language.retrieve_string('home.about_us.explanation_2')}</p>
                                            </div>
                                        </SectionAnimation>
                                        <SectionAnimation>
                                            <div className="row home_about_us_section_container">
                                                <Team />
                                            </div>
                                        </SectionAnimation>
                                        <SectionAnimation>
                                            <div className="row home_about_us_section_container">
                                                <Advisors />
                                            </div>
                                        </SectionAnimation>
                                        <SectionAnimation>
                                            <div className="row home_about_us_section_container">
                                                <Partnerships />
                                            </div>
                                        </SectionAnimation>
                                    </div>
                                </div>
                            </SectionAnimation>
                        </div>
                    </div>
                </div>
            </SectionAnimation>
        )
    }
}

export default withLanguage(AboutUs);