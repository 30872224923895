// Import libraries
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Import context
import { LanguageProvider } from "../contexts/language/context/LanguageContext";

// Import high order components
import { withAuthentication } from "../contexts/session/index";

// Import locales
import es from "../resources/locales/es";
import en from "../resources/locales/en";

// Import widgets
import Navigation from "./widgets/navigation/Navigation";
import Footer from "./widgets/footer/Footer";

// Import components
import Home from "./public/home/Home";
import SignIn from "./authentication/sign_in/SignIn";
import Profile from "./private/index";

// Import resources
import * as ROUTES from "../resources/constants/routes";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.setLanguage = (language) => {
      this.setState((state) => ({
        language: language,
      }));
    };

    this.retrieve_string = (data_to_retrieve) => {
      if (this.state.language === "en-UK") {
        return data_to_retrieve.split(".").reduce(function (o, k) {
          return o && o[k];
        }, en);
      } else {
        return data_to_retrieve.split(".").reduce(function (o, k) {
          return o && o[k];
        }, es);
      }
    };

    this.state = {
      language: "es-ES",
      setLanguage: this.setLanguage.bind(this),
      retrieve_string: this.retrieve_string.bind(this),
      test_initialized: false,
    };

    this.function_test_initialized = this.function_test_initialized.bind(this);
    this.function_test_finalised = this.function_test_finalised.bind(this);
  }

  componentDidMount() {
    let browser_language = navigator.language || navigator.userLanguage;

    if (browser_language.startsWith("en-")) {
      this.setState({ language: "en-UK" });
    } else {
      this.setState({ language: "es-ES" });
    }
  }

  function_test_initialized = () => {
    this.setState({ test_initialized: true });
  };

  function_test_finalised = () => {
    this.setState({ test_initialized: false });
  };

  render() {
    return (
      <LanguageProvider value={this.state}>
        <BrowserRouter>
          <div id={this.state.test_initialized ? "main-wrapper-test" : "main-wrapper"}>
            {this.state.test_initialized ? null : <Navigation />}
            <div id={this.state.test_initialized ? "content-container-test" : "content-container"} className="container-fluid row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <Switch>
                  <Route exact path={ROUTES.HOME} component={Home} />
                  <Route path={ROUTES.SIGN_IN} component={SignIn} />
                  <Route
                    path={ROUTES.PROFILE}
                    render={(props) => <Profile {...props} function_test_initialized={this.function_test_initialized} function_test_finalised={this.function_test_finalised} />}
                  />
                </Switch>
              </div>
            </div>
            {this.state.test_initialized ? null : <Footer />}
          </div>
        </BrowserRouter>
      </LanguageProvider>
    );
  }
}

export default withAuthentication(App);
