// Import libraries
import React from "react";
import PropTypes from "prop-types";

// Import authentication context
import AuthUserContext, { withAuthorization } from "../../contexts/session/index";

// Import components
import Profile from "./profile/Profile";

class AuthenticationIndex extends React.Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => <Profile function_test_initialized={this.props.function_test_initialized} function_test_finalised={this.props.function_test_finalised} />}
      </AuthUserContext.Consumer>
    );
  }
}

AuthenticationIndex.propTypes = {
  function_test_initialized: PropTypes.func.isRequired,
  function_test_finalised: PropTypes.func.isRequired,
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AuthenticationIndex);
