// Import libraries
import React from "react";
import { FaUsers } from "react-icons/fa";

// Import high order components
import { withLanguage } from "../../../../../contexts/language/index";

// Import widgets
import SectionAnimation from "../../../../widgets/animations/SectionAnimation";

// Import components
import Profile from "../profile/Profile";

class Team extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <SectionAnimation>
          <div className="row">
            <div className="home_about_us_section_header_container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <span className="home_about_us_section_header_icon_container">
                <FaUsers />
              </span>
              <span className="home_about_us_section_header_text">{this.props.language.retrieve_string("home.about_us.team.header")}</span>
            </div>
          </div>
        </SectionAnimation>
        <SectionAnimation>
          <div className="row">
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_carla_zaldua_aguirre_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.carla_zaldua_aguirre.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.carla_zaldua_aguirre.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.carla_zaldua_aguirre.description")}
              disable_description={true}
            />
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_javier_zaldua_aguirre_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.javier_zaldua_aguirre.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.javier_zaldua_aguirre.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.javier_zaldua_aguirre.description")}
              disable_description={true}
            />
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_tomas_rojo_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.tomas_rojo.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.tomas_rojo.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.tomas_rojo.description")}
              disable_description={true}
            />
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_victoria_de_la_orden_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.victoria_de_la_orden.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.victoria_de_la_orden.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.victoria_de_la_orden.description")}
              disable_description={true}
            />
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_ania_zuazua_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.ania_zuazua.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.ania_zuazua.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.ania_zuazua.description")}
              disable_description={true}
            />
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_xabier_hernando_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.xabier_hernando.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.xabier_hernando.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.xabier_hernando.description")}
              disable_description={true}
            />
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_peru_gabirondo_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.peru_gabirondo.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.peru_gabirondo.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.peru_gabirondo.description")}
              disable_description={true}
            />
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_patricia_dias_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.patricia_dias.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.patricia_dias.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.patricia_dias.description")}
              disable_description={true}
            />
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_iker_honorato_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.iker_honorato.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.iker_honorato.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.iker_honorato.description")}
              disable_description={true}
            />
            <Profile
              is_team_member={true}
              container_class={"col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"}
              photo_container_class={"home_about_us_section_profile_dario_gallego_photo_container"}
              name={this.props.language.retrieve_string("home.about_us.team.team_members.dario_gallego.name")}
              position={this.props.language.retrieve_string("home.about_us.team.team_members.dario_gallego.position")}
              description={this.props.language.retrieve_string("home.about_us.team.team_members.dario_gallego.description")}
              disable_description={true}
            />
          </div>
        </SectionAnimation>
      </div>
    );
  }
}

export default withLanguage(Team);
