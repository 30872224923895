import * as firebase from 'firebase/app';
import 'firebase/firestore';

class FirestoreService {
  create_record_firestore = (test_battery) => {
    const database = firebase.firestore();
    database.collection('TestBatteries').add({
      BatteryCompleted: test_battery.BatteryCompleted,
      BatteryID: test_battery.BatteryID,
      BatteryTestDate: test_battery.BatteryTestDate,
      BatteryUploaded: test_battery.BatteryUploaded,
      Conversation_Filename: test_battery.Conversation_Filename,
      Conversation_UploadSuccess: test_battery.Conversation_UploadSuccess,
      CustomPatientID: test_battery.CustomPatientID,
      ID: test_battery.ID,
      Image_Description_Filename: test_battery.Image_Description_Filename,
      Image_Description_UploadSuccess: test_battery.Image_Description_UploadSuccess,
      iOS: test_battery.iOS,
      PatientID: test_battery.PatientID,
      SVF_Filename: test_battery.SVF_Filename,
      SVF_UploadSuccess: test_battery.SVF_UploadSuccess,
      Score: test_battery.Score,
      UserID: test_battery.UserID
    });
  };

  update_record_firestore = (parameter, value, test_battery_id, patient_id, battery_uploaded) => {
    const database = firebase.firestore();
    database
      .collection('TestBatteries')
      .where('BatteryID', '==', test_battery_id)
      .where('PatientID', '==', patient_id)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((document) => {
            database
              .collection('TestBatteries')
              .doc(document.id)
              .update({ [parameter]: value, BatteryUploaded: battery_uploaded });
          });
        }
      })
      .catch((error) => {
        console.log('Error getting documents', error);
      });
  };
};

export default FirestoreService;
