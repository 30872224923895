// Import libraries
import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

// Import CSS
import "../../../../../styles/components/private/test_battery/test_steps/shared/TestStepButton.css";

class TestStepButton extends React.Component {
  render() {
    return (
      <div id="test_step_common_view_button_container" className="row mx-auto justify-content-center align-content-center">
        <Button id="test_step_common_view_button" onClick={this.props.onClick_action}>
          {this.props.button_text}
        </Button>
      </div>
    );
  }
};

TestStepButton.propTypes = {
  onClick_action: PropTypes.func.isRequired,
  button_text: PropTypes.string.isRequired,
};

export default TestStepButton;
