// Import libraries
import React from 'react';
import { FaPhoneAlt, FaMobileAlt, FaStethoscope, FaHospital, FaHome, FaSyringe } from 'react-icons/fa';

// Import high order components
import { withLanguage } from '../../../../contexts/language/index';

// Import widgets
import SectionAnimation from '../../../widgets/animations/SectionAnimation';

// Import components
import TargetsPanel from './TargetsPanel';

// Import CSS
import '../../../../styles/components/public/home/targets/Targets.css';

class Targets extends React.Component {
    render() {
        return (
            <SectionAnimation>
                <div id="home_targets_section_container" className="container-fluid">
                    <div id="home_targets_aplication_text_container" ref="home_section_targets_text_container" className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <SectionAnimation>
                                <div className="row">
                                    <div className="container-fluid">
                                        <div id="home_targets_section_header_container" className="row">
                                            <div className="col-12">
                                                <h1 id="home_targets_section_container_header">{this.props.language.retrieve_string('home.focus.header')}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SectionAnimation>
                            <div id="home_targets_section_text_container" className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    {
                                        this.props.language.language === 'es-ES'
                                            ?
                                            <SectionAnimation>
                                                <TargetsPanel is_left_panel={true} panel_reference="target_panel_teleservice" header="home.focus.teleservice.name" icon={<FaPhoneAlt />} text="home.focus.teleservice.description" />
                                                <TargetsPanel is_left_panel={false} panel_reference="target_panel_primary_health_care" header="home.focus.primary_health_care.name" icon={<FaHospital />} text="home.focus.primary_health_care.description" />
                                                <TargetsPanel is_left_panel={true} panel_reference="target_panel_clinical_trials" header="home.focus.clinical_trials.name" icon={<FaSyringe />} text="home.focus.clinical_trials.description" />
                                                <TargetsPanel is_left_panel={false} panel_reference="target_panel_medical_insurances" header="home.focus.medical_insurances.name" icon={<FaStethoscope />} text="home.focus.medical_insurances.description" />
                                                <TargetsPanel is_left_panel={true} panel_reference="target_panel_day_centres" header="home.focus.day_centres.name" icon={<FaHome />} text="home.focus.day_centres.description" />
                                                <TargetsPanel is_left_panel={false} panel_reference="target_panel_cognitive_stimulation_apps" header="home.focus.cognitive_stimulation_apps.name" icon={<FaMobileAlt />} text="home.focus.cognitive_stimulation_apps.description" />
                                            </SectionAnimation>
                                            : <SectionAnimation>
                                                <TargetsPanel is_left_panel={true} panel_reference="target_panel_clinicians" header="home.focus.clinicians.name" icon={<FaMobileAlt />} text="home.focus.clinicians.description" />
                                                <TargetsPanel is_left_panel={false} panel_reference="target_panel_medical_insurances" header="home.focus.medical_insurances.name" icon={<FaStethoscope />} text="home.focus.medical_insurances.description" />
                                                <TargetsPanel is_left_panel={true} panel_reference="target_panel_teleservice" header="home.focus.teleservice.name" icon={<FaPhoneAlt />} text="home.focus.teleservice.description" />
                                                <TargetsPanel is_left_panel={false} panel_reference="target_panel_clinical_trials" header="home.focus.clinical_trials.name" icon={<FaSyringe />} text="home.focus.clinical_trials.description" />
                                            </SectionAnimation>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionAnimation>
        )
    }
}

export default withLanguage(Targets);