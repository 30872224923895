// Import libraries
import React from 'react';
import PropTypes from 'prop-types';

// Import high order components
import { withLanguage } from '../../../../contexts/language/index';

class ApplicationCard extends React.Component {
    render() {
        return (
            <div className={`home_application_card ${this.props.container_class}`}>
                <article className="home_application_section_card">
                    <div className="home_application_section_card_thumb">
                        <div className="row home_application_section_card_icon_container mx-auto align-items-center justify-content-center">{this.props.icon}</div>
                    </div>
                    <div className="home_application_section_card_information">
                        <h1 className="home_application_section_card_header">{this.props.header}</h1>
                        <p className="home_application_section_card_text">{this.props.information}</p>
                    </div>
                </article>
            </div>
        )
    }
}

ApplicationCard.propTypes = {
    container_class: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    header: PropTypes.string.isRequired,
    information: PropTypes.string.isRequired,
}

export default withLanguage(ApplicationCard);