// Import libraries
import React from "react";
import { Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { CircularProgress, Button } from "@material-ui/core";

// Import Firebase
import * as firebase from "firebase/app";
import "firebase/firestore";

// Import high order components
import { withLanguage } from "../../../../contexts/language/index";

// Import widgets
import SectionAnimation from "../../../widgets/animations/SectionAnimation";

// Import resources
import logo from "../../../../resources/images/logo/accexible_white_logo.png";
import header_video from "../../../../resources/videos/home/home_video_demo.mp4";

// Import CSS
import "../../../../styles/components/public/home/header/HomeHeader.css";

class HomeHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      show_schedule_modal: false,
      email_address: "",
      schedule_selected_date: Date.now(),
      schedule_selected_hour: Date.now(),
      email_format_error: false,
      email_send_loading: false,
    };

    this.function_on_change = this.function_on_change.bind(this);
    this.function_submit_schedule_demo_formulary = this.function_submit_schedule_demo_formulary.bind(
      this
    );
    this.function_open_schedule_modal = this.function_open_schedule_modal.bind(
      this
    );
    this.function_close_schedule_modal = this.function_close_schedule_modal.bind(
      this
    );
    this.function_select_schedule_date = this.function_select_schedule_date.bind(
      this
    );
    this.function_select_schedule_date = this.function_select_schedule_date.bind(
      this
    );
  }

  function_on_change = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  function_submit_schedule_demo_formulary = () => {
    this.setState({ email_send_loading: true, email_format_error: false });

    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email_address)
    ) {
      const database = firebase.firestore();

      database
        .collection("RequestDemo")
        .add({
          email_address: this.state.email_address,
          demo_date: new Date(this.state.schedule_selected_date).toDateString(),
          demo_hour: new Date(this.state.schedule_selected_hour).toTimeString(),
        })
        .then(() => {
          this.setState({
            show_schedule_modal: false,
            email_send_loading: false,
          });
        })
        .catch((error) => {
          this.setState({ email_send_loading: false });
          console.error("Error adding document: ", error);
        });
    } else {
      this.setState({ email_format_error: true, email_send_loading: false });
    }
  };

  function_open_schedule_modal = () => {
    this.setState({
      show_schedule_modal: true,
      schedule_selected_date: Date.now(),
      schedule_selected_hour: Date.now(),
    });
  };

  function_close_schedule_modal = () => {
    this.setState({ show_schedule_modal: false });
  };

  function_select_schedule_date = (date) => {
    this.setState({ schedule_selected_date: date });
  };

  function_select_schedule_date = (date) => {
    this.setState({ schedule_selected_hour: date });
  };

  render() {
    return (
      <SectionAnimation>
        <div id="home_header_section_container" className="container-fluid">
          <video id="home_header_section_video" loop autoPlay muted>
            <source src={header_video} type="video/mp4" />
          </video>
          <div id="home_header_section_video_filter"></div>
          <div
            id="home_header_section_text_container"
            className="row"
            style={{ height: this.state.container_heigth + "px" }}
          >
            <div className="col-12">
              <div
                id="home_header_section_brand_container"
                className="row mx-auto align-items-center justify-content-center"
              >
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 offset-lg-1 col-lg-8 offset-xl-1 col-xl-8">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 offset-lg-3 col-lg-8 offset-xl-3 col-xl-8">
                  <img
                    id="home_header_section_accexible_logo"
                    src={logo}
                    alt="acceXible logo"
                  />
                  </div>
                  <h3 id="home_header_section_text">
                    {this.props.language.retrieve_string(
                      "home.home_header.video_overlay_text"
                    )}
                  </h3>
                </div>
              </div>
              <div
                id="home_header_section_schedule_demo_button_container"
                className="row mx-auto align-items-center justify-content-center"
              >
                <Button
                  id="home_header_section_schedule_demo_button"
                  onClick={this.function_open_schedule_modal}
                >
                  {this.props.language.retrieve_string(
                    "home.home_header.schedule_demo"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.show_schedule_modal}
          onClose={this.function_close_schedule_modal}
        >
          <DialogTitle
            className="mx-auto"
            style={{ color: "#515fc9", fontWeight: "500" }}
          >
            {this.props.language.retrieve_string(
              "home.home_header.schedule_demo"
            )}
          </DialogTitle>
          <div
            id="home_header_schedule_demo_modal_view_explanation"
            className="container"
          >
            <DialogContentText
              className="text-justify"
              style={{
                color: "#7f7f7f",
                fontSize:
                  document.documentElement.clientWidth > 992
                    ? "1.125vw"
                    : "2.5vw",
              }}
            >
              {this.props.language.retrieve_string(
                "home.home_header.schedule_demo_explanation"
              )}
            </DialogContentText>
          </div>
          <DialogContent>
            <Form>
              <FormControl fullWidth style={{ marginBottom: "0.75vw" }}>
                <TextField
                  error={this.state.email_format_error}
                  id="standard-basic"
                  label={this.props.language.retrieve_string(
                    "authentication.sign_in.email_address"
                  )}
                  name="email_address"
                  onChange={this.function_on_change}
                  helperText={
                    this.state.email_format_error
                      ? this.props.language.retrieve_string(
                          "home.home_header.email_format_error"
                        )
                      : null
                  }
                  style={{ backgroundColor: "transparent" }}
                />
              </FormControl>
              <KeyboardDatePicker
                label={this.props.language.retrieve_string(
                  "home.home_header.demo_date"
                )}
                minDate={Date.now()}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={this.state.schedule_selected_date}
                onChange={this.function_select_schedule_date}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                style={{
                  display: "inline-flex",
                  width: "50%",
                  paddingRight: "1vw",
                }}
              />
              <KeyboardTimePicker
                label={this.props.language.retrieve_string(
                  "home.home_header.demo_hour"
                )}
                margin="normal"
                id="time-picker"
                value={this.state.schedule_selected_hour}
                onChange={this.function_select_schedule_date}
                KeyboardButtonProps={{ "aria-label": "change time" }}
                style={{ display: "inline-flex", width: "50%" }}
              />
            </Form>
          </DialogContent>
          <DialogActions>
            <div className="row mx-auto" style={{ width: "100%" }}>
              <div className="col-6 col-xs-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                <Button
                  id="home_header_schedule_demo_modal_view_request_button"
                  variant="contained"
                  onClick={this.function_submit_schedule_demo_formulary}
                  disabled={this.state.email_send_loading}
                >
                  {this.state.email_send_loading && (
                    <CircularProgress
                      style={{
                        color: "#f8f8ff",
                        width: "1.75rem",
                        height: "1.75rem",
                      }}
                    />
                  )}
                  {!this.state.email_send_loading &&
                    this.props.language.retrieve_string(
                      "common.buttons.request"
                    )}
                </Button>
              </div>
              <div className="col-6 col-xs-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                <Button
                  id="home_header_schedule_demo_modal_view_close_button"
                  onClick={this.function_close_schedule_modal}
                >
                  {this.props.language.retrieve_string("common.buttons.close")}
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </SectionAnimation>
    );
  }
}

export default withLanguage(HomeHeader);
