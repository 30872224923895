// Import libraries
import React from "react";
import { FaHandshake } from "react-icons/fa";

// Import high order components
import { withLanguage } from "../../../../../contexts/language/index";

// Import widgets
import SectionAnimation from "../../../../widgets/animations/SectionAnimation";

// Import components
import PartnershipPanel from "./PartnershipPanel";

// Import resources
import FederEs from "../../../../../resources/images/home/about_us/partnerships/feder_esp.png";
import FederEn from "../../../../../resources/images/home/about_us/partnerships/feder_en.jpeg";
import QuironSalud from "../../../../../resources/images/home/about_us/partnerships/quiron_salud.png";
import CDTI from "../../../../../resources/images/home/about_us/partnerships/cdti.png";
import ENISA from "../../../../../resources/images/home/about_us/partnerships/enisa.png";
import BEAZ from "../../../../../resources/images/home/about_us/partnerships/beaz.png";
import MassChallenge from "../../../../../resources/images/home/about_us/partnerships/masschallenge.png";
import aenor from "../../../../../resources/images/home/about_us/partnerships/aenor.png";
/* import spri from "../../../../../resources/images/home/about_us/partnerships/spri.png"; */
import hazitek from "../../../../../resources/images/home/about_us/partnerships/hazitek.jpg";
import award from "../../../../../resources/images/home/about_us/partnerships/award.png";

class Partnerships extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <SectionAnimation>
          <div className="row">
            <div className="home_about_us_section_header_container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <span className="home_about_us_section_header_icon_container">
                <FaHandshake />
              </span>
              <span className="home_about_us_section_header_text">
                {this.props.language.retrieve_string(
                  "home.about_us.partnerships.header"
                )}
              </span>
            </div>
          </div>
        </SectionAnimation>
        <SectionAnimation>
          <div className="row">
            <PartnershipPanel
              container_class={
                "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4"
              }
              name={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.feder"
              )}
              image={<img src={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.feder"
              ) === "Programa ICEX Next" ? FederEs : FederEn} alt="feder logo" />}
            />
            <PartnershipPanel
              container_class={
                "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4"
              }
              name={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.quiron_salud"
              )}
              image={<img src={QuironSalud} alt="QuironSalud logo" />}
            />
            <PartnershipPanel
              container_class={
                "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4"
              }
              name={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.cdti"
              )}
              image={<img src={CDTI} alt="Neotec/CDTI logo" />}
            />
            <PartnershipPanel
              container_class={
                "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4"
              }
              name={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.enisa"
              )}
              image={<img src={ENISA} alt="ENISA logo" />}
            />
            <PartnershipPanel
              container_class={
                "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4"
              }
              name={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.beaz"
              )}
              image={<img src={BEAZ} alt="Bizkaia BEAZ logo" />}
            />
            <PartnershipPanel
              container_class={
                "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4"
              }
              name={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.masschallenge"
              )}
              image={<img src={MassChallenge} alt="MassChallenge logo" />}
            />
            <PartnershipPanel
              container_class={
                "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4"
              }
              name={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.aenor"
              )}
              image={<img src={aenor} alt="MassChallenge logo" />}
            />
            <PartnershipPanel
              container_class={
                "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4"
              }
              name={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.spri"
              )}
              image={<img src={hazitek} alt="MassChallenge logo" />}
            />
            <PartnershipPanel
              container_class={
                "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4"
              }
              name={this.props.language.retrieve_string(
                "home.about_us.partnerships.partners.award"
              )}
              image={<img src={award} alt="MassChallenge logo" />}
            />
          </div>
        </SectionAnimation>
      </div>
    );
  }
}

export default withLanguage(Partnerships);
