// Import libraries
import React from 'react';
import PropTypes from 'prop-types';

// Import high order components
import { withLanguage } from '../../../../contexts/language/index';

// Import widgets
import SectionAnimation from '../../../widgets/animations/SectionAnimation';

class TargetsPanel extends React.Component {
    constructor() {
        super();

        this.state = {
            icon_height: 0
        }
    }

    componentDidMount() {
        this.timeout = setTimeout(() => {
            if (this.refs[this.props.panel_reference]) {
                this.setState({ icon_height: this.refs[this.props.panel_reference].clientHeight });
            }
        }, 100);

        window.addEventListener('resize', () => {
            if (this.refs[this.props.panel_reference]) {
                this.setState({ icon_height: this.refs[this.props.panel_reference].clientHeight });
            }
        });

        window.addEventListener('load', () => {
            if (this.refs[this.props.panel_reference]) {
                this.setState({ icon_height: this.refs[this.props.panel_reference].clientHeight });
            }
        });
    }

    render() {
        if (document.documentElement.clientWidth > 992) {
            if (this.props.is_left_panel) {
                return (
                    <SectionAnimation>
                        <div className="home_targets_section_panel_container_left col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <article ref={this.props.panel_reference} style={{ paddingLeft: this.state.icon_height / document.documentElement.clientWidth * 100 + (this.state.icon_height / document.documentElement.clientWidth * 100 * 0.2) + 'vw' }}>
                                <div className="home_targets_section_panel_container_icon_container_left" style={{ width: this.state.icon_height / document.documentElement.clientWidth * 100 + 'vw', height: this.state.icon_height / document.documentElement.clientWidth * 100 + 'vw' }}>
                                    <div className="row mx-auto align-items-center justify-content-center">
                                        {this.props.icon}
                                    </div>
                                </div>
                                <h1 className="home_targets_section_panel_container_header_left text-left">{this.props.language.retrieve_string(this.props.header)}</h1>
                                <p className="home_targets_section_panel_container_text_left text-left">{this.props.language.retrieve_string(this.props.text)}</p>
                            </article>
                        </div>
                    </SectionAnimation>
                )
            } else {
                return (
                    <SectionAnimation>
                        <div className="home_targets_section_panel_container_right col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <article ref={this.props.panel_reference} style={{ paddingRight: this.state.icon_height / document.documentElement.clientWidth * 100 + (this.state.icon_height / document.documentElement.clientWidth * 100 * 0.2) + 'vw' }}>
                                <h1 className="home_targets_section_panel_container_header_right text-right">{this.props.language.retrieve_string(this.props.header)}</h1>
                                <p className="home_targets_section_panel_container_text_right text-right">{this.props.language.retrieve_string(this.props.text)}</p>
                                <div className="home_targets_section_panel_container_icon_container_right" style={{ width: this.state.icon_height / document.documentElement.clientWidth * 100 + 'vw', height: this.state.icon_height / document.documentElement.clientWidth * 100 + 'vw' }}>
                                    <div className="row mx-auto align-items-center justify-content-center">
                                        {this.props.icon}
                                    </div>
                                </div>
                            </article>
                        </div>
                    </SectionAnimation>
                )
            }
        } else {
            return (
                <SectionAnimation>
                    <div className="home_targets_section_panel_container_small col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row mx-auto justify-content-center">
                            <div className="home_targets_section_panel_container_icon_container_small">
                                <div className="row mx-auto align-items-center justify-content-center">
                                    {this.props.icon}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="home_targets_section_panel_container_header_small">{this.props.language.retrieve_string(this.props.header)}</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p className="home_targets_section_panel_container_text_small">{this.props.language.retrieve_string(this.props.text)}</p>
                            </div>
                        </div>
                    </div>
                </SectionAnimation>
            );
        }
    }
}

TargetsPanel.propTypes = {
    is_left_panel: PropTypes.bool.isRequired,
    panel_reference: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired
}

export default withLanguage(TargetsPanel);