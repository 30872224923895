// Import libraries
import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

// Import high order components
import { withLanguage } from "../../../../contexts/language/index";

// Import CSS
import "../../../../styles/components/private/test_battery/test_battery_explanation/TestBatteryExplanation.css";

class TestBatteryExplanation extends React.Component {
  render() {
    return (
      <div className="col-12" id="test_battery_explanation_container">
        <div id="test_battery_explanation_header" className="row mx-auto justify-content-center align-content-center">
          <h1>{this.props.language.retrieve_string("testBattery.testBatteryExplanation.header")}</h1>
        </div>
        <div id="test_battery_explanation_text_container" className="row mx-auto align-content-center">
          <p className="test_battery_explanation_text text-justify">{ReactHtmlParser(this.props.language.retrieve_string("testBattery.testBatteryExplanation.explanation"))}</p>
          <p className="test_battery_subexplanation_text text-justify">{ReactHtmlParser(this.props.language.retrieve_string("testBattery.testBatteryExplanation.explanation_1"))}</p>
        </div>
        <div id="test_battery_explanation_start_button_container" className="row mx-auto justify-content-center align-content-center">
          <Button id="test_battery_explanation_start_button" onClick={this.props.start_test_battery}>
            {this.props.language.retrieve_string("testBattery.buttons.startTestBattery")}
          </Button>
        </div>
      </div>
    );
  }
}

TestBatteryExplanation.propTypes = {
  start_test_battery: PropTypes.func.isRequired,
};

export default withLanguage(TestBatteryExplanation);
