// Import libraries
import React from "react";
import PropTypes from "prop-types";

// Import CSS
import "../../../styles/widgets/test_step_counter/TestStepCounter.css";

class TestStepCounter extends React.Component {
  render() {
    if (this.props.test_step === 0) {
      if (this.props.explanation) {
        return (
          <div id="widget_test_step_counter_container" className="row align-content-center mx-auto">
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_current_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_empty_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_empty_dot"></div>
            </div>
          </div>
        );
      } else {
        return (
          <div id="widget_test_step_counter_container" className="row align-content-center mx-auto">
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_filled_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_empty_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_empty_dot"></div>
            </div>
          </div>
        );
      }
    } else if (this.props.test_step === 1) {
      if (this.props.explanation) {
        return (
          <div id="widget_test_step_counter_container" className="row align-content-center mx-auto">
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_filled_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_current_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_empty_dot"></div>
            </div>
          </div>
        );
      } else {
        return (
          <div id="widget_test_step_counter_container" className="row align-content-center mx-auto">
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_filled_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_filled_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_empty_dot"></div>
            </div>
          </div>
        );
      }
    } else if (this.props.test_step === 2) {
      if (this.props.explanation) {
        return (
          <div id="widget_test_step_counter_container" className="row align-content-center mx-auto">
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_filled_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_filled_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_current_dot"></div>
            </div>
          </div>
        );
      } else {
        return (
          <div id="widget_test_step_counter_container" className="row align-content-center mx-auto">
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_filled_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_filled_dot"></div>
            </div>
            <div className="widget_test_step_counter_dot_step_container justify-content-center align-items-center">
              <div className="widget_test_step_counter_filled_dot"></div>
            </div>
          </div>
        );
      }
    }
  }
};

TestStepCounter.propTypes = {
  test_step: PropTypes.number.isRequired,
  explanation: PropTypes.bool.isRequired,
};

export default TestStepCounter;
