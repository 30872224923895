// Import libraries
import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

// Import high order components
import { withLanguage } from '../../../../../contexts/language/index';

class Profile extends React.Component {
    constructor() {
        super();
        this.state = {
            show_details: false,
            panel_visible: true,
            panel_height: 0
        }

        this.function_handle_toggle = this.function_handle_toggle.bind(this);
    }

    componentDidMount() {
        this.calculation_timeout = setTimeout(() => {
            this.setState({ panel_height: this.refs.home_about_us_section_profile_container.clientHeight });
        }, 100);
    }

    componentWillUnmount() {
        if (this.calculation_timeout) {
            clearTimeout(this.calculation_timeout);
            this.calculation_timeout = 0;
        }

        if (this.transition_time_out) {
            clearTimeout(this.transition_time_out);
            this.transition_time_out = 0;
        }
    }

    function_handle_toggle = () => {
        this.setState({ show_details: !this.state.show_details, panel_visible: false });

        this.transition_time_out = setTimeout(() => {
            this.setState({ panel_visible: true });
        }, 500)
    }

    render() {
        return (
            <div className={`home_about_us_section_profile ${this.props.container_class}`}>
                <div className="home_about_us_section_profile_container" ref="home_about_us_section_profile_container">
                    {
                        this.state.panel_visible
                            ? this.state.show_details
                                ?
                                <AnimateHeight duration={750} onClick={this.props.disable_description ? null : this.function_handle_toggle} height={this.state.show_details ? document.documentElement.clientWidth > 992 ? 250 : 100 : this.state.panel_height} style={{ overflowY: 'auto', overflow: 'scroll' }}>
                                    <div className={`row mx-auto align-items-center ${this.state.panel_visible ? "home_about_us_section_profile_visible" : "home_about_us_section_profile_not_visible"}`}>
                                        <div className="col-12">
                                            <div className="row home_about_us_section_profile_details_header_container">
                                                <div className="home_about_us_section_profile_details_header_name mr-auto align-self-center">{this.props.name}</div>
                                                {
                                                    this.props.is_team_member ? <div className="home_about_us_section_profile_details_header_position ml-auto align-self-center">{this.props.position}</div> : null
                                                }
                                            </div>
                                            <div className="home_about_us_section_profile_details_description row" style={{ overflowY: 'auto' }}>
                                                {this.props.description}
                                            </div>
                                        </div>
                                    </div>
                                </AnimateHeight>
                                : <AnimateHeight duration={750} onClick={this.props.disable_description ? null : this.function_handle_toggle} height={this.state.show_details ? document.documentElement.clientWidth > 992 ? 250 : 100 : 140}>
                                    <div className={`row mx-auto align-items-center ${this.state.panel_visible ? "home_about_us_section_profile_visible" : "home_about_us_section_profile_not_visible"}`} >
                                        <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-4 col-xl-4 text-center">
                                            <div className={`${this.props.photo_container_class}`}>

                                            </div>
                                        </div>
                                        <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-8 col-xl-8">
                                            <div className="row" >
                                                <div className="col-12">
                                                    <h1 className="home_about_us_section_profile_name" >{this.props.name}</h1>
                                                </div>
                                            </div>
                                            {
                                                this.props.is_team_member ? <div className="row" >
                                                    <div className="col-12" >
                                                        <h2 className="home_about_us_section_profile_position" >{this.props.position}</h2>
                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                </AnimateHeight>
                            : <AnimateHeight duration={750} onClick={this.props.disable_description ? null : this.function_handle_toggle} height={this.state.show_details ? document.documentElement.clientWidth > 992 ? 250 : 100 : this.state.panel_height}>
                                <div className={`row mx-auto align-items-center ${this.state.panel_visible ? "home_about_us_section_profile_visible" : "home_about_us_section_profile_not_visible"}`} >
                                    <div style={{ height: this.state.panel_height + 'px' }}></div>
                                </div>
                            </AnimateHeight>
                    }
                </div>
            </div >
        )
    }
}

Profile.propTypes = {
    is_team_member: PropTypes.bool.isRequired,
    container_class: PropTypes.string.isRequired,
    photo_container_class: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    disable_description: PropTypes.bool.isRequired
}

export default withLanguage(Profile);