const es = {
  common: {
    buttons: {
      close: "CERRAR",
      create_user: "CREAR CUENTA",
      request: "SOLICITAR",
      send: "ENVIAR",
      sign_in: "INICIAR SESIÓN",
      start_test_battery: "EMPEZAR BATERÍA DE PRUEBAS",
      reload: "RECARGAR",
    },
    navigationBar: {
      home: "Inicio",
      application: "DCL App",
      profile: "Perfil",
      sign_in: "Iniciar sesión",
      sign_out: "Cerrar sesión",
    },
    footer: {
      address: {
        bilbo: {
          city: "BIZKAIA",
          name: "Torre Urduliz - Nagusi Intelligence Center",
          street: "Aita Gotzon, 37",
          postal_code: "48610 Urduliz",
        },
        barcelona: {
          city: "BARCELONA",
          name: "Tech Barcelona",
          street: "Plaça de Pau Vila, 1 Bloc A, Planta 3, Porta 3A1",
          postal_code: "08003 Barcelona",
        },
      },
      contact_us: "CONTACTA",
      contact_us_form_email: "Tu dirección de correo electrónico",
      contact_us_form_explanation: "Si desea ponerse en contacto con nosotros, por favor, rellene el siguiente formulario",
      contact_us_form_message: "Tu mensaje",
      contact_us_form_name: "Tu nombre",
      privacy_policy: {
        header: "Política de privacidad",
        explanation:
          '<h1><span style="color: #33cccc;">POL&Iacute;TICA DE&nbsp;PRIVACIDAD&nbsp;</span></h1>' +
          "<p>De conformidad con lo establecido en el Art&iacute;culo 13 del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos y por el que se deroga la Directiva 95/46/CE (Reglamento General de Protecci&oacute;n de Datos&nbsp;o RGPD),&nbsp;y en el art&iacute;culo 11 de la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales, en adelante LOPD-GDD,&nbsp;se facilita a los usuarios la siguiente informaci&oacute;n:&nbsp;</p>" +
          "<h2>&nbsp;</h2>" +
          '<h2><span style="color: #33cccc;">1. &iquest;Qui&eacute;n es el&nbsp;Responsable&nbsp;del tratamiento de sus datos?&nbsp;</span></h2>' +
          "<p>&nbsp;</p>" +
          '<table border="1" width="566">' +
          "<tbody>" +
          "<tr>" +
          '<td width="198">' +
          "<p><strong>TITULAR:</strong>&nbsp;</p>" +
          "</td>" +
          '<td width="368">' +
          "<p>Accexible Impacto, S.L.&nbsp;</p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td width="198">' +
          "<p><strong>CIF:</strong>&nbsp;</p>" +
          "</td>" +
          '<td width="368">' +
          "<p>B95886198&nbsp;</p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td width="198">' +
          "<p><strong>DOMICILIO SOCIAL</strong>&nbsp;</p>" +
          "</td>" +
          '<td width="368">' +
          "<p>C/Godorniz 44, 48002, Bilbao</p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td width="198">' +
          "<p><strong>CORREO ELECTR&Oacute;NICO</strong>&nbsp;</p>" +
          "</td>" +
          '<td width="368">' +
          "<p>support@accexible.com</p>" +
          "</td>" +
          "</tr>" +
          "</tbody>" +
          "</table>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">2. &iquest;Con qu&eacute; finalidad tratamos sus datos personales&nbsp;y&nbsp;cu&aacute;l&nbsp;es la base de legitimaci&oacute;n que lo sustenta?</span>&nbsp;</h2>' +
          "<p>&nbsp;</p>" +
          "<p>En&nbsp;Accexible Impacto, S.L., como Responsables del tratamiento de sus datos,&nbsp;recogemos y tratamos&nbsp;los datos de&nbsp;car&aacute;cter&nbsp;personal&nbsp;que nos facilita con las&nbsp;siguientes finalidades en funci&oacute;n de la relaci&oacute;n que mantengamos con usted:&nbsp;&nbsp;</p>" +
          "<p>&nbsp;</p>" +
          '<table border="1" width="568">' +
          "<tbody>" +
          "<tr>" +
          '<td colspan="3" width="568">' +
          '<p style="text-align: center;"><strong>CLIENTES y POTENCIALES CLIENTES</strong></p>' +
          "</td>" +
          "</tr>" +
          '<tr style="text-align: center;">' +
          '<td width="246">' +
          "<p><strong>FINALIDAD</strong></p>" +
          "</td>" +
          '<td width="141">' +
          "<p><strong>BASE DE LEGITIMACI&Oacute;N</strong></p>" +
          "</td>" +
          '<td width="181">' +
          "<p><strong>CATEGOR&Iacute;AS DE DATOS TRATADOS</strong></p>" +
          "</td>" +
          "</tr>" +
          '<tr style="text-align: center;">' +
          '<td width="246">' +
          "<p>&nbsp;</p>" +
          "<p>Prestaci&oacute;n de servicios: acceso a los servicios del portal de Accexible para usuarios registrados</p>" +
          "<p>Apoyo al diagn&oacute;stico m&eacute;dico</p>" +
          "</td>" +
          '<td width="141">' +
          "<p>&nbsp;</p>" +
          "<p>Cumplimiento relaci&oacute;n contractual</p>" +
          "<p>&nbsp;</p>" +
          "</td>" +
          '<td width="181">' +
          "<p>Identificativos: nombre y apellidos</p>" +
          "<p>&nbsp;</p>" +
          "<p>Contacto: tel&eacute;fono, correo electr&oacute;nico</p>" +
          "</td>" +
          "</tr>" +
          '<tr style="text-align: center;">' +
          '<td width="246">' +
          "<p>Gesti&oacute;n administrativa de los servicios sanitarios prestados</p>" +
          "</td>" +
          '<td width="141">' +
          "<p>Cumplimiento relaci&oacute;n contractual</p>" +
          "</td>" +
          '<td width="181">' +
          "<p>Identificativos: nombre y apellidos</p>" +
          "<p>&nbsp;</p>" +
          "<p>Contacto: tel&eacute;fono, correo electr&oacute;nico</p>" +
          "<p>&nbsp;</p>" +
          "<p>Datos econ&oacute;micos</p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="246">' +
          "<p>Desarrollo de demostraci&oacute;n del servicio de la plataforma</p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p>Relaci&oacute;n precontractual</p>" +
          "</td>" +
          '<td width="181">' +
          '<p style="text-align: center;">Identificativos: correo electr&oacute;nico</p>' +
          "</td>" +
          "</tr>" +
          "</tbody>" +
          "</table>" +
          "<p>&nbsp;</p>" +
          "<p>&nbsp;</p>" +
          '<table border="1" width="0">' +
          "<tbody>" +
          "<tr>" +
          '<td style="text-align: center;" colspan="3" width="566">' +
          "<p><strong>CONTACTO</strong></p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="245">' +
          "<p><strong>FINALIDAD</strong></p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p><strong>BASE DE LEGITIMACI&Oacute;N</strong></p>" +
          "</td>" +
          '<td style="text-align: center;" width="179">' +
          "<p><strong>CATEGOR&Iacute;AS DE DATOS TRATADOS</strong></p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="245">' +
          "<p>Gesti&oacute;n e informaci&oacute;n de los servicios prestados por Accexible</p>" +
          "<p>&nbsp;</p>" +
          "<p>Respuesta de dudas y consultas sobre los servicios de Accexible</p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p>Consentimiento del interesado</p>" +
          "</td>" +
          '<td style="text-align: center;" width="179">' +
          "<p>Identificativos: nombre y apellidos</p>" +
          "<p>&nbsp;</p>" +
          "<p>Contacto: tel&eacute;fono y correo electr&oacute;nico</p>" +
          "</td>" +
          "</tr>" +
          "</tbody>" +
          "</table>" +
          "<p>&nbsp;</p>" +
          "<p>&nbsp;</p>" +
          '<table border="1" width="0">' +
          "<tbody>" +
          "<tr>" +
          '<td style="text-align: center;" colspan="3" width="566">' +
          "<p><strong>EMPLEO</strong></p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="245">' +
          "<p><strong>FINALIDAD</strong></p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p><strong>BASE DE LEGITIMACI&Oacute;N</strong></p>" +
          "</td>" +
          '<td style="text-align: center;" width="179">' +
          "<p><strong>CATEGOR&Iacute;AS DE DATOS TRATADOS</strong></p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="245">' +
          "<p>Gesti&oacute;n de candidatos mediante el env&iacute;o del CV con la finalidad de valorar una posible contrataci&oacute;n</p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p>&nbsp;</p>" +
          "<p>Consentimiento inequ&iacute;voco</p>" +
          "</td>" +
          '<td style="text-align: center;" width="179">' +
          "<p>Todos los que se incluyan en el CV (identificativos y de contacto, acad&eacute;micos y de empleo, etc.)</p>" +
          "</td>" +
          "</tr>" +
          "</tbody>" +
          "</table>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Datos tratados en calidad de Encargado del Tratamiento</span>&nbsp;&nbsp;</h2>' +
          "<p>&nbsp;</p>" +
          "<p>Adem&aacute;s, es posible que, si ha recibido un enlace para la realizaci&oacute;n de una prueba de biomarcadores vocales de Accexible tras la consulta con tu m&eacute;dico, tratemos sus datos como encargados del tratamiento, puesto que recogemos y tratamos&nbsp;los datos de&nbsp;car&aacute;cter&nbsp;personal&nbsp;que nos facilita en marco de la prestaci&oacute;n de un servicio de apoyo al diagn&oacute;stico de su especialista. Trataremos su voz y los resultados obtenidos a trav&eacute;s de la prueba en base a la ejecuci&oacute;n de un contrato de prestaci&oacute;n de servicios con su m&eacute;dico, que es el responsable del tratamiento de los datos. Si desea obtener m&aacute;s informaci&oacute;n sobre el alcance de este tratamiento, le recomendamos que consulte la Pol&iacute;tica de Privacidad del Responsable.</p>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">4. &iquest;C&oacute;mo hemos obtenido sus datos y por cuanto tiempo conservamos sus datos personales?</span>&nbsp;</h2>' +
          "<p>&nbsp;</p>" +
          "<p>Los datos personales han sido proporcionados por usted a trav&eacute;s de los formularios&nbsp;cumplimentados en&nbsp;la p&aacute;gina web y los obtenidos en el desarrollo de la prestaci&oacute;n de servicios,&nbsp;y&nbsp;se conservar&aacute;n durante&nbsp;el tiempo necesario para alcanzar las finalidades para las que fueron recogidos, y durante los plazos legalmente aplicables.</p>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">5. &iquest;A qu&eacute;&nbsp;destinatarios se comunicar&aacute;n sus datos&nbsp;personales?&nbsp;</span></h2>' +
          "<p>&nbsp;</p>" +
          "<p>En concreto&nbsp;los datos&nbsp;de nuestros usuarios pueden ser comunicados a los siguientes destinatarios:&nbsp;</p>" +
          "<ul>" +
          "<li>En virtud de lo&nbsp;establecido&nbsp;en la Ley, como es el caso de las Fuerzas y Cuerpos de Seguridad.&nbsp;</li>" +
          "<li>A las administraciones p&uacute;blicas a las que&nbsp;Accexible Impacto, S.L.&nbsp;tenga que facilitar informaci&oacute;n con motivo del cumplimiento de obligaciones legales.&nbsp;</li>" +
          "</ul>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">6. &iquest;Cu&aacute;les son sus derechos cuando nos facilita sus datos personales?</span>&nbsp;</h2>' +
          "<p>&nbsp;</p>" +
          "<p>Le informamos&nbsp;que como&nbsp;interesado&nbsp;tiene derecho a acceder a sus datos personales, as&iacute; como a solicitar la rectificaci&oacute;n de los datos inexactos o, en su caso, solicitar su supresi&oacute;n cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.&nbsp;</p>" +
          "<p>En determinadas circunstancias, los interesados podr&aacute;n solicitar la limitaci&oacute;n del tratamiento de sus datos, en cuyo caso &uacute;nicamente los conservaremos para el ejercicio o la defensa de reclamaciones.&nbsp;</p>" +
          "<p>En determinadas circunstancias y por motivos relacionados con su situaci&oacute;n particular, los interesados podr&aacute;n oponerse al tratamiento de sus datos.&nbsp;Accexible Impacto, S.L.&nbsp;dejar&aacute; de tratar los datos, salvo por motivos leg&iacute;timos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.&nbsp;</p>" +
          "<p>En determinadas circunstancias, el interesado tendr&aacute; derecho a recibir los datos personales que le incumban, que nos haya facilitado, en un formato estructurado, de uso com&uacute;n y lectura mec&aacute;nica, y a transmitirlos a otro responsable del tratamiento.&nbsp;</p>" +
          "<p>Las personas interesadas tendr&aacute;n derecho a oponerse, por motivos relacionados con su situaci&oacute;n particular y sin que existan motivos leg&iacute;timos que prevalezcan sobre los interesas, derechos y libertades del interesado, a que los datos personales que le conciernan sean objeto de tratamiento basado en el cumplimiento de una misi&oacute;n realizada en inter&eacute;s p&uacute;blico o en el ejercicio de poderes p&uacute;blicos o para la satisfacci&oacute;n de intereses leg&iacute;timos de&nbsp;Accexible Impacto, S.L.,&nbsp;incluida la elaboraci&oacute;n de perfiles.&nbsp;</p>" +
          '<p>Usted puede ejercer sus derechos por escrito adjuntando una copia de su DNI o documentaci&oacute;n que el identifique en la siguiente direcci&oacute;n:&nbsp;Plaza de Pau Vila, 1 Bloque A, 3 3 1, 08003 Barcelona &ndash; Barcelona, Espa&ntilde;a, o por correo electr&oacute;nico a&nbsp;<a href="mailto:support@accexible.com">support@accexible.com</a>.</p>' +
          "<p>Asimismo, le informamos que,&nbsp;cuando no haya obtenido satisfacci&oacute;n en el ejercicio de sus derechos o la forma de ejercerlos podr&aacute; presentar una reclamaci&oacute;n ante la Autoridad de Control. Si quiere conocer m&aacute;s informaci&oacute;n sobre este derecho y cómo ejercerlo puede dirigirse a la AEPD:&nbsp;</p>" +
          '<p style="text-align: center;"><a href="http://www.aepd.es/">http://www.aepd.es</a>.</p>' +
          '<p style="text-align: center;">&nbsp;Tel. 901 100 099 y 912 663 517</p>' +
          '<p style="text-align: center;">C/ Jorge Juan, 6, 28001 Madrid.</p>',
      },
    },
    errors: {
      patient_exists: "Ya existe un usuario con el correo electrónico introducido. No se puede crear un nuevo usuario.",
      generic_error: "Ha habido un error. Por favor, inténtelo de nuevo más tarde o contacte con un administrador si el problema persiste.",
      permission_error:
        "Para un correcto funcionamiento de la aplicación web, el usuario debe dar permisos de acceso al micrófono del dispositivo. Por favor, elimine los datos de navegación del navegador en las opciones y vuelva a recargar la página pulsando sobre el botón. Deberá aparecer un mensaje para otorgar permisos de acceso al micrófono.",
    },
  },
  home: {
    home_header: {
      name: "accexible",
      video_overlay_text: "Detección de enfermedades a través del análisis del habla",
      schedule_demo: "Programar demo",
      schedule_demo_explanation:
        "Por favor, selecciona día y hora para que te hagamos la demostración del funcionamiento de la plataforma de accexible. Una vez que reserves hora, nos pondremos en contacto contigo con los datos sobre la sesión de demo virtual.",
      demo_email_address: "Correo electrónico",
      demo_date: "Fecha",
      demo_hour: "Hora",
      email_format_error: "El campo 'Correo electrónico' no tiene el formato correcto.",
    },
    prevention: {
      header: "Medicina preventiva",
      explanation:
        "Accexible identifica biomarcadores vocales para la detección y seguimiento de enfermedades.  El sistema de alerta de accexible permite un diagnóstico precoz.  Actualmente está desarrollando modelos relacionados con la salud mental (depresión, ansiedad) y con el deterioro cognitivo (alzheimer, parkinson, ictus y COVID persistente).",
      panel_1: "Dispositivo con conexión a Internet",
      panel_2: "Modelos de análisis del habla de accexible",
      panel_3: "Resultados personalizados en 1-2 minutos médico y paciente",
    },
    application: {
      header: "Demencia y deterioro cognitivo leve",
      explanation:
        "El modelo de detección de deterioro cognitivo leve y demencia en etapas tempranas de Accexible tiene una precisión del 93%. Se emplea como apoyo al especialista, es un sistema de alertas.",
      hover_to_find_out_more: "Desliza el cursor para conocer más",
      cards: {
        accurate_effective: {
          header: "Precisa y efectiva",
          explanation: "Reduce el error en el diagnóstico y el coste de detección y seguimiento de la demencia",
        },
        cost_effective: {
          header: "Coste efectiva",
          explanation: "Reduce el tiempo y el coste de la detección y el seguimiento de la demencia",
        },
        quality_life: {
          header: "Calidad de vida",
          explanation: "Mejora de calidad de vida de las personas que sufren demencia y sus seres cercanos",
        },
        personalised: {
          header: "Personalizada",
          explanation: "Seguimiento personalizado de la evolución de las funciones cognitivas",
        },
        accurate: {
          header: "Precisa",
          explanation: "Permite reducir el error y apoyar el diagnóstico del/la médico que se centra en el paciente",
        },
        evolution: {
          header: "Evolución",
          explanation: "Seguimiento y sistema de alertas de la enfermedad",
        },
        digital: {
          header: "Digital",
          explanation: "Objetiva, fácilmente escalable y aplicación en remoto",
        },
      },
    },
    focus: {
      header: "Ecosistema de salud y Accexible",
      teleservice: {
        name: "Teleasistencia",
        description:
          "La plataforma de accexible permite la detección y seguimiento de enfermedades en remoto. Y establece un sistema de alertas que se complementa con otros servicios de teleasistencia.",
      },
      primary_health_care: {
        name: "Atención Primaria",
        description:
          "Accexible se emplea como herramienta de cribado y en pocos minutos se realizan las pruebas de detección de deterioro cognitivo leve. Las pruebas se pueden realizar tanto en la sala de espera como en consulta, tanto por personal de enfermería como médicos.",
      },
      clinical_trials: {
        name: "Ensayos clínicos",
        description:
          "La plataforma de accexible es una solución objetiva que ofrece un criterio de cribado de participantes en ensayos clínicos. El fácil uso de la plataforma facilita el seguimiento de los participantes a lo largo de los ensayos clínicos.",
      },
      medical_insurances: {
        name: "Seguros médicos",
        description:
          "Las herramientas de accexible permiten con una simple llamada de teléfono una detección precoz de enfermedades relacionadas con el deterioro cognitivo. La detección temprana permite planificar el tratamiento adecuado de forma eficiente.",
      },
      day_centres: {
        name: "Centros de día",
        description:
          "El uso de accexible en los centros de día permite una detección precoz del deterioro cognitivo y realizar el seguimiento de las funciones cognitivas de las personas tanto a nivel remoto como presencialmente. Permite también la realización de las pruebas sin la presencia continua de un/a médico o especialista.",
      },
      cognitive_stimulation_apps: {
        name: "Aplicaciones de estimulación cognitiva",
        description:
          "Accexible puede ser el tándem perfecto para aplicaciones dedicadas a la mejora del rendimiento cognitivo. Por un lado, permite a estas aplicaciones realizar una detección temprana del deterioro cognitivo y ampliar su número de usuarios, y por otro, permite realizar el seguimiento sobre la evolución de las funciones cognitivas de sus usuarios.",
      },
      clinicians: {
        name: "",
        description: "",
      },
    },
    about_us: {
      header: "Sobre nosotros",
      explanation_1:
        "Somos un equipo multidisciplinar. Hemos creado Accexible con el fin de desarrollar una plataforma que permita acceder a una detección y diagnóstico de enfermedades relacionadas con el deterioro cognitivo al mayor número de personas posible.",
      explanation_2:
        "El objetivo es llevar al mercado modelos basados en biomarcadores vocales. Modelos eficientes y fácilmente escalables para que el mayor número de personas tenga acceso a un sistema preventivo de alertas y que se traduzca en una mejora de calidad de las personas.",
      click_to_find_out_more: "Haz click para conocer más",
      team: {
        header: "Equipo",
        team_members: {
          carla_zaldua_aguirre: {
            name: "Carla Zaldua Aguirre",
            position: "CEO / Cofundadora",
            description:
              "Carla tiene una amplia experiencia en llevar proyectos de i+d al mercado. Todos estos proyectos comparten que tienen como objeto el uso de la tecnología para tener un impacto positivo en la sociedad. Ha sido nombrada mujer emprendedora del año en el 2020 por el ranking de elreferente. Tiene un MSc de la London School of Economics en Comunicación y Sistemas de Información y estudios de grado en Ciencias Políticas y Sociología.",
          },
          javier_zaldua_aguirre: {
            name: "Javier Zaldua Aguirre",
            position: "CFO / Cofundador",
            description:
              "Antes de fundar Accexible Javi trabajó en Banca, en finanzas y en la construcción de redes comerciales. Tras 20 años en el mundo financiero, decidió fundar accexible con el fin de aplicar la inteligencia artificial como herramienta para un envejecimiento activo y saludable. Estudió ciencias empresariales en la Universidad de Deusto y tiene un posgrado en liderazgo por el IE.",
          },
          tomas_rojo: {
            name: "Tomás Rojo",
            position: "CDO / CTO",
            description: "",
          },
          victoria_de_la_orden: {
            name: "Victoria de la Orden",
            position: "Business Development Manager",
            description: "",
          },
          ania_zuazua: {
            name: "Ania Zuazua",
            position: "Technical Manager",
            description: "",
          },
          xabier_hernando: {
            name: "Xabi Hernando",
            position: "Head of Front-end development",
            description: "",
          },
          peru_gabirondo: {
            name: "Peru Gabirondo",
            position: "Lead Data Scientist",
            description:
              "Graduado en Matemáticas, Peru está realizando el Master in Statistics for Data Science en la Universidad Carlos III de Madrid, donde se está formado en modelos estadísticos para el análisis de datos. Asimismo, cuenta con experiencia en métodos predictivos relacionados con Time Series Analysis y Machine Learning.",
          },
          patricia_dias: {
            name: "Patricia Dias",
            position: "CMO",
            description: "",
          },
          iker_honorato: {
            name: "Iker Honorato",
            position: "Data Scientist",
            description: "",
          },
          dario_gallego: {
            name: "Darío Gallego",
            position: "Data Scientist",
            description: "",
          },
        },
      },
      advisors: {
        header: "Asesores",
        advisors_members: {
          javier_jimenez: {
            name: "Javier Jiménez",
            position: "IA / Socio",
            description:
              "Javier es graduado en Física y posee un Máster en Inteligencia Artificial y Deep Learning. Tiene más de 3 años de experiencia trabajando como científico de datos en consultoría y lleva casi 2 años colaborando con acceXible. Es especialista en análisis de datos y Machine Learning.",
          },
          pablo_delaguardia: {
            name: "Pablo de la Guardia",
            position: "Socio / Matemático",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et imperdiet urna, in rutrum leo. Donec lacus urna, posuere in tellus nec, consectetur fermentum turpis. Proin fringilla volutpat augue vel mattis. Nunc at libero iaculis, luctus nisl quis, fermentum neque. Nam faucibus mi quis blandit tincidunt. Aenean eu arcu a tortor egestas auctor ac nec diam. Nulla sed mauris felis. Fusce ac ultricies ex. Praesent finibus vitae velit nec venenatis. Aliquam lorem felis, ornare eget consequat eget, fringilla a tortor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et imperdiet urna, in rutrum leo. Donec lacus urna, posuere in tellus nec, consectetur fermentum turpis. Proin fringilla volutpat augue vel mattis. Nunc at libero iaculis, luctus nisl quis, fermentum neque. Nam faucibus mi quis blandit tincidunt. Aenean eu arcu a tortor egestas auctor ac nec diam. Nulla sed mauris felis. Fusce ac ultricies ex. Praesent finibus vitae velit nec venenatis. Aliquam lorem felis, ornare eget consequat eget, fringilla a tortor.",
          },
          donald_a_davidoff: {
            name: "Donald A. Davidoff",
            position: "Psychology Harvard Medical School and Chief of Neuropsychology in McLean Hospital",
            description:
              "Donald Davidoff, PhD, es jefe del departamento de Neuropsicología del Mc Lean Hospital y profesor adjunto de psicología en el Departamento de Psiquiatría de la Facultad de Medicina de la Universidad de Harvard. Entre sus responsabilidades se incluyen la gestión del personal clínico y administrativo, la asistencia clínica y la formación de estudiantes postdoctorales y de médicos residentes en psiquiatría. Fundó la Geriatric Neuropsychiatry Unit y durante 16 años ocupó el puesto de psicólogo a cargo.",
          },
          pedro_montejo: {
            name: "Pedro Montejo",
            position: "Doctor en Medicina especialista en psiquiatría",
            description:
              "El Dr. Pedro Montejo Carrasco es doctor en Medicina, especialista en Psiquiatría.\nEs jefe del Centro de Prevención del Deterioro Cognitivo especializado en la detección precoz y la estimulación cognitiva en el deterioro cognitivo, centro fundado en el año 2006. Desempeña su labor profesional en el campo de la Psicogeriatría en Madrid Salud, Ayuntamiento de Madrid. Durante 6 años ha sido jefe de la Sección de Gestión de Programas de salud del Ayuntamiento de Madrid. Destaca su labor en el campo de la evaluación y la estimulación cognitiva. Coautor del Método de Entrenamiento de Memoria UMAM del que se han publicado varios documentos y se han realizado más de 50 cursos de formación para profesionales que trabajan en el ámbito de la psicogeriatría. Continúa colaborando en la realización del material de estimulación del Centro de Prevención. En el momento actual participa en estudios sobre las quejas de memoria en distintas edades y en diversas investigaciones que se realizan desde el Centro de Prevención del deterioro cognitivo en colaboración con Hospitales y otros centros y en diagnóstico precoz del Deterioro Cognitivo. Docente de numerosos cursos de envejecimiento, evaluación y detección del deterioro, así como en máster, principalmente de Psicogerontología y Neuropsicología. Autor  y co-autor de numerosos trabajos en el campo de las quejas de memoria, diagnóstico precoz del Deterioro Cognitivo, Memoria, etc. Revisor de artículos científicos en revistas nacionales e internacionales: SAGE Open Medicine, Anales de Psicología, etc.",
          },
        },
      },
      partnerships: {
        header: "Colaboraciones y apoyo",
        partners: {
          feder: "Programa ICEX Next",
          quiron_salud: "QuirónSalud",
          cdti: "Neotec/CDTI",
          enisa: "Enisa",
          beaz: "BEAZ",
          masschallenge: "Masschallenge",
          aenor: "AENOR - Joven Empresa Innovadora",
          spri: "Grupo SPRI Taldea - Hazitek",
          award: "Healthy Ageing Innovation Award ",
        },
      },
    },
  },
  authentication: {
    sign_in: {
      welcome: "¡Bienvenid@ a la demo de la plataforma de Accexible!",
      create_account_button: "nuevo usuario",
      create_account_message: "¿No tienes un perfil? Crea un",
      email_address: "Correo electrónico",
      enter_email_address: "Introduzca el correo electrónico",
      enter_password: "Introduzca la contraseña",
      invalid_data_error: "El correo electrónico y/o la contraseña introducidos no son correctos. Por favor, inténtelo de nuevo.",
      password: "Contraseña",
    },
    sign_up: {
      accept_terms_and_conditions: "Acepto los términos y condiciones de uso de acceXible",
      email_address_error: "El formato del correo electrónico no es correcto.",
      enter_repeat_password: "Introduzca de nuevo la contraseña",
      password_error: "El formato de la contraseña es erróneo. La contraseña debe tener más de 8 carácteres y contener al menos un número, una mayúscula y un símbolo.",
      password_repeat_error: "Las contraseñas introducidas no coinciden. Por favor, inténtelo de nuevo.",
      repeat_password: "Repetir contraseña",
      terms_and_conditions_error: "Se deben aceptar los términos y condiciones de uso.",
    },
  },
  profile: {
    patient: {
      home_information:
        'A continuación tiene una lista con las baterías de pruebas que ha realizado previamente. Para revisar cualquiera de ellas presione sobre uno de ellos. Si desea eliminar el perfil de una batería de pruebas, presione sobre el botón (-) y elija la que desea que desea eliminar. Si desea comenzar una nueva batería de pruebas presione sobre el botón "EMPEZAR BATERÍA DE PRUEBAS".',
    },
    medical_staff: {
      home_information:
        "A continuación tiene una lista con sus pacientes. Para revisar las baterías de pruebas realizadas por un paciente o realizar una nueva batería de pruebas presione sobre uno de ellos. Si desea crear un nuevo perfil de paciente, presione sobre el botón (+). Si desea eliminar el perfil de un paciente, presione sobre el botón (-) y elija el paciente que desea eliminar.",
    },
    patient_list: {
      new_patient_formulary: {
        information:
          "Para crear el perfil de un nuevo paciente por favor, cumplimente el siguiente formulario. En caso de introducir una dirección de correo electrónico válido, se creará un perfil de usuario y se le notificarán las nuevas credenciales de usuario mediante correo electrónico a la dirección indicada.",
        patient_identifier: "Identificador del paciente",
        patient_email_address: "Correo electrónico del paciente",
        gender: "Género",
        date_of_birth: "Fecha de nacimiento",
        level_of_studies: "Nivel de estudios",
        gender_options: {
          male: "Hombre",
          female: "Mujer",
        },
        level_of_studies_options: {
          no_education: "Sin estudios",
          childhood: "Educación infantil",
          primary: "Educación primaria",
          secondary: "Educación secundaria obligatoria",
          high: "Educación superior",
        },
        buttons: {
          create: "CREAR",
          close: "CERRAR",
        },
      },
      patient_search: {
        placeholder: "ID de paciente",
        patient_search_error: "ID no encontrado. Inténtalo de nuevo, por favor.",
      },
    },
    patient_details: {
      patient_information: "Información del paciente",
      date_of_birth: "Fecha de nacimiento",
      age: "Edad",
      years: "años",
      gender: "Género",
      level_of_studies: "Nivel de estudios",
      test_battery_list: "Baterías realizadas por el paciente",
      buttons: {
        start_test_battery: "EMPEZAR BATERÍA DE PRUEBAS",
      },
    },
    test_battery_details: {
      date: "Fecha de la batería",
      test_battery_completed: "Batería completada",
      test_battery_uploaded: "Batería subida",
      test_battery_result: "Resultado",
      information: "Información",
      file_list: "Lista de archivos",
      graphic_score: "Gráfico",
      show_score: "MOSTRAR",
      test_battery_result_modal: {
        DCL: {
          explanation_1: "Test administrados: Lista de Palabras de la batería CERAD, Fluidez verbal en un minuto (semántica, fonológica y alterna) y descripción de la lámina del test de Boston.",
          explanation_2:
            "Resultados: tras la evaluación se concluye que el rendimiento cognitivo global se encuentra posiblemente alterado (puntuación igual o inferior al punto de corte para el DCL). Se recomienda la realización de una evaluación en profundidad por parte de un profesional y la asignación de las pautas correspondientes. Los audios con las respuestas emitidas en cada una de las pruebas se encuentran disponibles para su análisis en caso necesario.",
        },
        non_DCL: {
          explanation_1: "Test administrados: Lista de Palabras de la batería CERAD, Fluidez verbal en un minuto (semántica, fonológica y alterna) y descripción de la lámina del test de Boston.",
          explanation_2:
            "Resultados: tras la evaluación se concluye que el rendimiento cognitivo global es normal (puntuación superior al punto de corte para el DCL). Se recomienda, con finalidad preventiva, la repetición de la evaluación en aproximadamente 6-12 meses, o antes si se manifiestan síntomas que reflejen un empeoramiento. Los audios con las respuestas emitidas en cada una de las pruebas se encuentran disponibles para su análisis en caso necesario.",
        },
      },
    },
  },
  testBattery: {
    buttons: {
      cancelTestBattery: "Cancelar batería",
      close: "CERRAR",
      end: "SALIR",
      uploadFiles: "Subir archivos",
      finishTestBattery: "FINALIZAR",
      nextTestStep: "SIGUIENTE PRUEBA",
      play: "REPRODUCIR",
      startTestBattery: "EMPEZAR BATERÍA",
      startTestStep: "EMPEZAR PRUEBA",
    },
    testBatteryExplanation: {
      explanation: "Esta batería de pruebas consta de 3 pruebas y no le llevará más de 5 minutos:",
      explanation_1:
        "- <strong>Fluidez verbal semántica.</strong> Diga todas las palabras que se le ocurran de una misma categoría.<br>" +
        "- <strong>Descripción de la imagen.</strong> Describa una imagen que se le mostrará en pantalla.<br>" +
        "- <strong>Pregunta abierta.</strong> Conteste con detalle a una pregunta abierta.",
      header: "Batería de pruebas",
    },
    testStepFinish: {
      next_step_information: 'Por favor, pulse el botón "Siguiente prueba" para continuar.',
      participation_thank: "Muchas gracias por su participación.",
      step_finalised: "PRUEBA FINALIZADA",
      test_finalise_information: 'Por favor, pulse el botón "Finalizar" para continuar.',
    },
    testStep: {
      ASVF: {
        explanation_1: "A continuación, le diré el nombre de dos categorías o conjunto de cosas.",
        explanation_2:
          'Tendrá 1 minuto para decir de forma alterna todas las palabras diferentes que se le ocurran que pertenezcan a esas dos categorías. Por ejemplo, si las categorías son “árboles" y "medios de transporte”, usted tiene que decir un árbol y luego un medio de transporte, de esta manera: pino, tren, manzano, autobús, ciprés, bicicleta, etc.',
        explanation_3: "Sus categorías son ",
        explanation_4: "DEPORTES",
        explanation_5: " y ",
        explanation_6: "FRUTAS.",
        explanation_7: " Díganos todas las palabras que pueda alternando siempre un deporte con una fruta.",
        name: "Fluidez verbal semántica alterna",
      },
      CERAD_Codify: {
        explanation_1: "A continuación se van a mostrar unas palabras, léalas en voz alta a medida que van apareciendo.",
        explanation_2: "Después de mostrarse las palabras se oirá un sonido, tras el cual deberá de repetir todas las palabras que recuerde.",
        explanation_3: "Para finalizar presione el botón rojo.",
        interstep_explanation: "A continuación se le mostrará la lista de palabras. Léalas en voz alta a medida que van apareciendo. Pulse en el botón para comenzar.",
        name: "Lista de palabras del CERAD - Codificar",
        word_lists: [
          ["mantequilla", "brazo", "playa", "carta", "reina", "cabaña", "poste", "billete", "hierba", "motor"],
          ["billete", "cabaña", "mantequilla", "playa", "motor", "brazo", "reina", "carta", "poste", "hierba"],
          ["reina", "hierba", "brazo", "cabaña", "poste", "playa", "mantequilla", "motor", "billete", "carta"],
        ],
      },
      CERAD_Recognise: {
        name: "Lista de palabras del CERAD - Reconocer",
        explanation_1: "A continuación le mostraremos una serie de palabras. Algunas de ellas son de la lista que se ha mostrado anteriormente, otras no.",
        explanation_2: "Indique cuales han aparecido antes y cuáles no. Únicamente diga claro “SÍ” o “NO”.",
        explanation_3: "Para ver la siguiente palabra clique sobre el botón rojo.",
        word_list: [
          "iglesia",
          "café",
          "mantequilla",
          "dólar",
          "brazo",
          "playa",
          "cinco",
          "carta",
          "hotel",
          "montaña",
          "reina",
          "cabaña",
          "zapatillas",
          "poste",
          "valle",
          "cinta",
          "billete",
          "ejército",
          "hierba",
          "motor",
        ],
      },
      CERAD_Remember: {
        explanation_1: "Hace unos minutos se le han mostrado una lista de palabras. Por favor, diga todas las palabras de la lista que recuerde.",
        explanation_2: "Presione en empezar prueba y tras el sonido comience a enumerarlas.",
        explanation_3: "Para finalizar presione el botón rojo.",
        name: "Lista de palabras del CERAD - Recordar",
      },
      Conversation: {
        explanation: "A continuación cuente en un minuto una experiencia positiva o agradable que haya tenido en el último mes.",
        explanation_1: "La prueba se detendrá automáticamente tras 60 segundos. Si quiere finalizarla antes, puede pulsar el botón rojo.",
        name: "Experiencia personal",
      },
      /* Question1: {
        explanation: " ¿Estado civil?",
        name: "Estado civil",
      },
      Question2: {
        explanation: "¿Vive usted solo?",
        name: "Vive usted solo",
      },
      Question3: {
        explanation: "¿Es usted bilingüe?",
        name: "Bilingüe",
      }, */
      Question1: {
        explanation: "¿A que día de la semana estamos?",
        commonExplanation1: "A continuación le realizaremos varias preguntas",
        commonExplanation2: "Presione en empezar prueba y tras el sonido conteste",
        commonExplanation3: "Para finalizar presione el botón rojo",
        commonExplanation4: "La pregunta es:",
        name: "Pregunta 1",
      },
      Question2: {
        explanation: "¿En qué mes estamos?",
        commonExplanation2: "Presione en empezar prueba y tras el sonido conteste",
        commonExplanation3: "Para finalizar presione el botón rojo",
        commonExplanation4: "La pregunta es:",
        name: "Pregunta 2",
      },
      Question3: {
        explanation: "¿En qué año estamos?",
        commonExplanation2: "Presione en empezar prueba y tras el sonido conteste",
        commonExplanation3: "Para finalizar presione el botón rojo",
        commonExplanation4: "La pregunta es:",
        name: "Pregunta 3",
      },
      Question4: {
        explanation: "¿Qué fecha es hoy?",
        commonExplanation2: "Presione en empezar prueba y tras el sonido conteste",
        commonExplanation3: "Para finalizar presione el botón rojo",
        commonExplanation4: "La pregunta es:",
        name: "Pregunta 4",
      },
      Question5: {
        explanation: "¿Aproximadamente qué hora es?",
        commonExplanation2: "Presione en empezar prueba y tras el sonido conteste",
        commonExplanation3: "Para finalizar presione el botón rojo",
        commonExplanation4: "La pregunta es:",
        name: "Pregunta 5",
      },
      Image_Description: {
        explanation: "A continuación se le mostrará una imagen. Diga todo lo que vea que está pasando en esa imagen.",
        explanation_1: "La prueba se detendrá automáticamente tras 60 segundos. Si quiere finalizarla antes, puede pulsar el botón rojo.",
        name: "Descripción de imagen",
      },
      PVF: {
        explanation_1: "A continuación, le diré una letra.",
        explanation_2:
          "Tendrá 1 minuto para decir todas las palabras diferentes que se le ocurran que comiencen con esa letra. No debe decir nombres propios de personas o lugares, ni palabras con la misma raíz, por ejemplo camino y caminante.",
        explanation_3: "La letra es: ",
        explanation_4: "F",
        name: "Fluidez verbal fonológica",
      },
      SVF: {
        explanation:
          "A continuación aparecerá el nombre de una categoría o conjunto de cosas." +
          "<br><br>" +
          "Tendrá 1 minuto para decir todas las palabras diferentes que se le ocurran que pertenezcan a esa categoría. Por ejemplo, si la categoría es “flores”, usted podría decir rosa, tulipán, margarita, etc." +
          "<br><br>" +
          "La categoría es <strong>ANIMALES</strong>.",
        explanation_1: "La prueba se detendrá automáticamente tras 60 segundos.",
        name: "Fluidez verbal semántica",
      },
      Watch_Picture: {
        explanation_1: "En esta prueba debe dibujar un reloj redondo y grande con todos los números y las manecillas marcando las once y diez.",
        explanation_2: "Antes de comenzar a dibujar el reloj puede probar el pencil.",
        name: "Test del dibujo del reloj",
      },
    },
    results: {
      explanation:
        "La batería de pruebas ha finalizado correctamente. A continuación se presenta la lista de archivos que se han generado durante la bateria. Para obtener el resultado, por favor, envíe los archivos a nuestro servicio." +
        "<br><br>" +
        "<strong>IMPORTANTE</strong>: espere a que se suban todos los archivos, de lo contrario, estos se perderán y no se podrán recuperar. Una vez que se suban los archivos pulse el botón salir.",
      header: "Resultados de la batería",
      results_table: {
        header: {
          file_name: "Archivo",
          upload_progress: "Progreso de subida",
        },
        body: {
          test_1: "Prueba 1",
          test_2: "Prueba 2",
          test_3: "Prueba 3",
          test_4: "Prueba 4",
          test_5: "Prueba 5",
          test_6: "Prueba 6",
          test_7: "Prueba 7",
          test_8: "Prueba 8",
          test_9: "Prueba 9",
          test_10: "Prueba 10",
          test_11: "Prueba 11",
          test_12: "Prueba 12",
          test_13: "Prueba 13",
          test_14: "Prueba 14",
          test_15: "Prueba 15",
          test_16: "Prueba 16",
          test_17: "Prueba 17",
          test_18: "Prueba 18",
          test_19: "Prueba 19",
          test_20: "Prueba 20",
          unique: "Único",
          upload_error: "Error al subir el archivo",
        },
      },
    },
  },
};

export default es;
