// Import libraries
import React from "react";
import PropTypes from "prop-types";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import { Button } from "react-bootstrap";
import { FaStopCircle } from "react-icons/fa";

// Import widgets
import SoundVisualizer from "../../../../widgets/sound_visualizer/SoundVisualizer";

// Import CSS
import "../../../../../styles/components/private/test_battery/test_steps/steps/Conversation_03.css";

class Conversation03 extends React.Component {
  constructor() {
    super();
    this.state = {
      recording: false,
      button_disabled: true,
      sound_values: [],
    };
    this.function_start_recording = this.function_start_recording.bind(this);
    this.function_stop_recording = this.function_stop_recording.bind(this);
  }

  componentDidMount() {
    this.function_empty_audio_array();
    this.function_start_recording();
  }

  componentWillUnmount() {
    this.setState({ recording: false, button_disabled: true });

    if (this.audio_analyser_interval) {
      clearInterval(this.audio_analyser_interval);
    }

    if (this.test_start_timer) {
      clearTimeout(this.test_start_timer);
      this.test_start_timer = 0;
    }

    if (this.finish_subtest_timer) {
      clearTimeout(this.finish_subtest_timer);
      this.finish_subtest_timer = 0;
    }

    if (this.recording_button_disabled) {
      clearTimeout(this.recording_button_disabled);
      this.recording_button_disabled = 0;
    }
  }

  function_empty_audio_array = () => {
    let sound_values_array = [];

    for (let i = 0; i < 50; i++) {
      sound_values_array.push(0);
    }

    this.setState({ sound_values: sound_values_array });
  };

  function_start_recording = () => {
    // Assigns the audio file name
    let audio_name = `${this.props.test_battery_user_ID}-PersonalExperience-Unico-${this.props.test_battery_ID}-${new Date().getTime()}_0.wav`;

    // Updates the object with the information of the test battery
    this.props.update_test_battery("Conversation_Filename", audio_name);

    // Plays the beep that indicates the initialization of the audio recording
    let beep = new Audio(process.env.REACT_APP_BEEP_SOUND);
    beep.play();

    this.test_start_timer = setTimeout(() => {
      clearTimeout(this.test_start_timer);
      this.test_start_timer = 0;

      // Start recording of the audio
      navigator.mediaDevices
        .getUserMedia({ audio: { sampleSize: 16, channelCount: 1, sampleRate: 44100, echoCancellation: true }, video: false })
        .then((stream) => {
          // Sets the audio recorder
          this.audioRecorder = RecordRTC(stream, { type: "audio", mimeType: "audio/wav", recorderType: StereoAudioRecorder, numberOfAudioChannels: 1, bitrate: 320000 });

          // Sets the audio context to get the db from the audio recording
          this.audio_context = new window.AudioContext();
          this.audio_analyser = this.audio_context.createAnalyser();
          this.source = this.audio_context.createMediaStreamSource(stream);
          this.audio_analyser.smoothingTimeConstant = 0.8;
          this.audio_analyser.fftSize = 1024;

          this.source.connect(this.audio_analyser);

          // Starts recording the audio file
          this.audioRecorder.startRecording();

          this.setState({ recording: true });

          this.audio_analyser_interval = setInterval(() => {
            let audio_array = new Uint8Array(this.audio_analyser.frequencyBinCount);
            this.audio_analyser.getByteFrequencyData(audio_array);
            let values = 0;

            for (let i = 0; i < audio_array.length; i++) {
              values += audio_array[i];
            }

            let average = values / audio_array.length;

            if (average <= 1) {
              average = 1;
            }

            let volume_array = this.state.sound_values;
            let updated_volume_array = volume_array.slice(1);
            updated_volume_array.push(average);

            this.setState({ sound_values: updated_volume_array });
          }, 100);

          // Sets the timer to finish the test step
          this.test_step_timer = setTimeout(() => {
            this.test_step_timer = 0;

            if (this.audio_analyser_interval) {
              clearInterval(this.audio_analyser_interval);
            }

            this.function_empty_audio_array();

            this.function_stop_recording();
          }, 60000);

          this.recording_button_disabled = setTimeout(() => {
            clearTimeout(this.recording_button_disabled);
            this.recording_button_disabled = 0;

            this.setState({ button_disabled: false });
          }, 1000);
        })
        .catch(console.error);
    }, 500);
  };

  function_stop_recording = () => {
    // Save audio file
    this.audioRecorder.stopRecording(() => {
      let blob = this.audioRecorder.getBlob();
      const dataUrl = URL.createObjectURL(blob);
      this.props.update_test_battery_blob("Conversation_url", dataUrl);

      this.finish_subtest_timer = setTimeout(() => {
        if (this.audio_analyser_interval) {
          clearInterval(this.audio_analyser_interval);
        }

        this.function_empty_audio_array();

        this.props.finish_test_step();
      }, 10);
    });
  };

  render() {
    return this.state.recording ? (
      <div id="test_battery_test_step_03_conversation_container" className="col-12">
        <SoundVisualizer height={50} sound_values={this.state.sound_values} />
        <div id="test_battery_test_step_03_conversation_button_container" className="row justify-content-center align-content-center">
          <Button
            disabled={this.state.button_disabled}
            id={this.state.button_disabled ? "test_battery_test_step_03_conversation_stop_button_interstitial" : "test_battery_test_step_03_conversation_stop_button"}
            onClick={this.function_stop_recording}
          >
            <FaStopCircle />
          </Button>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
}

Conversation03.propTypes = {
  test_battery_ID: PropTypes.string.isRequired,
  test_battery_user_ID: PropTypes.string.isRequired,
  finish_test_step: PropTypes.func.isRequired,
  update_test_battery: PropTypes.func.isRequired,
  update_test_battery_blob: PropTypes.func.isRequired,
};

export default Conversation03;
