// Import libraries
import React from "react";
import PropTypes from "prop-types";

// Import high order components
import { withLanguage } from "../../../../../contexts/language/index";

// Import components
import TestStepHeader from "../shared/TestStepHeader";
import TestStepExplanation from "../shared/TestStepExplanation";
import TestStepButton from "../shared/TestStepButton";

// Import CSS
import "../../../../../styles/components/private/test_battery/test_steps/common/TestStepExplanationView.css";

class TestStepExplanationView extends React.Component {
  render() {
    return (
      <div id="test_battery_step_explanation_container" className="col-12">
        <TestStepHeader test_step={this.props.test_step} />
        <TestStepExplanation test_step={this.props.test_step} />
        <TestStepButton onClick_action={this.props.start_test_step} button_text={this.props.language.retrieve_string("testBattery.buttons.startTestStep")} />
      </div>
    );
  }
};

TestStepExplanationView.propTypes = {
  test_step: PropTypes.number.isRequired,
  start_test_step: PropTypes.func.isRequired,
};

export default withLanguage(TestStepExplanationView);
