// Import libraries
import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { FaMinusCircle } from "react-icons/fa";

// Import Firebase
import app from "firebase/app";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

// Import authentication context
import AuthUserContext from "../../../../../contexts/session/index";

// Import CSS
import "../../../../../styles/components/private/profile/navigation_components/patient_version/TestBatteryList.css";

class TestBatteryList extends React.Component {
  _isMounted = true;
  constructor() {
    super();
    this.state = {
      // List of the logged user's test battery profiles
      user_test_battery_profiles: [],
      // Sets if the option to delete the test battery profiles is active
      delete_test_battery_profile_active: false,
      // Sets the identifier of the test battery that the user wants to delete for confirmation
      test_battery_profile_delete_id: "",
    };
    this.function_toggle_option_delete_test_battery_profiles = this.function_toggle_option_delete_test_battery_profiles.bind(this);
    this.function_delete_test_battery_profile = this.function_delete_test_battery_profile.bind(this);
  }

  componentDidMount() {
    this.function_retrieve_user_test_battery_profiles();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Function that retrieves the list of test battery profiles of the logged user
  function_retrieve_user_test_battery_profiles() {
    // Sets a reference of the Firestore service
    this.database = app.firestore();

    // Gets all the documents in the 'Test Batteries' collection whose 'PatientID' equals the logged user's identifier
    this.database
      .collection("TestBatteries")
      .where("PatientID", "==", this.context.uid)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          let test_battery_profile_list = [];
          snapshot.forEach((document) => {
            test_battery_profile_list.push(document.data());
          });
          if (this._isMounted) {
            this.setState({ user_test_battery_profiles: test_battery_profile_list });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Function that toggles the option to delete test battery profiles in the test battery list
  function_toggle_option_delete_test_battery_profiles = () => {
    if (this._isMounted) {
      this.setState((prevState) => ({ delete_test_battery_profile_active: !prevState.delete_test_battery_profile_active, test_battery_profile_delete_id: "" }));
    }
  };

  // Function that adds the button of confirmation of deletion of a selected test battery in the test battery list
  function_activate_confirmation_delete_test_battery_profile = (batteryID) => {
    if (this._isMounted) {
      this.setState({ test_battery_profile_delete_id: batteryID });
    }
  };

  // Function that deletes the selected test battery profile from the test battery list
  function_delete_test_battery_profile = (test_battery_id) => {
    this.database = app.firestore();

    this.database
      .collection("TestBatteries")
      .where("PatientID", "==", this.context.uid)
      .where("BatteryID", "==", test_battery_id)
      .limit(1)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((document) => {
            // Gets the data from the Firestore document since the PatientID and the BatteryID are needed to delete the files from Firebase Storage
            let data = document.data();
            // Delete all the files uploaded to Firebase Storage service
            // Gets the Firebase Storage reference
            let storage_reference = firebase.storage().ref();
            // Deletes all the files from the path of Firebase Storage
            storage_reference
              .child(`AcceXibleApp/Audios/${data.PatientID}/${data.BatteryID}`)
              .delete()
              .then(() => {
                // Once all the files from Firebase Storage have been deleted the document in Firestore is removed too
                document.ref.delete();
              })
              .catch((error) => {
                // If there is any error a log appears in the console
                console.log(error);
              });
            document.ref.delete();
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div id="profile_page_patient_version_navigation_profile_list_container" className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
        <div id="profile_page_patient_version_navigation_profile_list_parent_container">
          <div id="profile_page_patient_version_navigation_profile_list_child_container">
            <div id="profile_page_patient_version_navigation_profile_list_subparent_container">
              <div id="profile_page_patient_version_navigation_profile_list_button_container">
                <Button
                  id="profile_page_patient_version_navigation_profile_list_button_delete"
                  onClick={this.function_toggle_option_delete_test_battery_profiles}
                  style={this.state.delete_test_battery_profile_active === false ? { color: "red" } : { color: "gray" }}
                >
                  <FaMinusCircle />
                </Button>
              </div>
              <div id="profile_page_patient_version_navigation_profile_list">
                <div id="profile_page_patient_version_navigation_profile_list_content_wrapper">
                  <div id="profile_page_patient_version_navigation_profile_list_content">
                    <ul>
                      {this.state.user_test_battery_profiles.map((object) => (
                        <li
                          style={this.props.selected_battery_id === object.BatteryID ? { backgroundColor: "#D0DEFF", fontWeight: "900", color: "#515fc9" } : { backgroundColor: "transparent" }}
                          key={object.BatteryID}
                        >
                          {this.state.delete_test_battery_profile_active === false ? (
                            <div className="row profile_page_patient_version_navigation_profile_list_object" onClick={() => this.props.function_select_test_battery_profile(object.BatteryID)}>
                              <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">{object.BatteryID}</div>
                            </div>
                          ) : this.state.test_battery_profile_delete_id === object.BatteryID ? (
                            <div className="row">
                              <div className="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 text-center">{object.BatteryID}</div>
                              <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center">
                                <Button
                                  onClick={this.function_delete_test_battery_profile(object.BatteryID)}
                                  style={{ fontSize: "0.6925vw", height: "100%", backgroundColor: "red", color: "white", padding: 0 }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                <Button
                                  style={{ color: "red", padding: 0, fontSize: "1.25vw", left: 0 }}
                                  onClick={() => {
                                    this.function_activate_confirmation_delete_test_battery_profile(object.BatteryID);
                                  }}
                                >
                                  <FaMinusCircle />
                                </Button>
                              </div>
                              <div className="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">{object.BatteryID}</div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TestBatteryList.contextType = AuthUserContext;

TestBatteryList.propTypes = {
  function_select_test_battery_profile: PropTypes.func.isRequired,
  selected_battery_id: PropTypes.string,
};

export default TestBatteryList;
