const en = {
  common: {
    buttons: {
      close: "CLOSE",
      create_user: "CREATE USER",
      request: "REQUEST",
      send: "SEND",
      sign_in: "SIGN IN",
      start_test_battery: "START TEST BATTERY",
      reload: "RELOAD",
    },
    navigationBar: {
      home: "Home",
      application: "DCL App",
      profile: "Profile",
      sign_in: "Sign in",
      sign_out: "Sign out",
    },
    footer: {
      address: {
        bilbo: {
          city: "BIZKAIA",
          name: "Torre Urduliz - Nagusi Intelligence Center",
          street: "Aita Gotzon, 37",
          postal_code: "48610 Urduliz",
        },
        barcelona: {
          city: "BARCELONA",
          name: "Tech Barcelona",
          street: "Plaça de Pau Vila, 1 Bloc A, Planta 3, Porta 3A1",
          postal_code: "08003 Barcelona",
        },
      },
      contact_us: "CONTACT US",
      contact_us_form_email: "Your email address",
      contact_us_form_explanation: "If you wish to contact us, please, fill the following formulary.",
      contact_us_form_message: "Your message",
      contact_us_form_name: "Your name",
      privacy_policy: {
        header: "Privacy policy",
        explanation:
          '<h1><span style="color: #33cccc;">PRIVACY POLICY</span></h1>' +
          "<p>In accordance with the provisions of Article 13 of Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 regarding the protection of natural persons with regard to the processing of personal data and free movement of these and by which Directive 95/46/CE (General Data Protection Regulation or GDPR) is repealed, and in article 11 of Organic Law 3/2018, of December 5, on Protection of Personal Data and guarantee of digital rights, hereinafter LOPD-GDD, the following information is provided to users:</p>" +
          "<h2>&nbsp;</h2>" +
          '<h2><span style="color: #33cccc;">1. Who is responsible for the processing of your data?</span></h2>' +
          "<p>&nbsp;</p>" +
          '<table border="1" width="566">' +
          "<tbody>" +
          "<tr>" +
          '<td width="198">' +
          "<p><strong>OWNER:</strong></p>" +
          "</td>" +
          '<td width="368">' +
          "<p>Accexible Impacto, S.L.</p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td width="198">' +
          "<p><strong>CIF:</strong></p>" +
          "</td>" +
          '<td width="368">' +
          "<p>B95886198</p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td width="198">' +
          "<p><strong>REGISTERED OFFICE</strong></p>" +
          "</td>" +
          '<td width="368">' +
          "<p>C/Godorniz &nbsp;44,48002, Bilbao</p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td width="198">' +
          "<p><strong>EMAIL</strong></p>" +
          "</td>" +
          '<td width="368">' +
          "<p>support@accexible.com</p>" +
          "</td>" +
          "</tr>" +
          "</tbody>" +
          "</table>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">2. For what purpose do we process your personal data and what is the basis of legitimation that supports it?</span></h2>' +
          "<p>&nbsp;</p>" +
          "<p>In Accexible &nbsp;Impacto, S.L., as Responsible for the processing of your data, we collect and process the personal data you provide us with the following purposes depending on the relationship we have with you:</p>" +
          "<p>&nbsp;</p>" +
          '<table border="1" width="568">' +
          "<tbody>" +
          "<tr>" +
          '<td colspan="3" width="568">' +
          '<p style="text-align: center;"><strong>CUSTOMERS and POTENTIAL CUSTOMERS</strong></p>' +
          "</td>" +
          "</tr>" +
          '<tr style="text-align: center;">' +
          '<td width="246">' +
          "<p><strong>PURPOSE</strong></p>" +
          "</td>" +
          '<td width="141">' +
          "<p><strong>BASIS OF LEGITIMATION</strong></p>" +
          "</td>" +
          '<td width="181">' +
          "<p><strong>CATEGORIES OF DATA PROCESSED</strong></p>" +
          "</td>" +
          "</tr>" +
          '<tr style="text-align: center;">' +
          '<td width="246">' +
          "<p>&nbsp;</p>" +
          "<p>Provision of services: to access the services of Accexible&rsquo;s portal for registered users</p>" +
          "<p>Support for medical diagnosis</p>" +
          "</td>" +
          '<td width="141">' +
          "<p>&nbsp;</p>" +
          "<p>Contractual relationship compliance</p>" +
          "<p>&nbsp;</p>" +
          "</td>" +
          '<td width="181">' +
          "<p>Identification: first and last name</p>" +
          "<p>&nbsp;</p>" +
          "<p>Contact: phone, email</p>" +
          "</td>" +
          "</tr>" +
          '<tr style="text-align: center;">' +
          '<td width="246">' +
          "<p>Administrative management of health services provided</p>" +
          "</td>" +
          '<td width="141">' +
          "<p>Contractual relationship compliance</p>" +
          "</td>" +
          '<td width="181">' +
          "<p>Identification: first and last name</p>" +
          "<p>&nbsp;</p>" +
          "<p>Contact: phone, email</p>" +
          "<p>&nbsp;</p>" +
          "<p>Economic data</p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="246">' +
          "<p>Platform service demo development</p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p>Pre-contractual relationship</p>" +
          "</td>" +
          '<td width="181">' +
          '<p style="text-align: center;">Identification: email</p>' +
          "</td>" +
          "</tr>" +
          "</tbody>" +
          "</table>" +
          "<p>&nbsp;</p>" +
          "<p>&nbsp;</p>" +
          '<table border="1" width="0">' +
          "<tbody>" +
          "<tr>" +
          '<td style="text-align: center;" colspan="3" width="566">' +
          "<p><strong>CONTACT</strong></p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="245">' +
          "<p><strong>PURPOSE</strong></p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p><strong>BASIS OF LEGITIMATION</strong></p>" +
          "</td>" +
          '<td style="text-align: center;" width="179">' +
          "<p><strong>CATEGORIES OF DATA PROCESSED</strong></p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="245">' +
          "<p>Management and information of the services provided by Accexible</p>" +
          "<p>&nbsp;</p>" +
          "<p>Answering questions and queries about Accexible's services</p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p>Consent of the interested party</p>" +
          "</td>" +
          '<td style="text-align: center;" width="179">' +
          "<p>Identification: first and last name</p>" +
          "<p>&nbsp;</p>" +
          "<p>Contact: phone and email</p>" +
          "</td>" +
          "</tr>" +
          "</tbody>" +
          "</table>" +
          "<p>&nbsp;</p>" +
          "<p>&nbsp;</p>" +
          '<table border="1" width="0">' +
          "<tbody>" +
          "<tr>" +
          '<td style="text-align: center;" colspan="3" width="566">' +
          "<p><strong>EMPLOYEMENT</strong></p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="245">' +
          "<p><strong>PURPOSE</strong></p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p><strong>BASIS OF LEGITIMATION</strong></p>" +
          "</td>" +
          '<td style="text-align: center;" width="179">' +
          "<p><strong>CATEGORIES OF DATA PROCESSED</strong></p>" +
          "</td>" +
          "</tr>" +
          "<tr>" +
          '<td style="text-align: center;" width="245">' +
          "<p>Management of candidates by sending the CV in order to assess a possible recruitment</p>" +
          "</td>" +
          '<td style="text-align: center;" width="141">' +
          "<p>&nbsp;</p>" +
          "<p>Unequivocal consent</p>" +
          "</td>" +
          '<td style="text-align: center;" width="179">' +
          "<p>All those included in the CV (identification and contact, academic and employment, etc.)</p>" +
          "</td>" +
          "</tr>" +
          "</tbody>" +
          "</table>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">3. Data processed as Data Processor</span></h2>' +
          "<p>&nbsp;</p>" +
          "<p>In addition, it is possible that, if you have received a link to perform a test of vocal biomarkers of Accexible after consultation with your doctor, we treat your data as processors, &nbsp;since we collect and treat the personal data that you provide us within the framework of the provision of a support service to the diagnosis of your specialist. We will process your voice and the results obtained through the test based on the execution of a contract for the provision of services with your doctor, who is responsible for the processing of the data. If you want more information about the scope of this treatment, we recommend that you consult the Privacy Policy of the Responsible.</p>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">4. How have we obtained your data and how long do we keep your personal data?</span></h2>' +
          "<p>&nbsp;</p>" +
          "<p>The personal data have been provided by you through the forms completed on the website and those obtained in the development of the provision of services, and will be kept for the time necessary to &nbsp;achieve the purposes for which they were collected, and during the legally applicable periods.</p>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">5. To which recipients will your personal data be communicated?</span></h2>' +
          "<p>&nbsp;</p>" +
          "<p>Specifically, the data of our users may be communicated to the following users:</p>" +
          "<ul>" +
          "<li>By virtue of the provisions of the Law, as is the case of the Security Forces and Bodies.</li>" +
          "<li>To the public administrations to which Accexible &nbsp;Impacto, S.L. you must provide information in connection with compliance with legal obligations.</li>" +
          "</ul>" +
          "<p>&nbsp;</p>" +
          '<h2><span style="color: #33cccc;">6. What are your rights when you provide us with your personal data?</span></h2>' +
          "<p>&nbsp;</p>" +
          "<p>We inform you that as an interested party you have the right to access your personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes that were collected.</p>" +
          "<p>In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims.</p>" +
          "<p>In certain circumstances and for reasons related to their particular situation, the interested parties may object to the processing of their data.&nbsp;Accexible &nbsp;Impacto, S.L. stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.</p>" +
          "<p>In certain circumstances, the data subject shall have the right to receive the personal data concerning him or her, which he or she has provided to us, in a structured, commonly used and mechanically readable format, and to transmit them to another data controller.</p>" +
          "<p>Interested persons shall have the right to object, for reasons related to their particular situation and without there being any legitimate reasons that prevail over the interests, rights and freedoms of the interested party, to the personal data concerning them being processed based on the fulfillment of a mission carried out in the public interest or in the exercise of public powers or for the satisfaction of legitimate interests of Accexible &nbsp;Impacto, S.L., including profiling.</p>" +
          '<p>You can exercise your rights in writing by attaching a copy of your ID or documentation that identifies you at the following address: Plaza de Pau Vila, 1 Block A, 3 3 1, 08003 Barcelona &ndash; Barcelona, Spain, or by email to &nbsp;<a href="mailto:support@accexible.com">support@accexible.com.</a></p>' +
          "<p>Likewise, we inform you that, when you have not obtained satisfaction in the exercise of your rights or the way of exercising them, you may file a claim with the Control Authority. If you want to know more information about this right and how to exercise it you can contact the AEPD:</p>" +
          '<p style="text-align: center;"><a href="http://www.aepd.es/">http://www.aepd.es</a>.</p>' +
          '<p style="text-align: center;">&nbsp;Tel. 901 100 099 and 912 663 517</p>' +
          '<p style="text-align: center;">C/ Jorge Juan, 6, 28001 Madrid.</p>',
      },
    },
    errors: {
      patient_exists: "Error creating the user. The email address is already in use by another account.",
      generic_error: "Ha habido un error. Por favor, inténtelo de nuevo más tarde o contacte con un administrador si el problema persiste.",
      permission_error:
        "In order to the web app to work properly, the user must give access permissions to the microphone of the device. Please, delete all the navigation data from the browser and then reload the page by clicking on the button. A message should appear where access permissions can be granted.",
    },
  },
  home: {
    home_header: {
      name: "accexible",
      video_overlay_text: "Speech analysis to detect diseases",
      schedule_demo: "Schedule demo",
      schedule_demo_explanation:
        "Please, select the day and time in which you wish a demonstration of how the acceXible platform works. Once you reserve the time, we will get in touch with you with the details about the virtual demo session.",
      demo_email_address: "Email address",
      demo_date: "Day",
      demo_hour: "Hour",
      email_format_error: "The format of the field 'Email address' is not valid.",
    },
    prevention: {
      header: "Preventive medicine",
      explanation:
        "Accexible identifies vocal biomarkers to detect and monitor diseases. Accexible's alert system allows an early diagnosis. At present, accexible is developing AI models for mental health (depression & anxiety) and health conditions related to mild cognitive impairment (alzheimers, parkinson, ictus and long COVID19).",
      panel_1: "A device with an internet connection and a micro",
      panel_2: "Accexible's speech analysis models",
      panel_3: "Customized results (for patient or doctor) in a couple of minutes",
    },
    application: {
      header: "Dementia and mild cognitive impairment",
      explanation:
        "Accexible's model is based on speech analysis to detect and monitor MCI and dementia and has an accuracy rate of 93%. It is a supporting tool to the specialist, an alert system that can be integrated to healthcare care plans to efficiently detect mild cognitive impairment and mild dementia.",
      hover_to_find_out_more: "Hover to find out more",
      cards: {
        accurate_effective: {
          header: "Accurate and effective",
          explanation: "With a 91% accuracy it reduces error in diagnosis and the time spent to diagnose",
        },
        cost_effective: {
          header: "Cost effective",
          explanation: "Time reduction to detect and monitor dementia",
        },
        quality_life: {
          header: "Quality of life",
          explanation: "Improves the quality of life of people suffering from dementia and their loved ones",
        },
        personalised: {
          header: "Customizable",
          explanation: "Personalized evolution of the cognitive functions",
        },
        accurate: {
          header: "Accurate",
          explanation: "With a 91% accuracy it reduces error in diagnosis",
        },
        evolution: {
          header: "Evolution",
          explanation: "Disease monitoring and alert system",
        },
        digital: {
          header: "Digital",
          explanation: "Scalable and remote application",
        },
      },
    },
    focus: {
      header: "Health Ecosystem & Accexible",
      teleservice: {
        name: "Telemedicine",
        description: "Accexible's platform allows remote detection and monitoring of diseases. It establishes an alert system that can be combined with other telemedicine services.",
      },
      primary_health_care: {
        name: "",
        description: "",
      },
      clinical_trials: {
        name: "Clinical trials",
        description:
          "Accexible is an objective solution that offers a quantitative criterion to screen clinical trials' participants. Additionally, the usability of the platform makes it easy to measure the evolution of the participants during the clinical trial.",
      },
      medical_insurances: {
        name: "Health Insurance & Providers",
        description:
          "Accexible's tools allow to efficiently detect and monitor mild cognitive impairment and dementia. Healthcare providers, medicare advantage plans, and nurse care centers can use our tool to detect dementia and combine the detection results with integrated health programs.",
      },
      day_centres: {
        name: "",
        description: "",
      },
      cognitive_stimulation_apps: {
        name: "",
        description: "",
      },
      clinicians: {
        name: "Clinicians",
        description: "Accexible is a screening tool for mild cognitive impairment and dementia. It is an automated tool that can be used remotely or in person.",
      },
    },
    about_us: {
      header: "About us",
      explanation_1:
        "We are a multidisciplinary team. We have founded Accexible to develop a platform that gives access to as many people as possible to the detection and diagnosis of diseases related to cognitive decline.",
      explanation_2:
        "Our goal is to bring to the market ai models based on vocal biomarkers. Create efficient and easily scalable models so many people have access to a preventive alert system related to cognitive decline diseases.",
      click_to_find_out_more: "Click to find out more",
      team: {
        header: "Team",
        team_members: {
          carla_zaldua_aguirre: {
            name: "Carla Zaldua Aguirre",
            position: "CEO / Cofounder",
            description:
              "Carla has extensive experience in bringing R&D projects to the market. All these projects have in common the use of technology to have a positive impact on Society. She has been named Entrepreneur of the Year in 2020 by the Spanish prestigious elreferente ranking. She has an MSc from the London School of Economics in Communication and Information Systems and graduate studies in Political Science and Sociology.",
          },
          javier_zaldua_aguirre: {
            name: "Javier Zaldua Aguirre",
            position: "CFO / Cofounder",
            description:
              "Before founding Accexible Javi has worked in Banking & Finances and in construction of commercial networks. After 20 years in the financial world he decided to found Accexible in order to apply artificial intelligence as a tool for active and healthy aging. He studied Business Science at the University of Deusto and has a postgraduate degree in Leadership from the IE.",
          },
          tomas_rojo: {
            name: "Tomás Rojo",
            position: "CDO / CTO",
            description: "",
          },
          victoria_de_la_orden: {
            name: "Victoria de la Orden",
            position: "Business Development Manager",
            description: "",
          },
          ania_zuazua: {
            name: "Ania Zuazua",
            position: "Technical Manager",
            description: "",
          },
          xabier_hernando: {
            name: "Xabi Hernando",
            position: "Head of Front-end development",
            description: "",
          },
          peru_gabirondo: {
            name: "Peru Gabirondo",
            position: "Lead Data Scientist",
            description:
              "Graduated in Mathematics, Peru is doing the Master in Statistics for Data Science at Universidad Carlos III de Madrid, where he is being trained in statistical models for data analysis. Furthermore, he has experience in predictive methods related to Time Series Analysis and Machine Learning.",
          },
          patricia_dias: {
            name: "Patricia Dias",
            position: "CMO",
            description: "",
          },
          iker_honorato: {
            name: "Iker Honorato",
            position: "Data Scientist",
            description: "",
          },
          dario_gallego: {
            name: "Darío Gallego",
            position: "Data Scientist",
            description: "",
          },
        },
      },
      advisors: {
        header: "Advisors",
        advisors_members: {
          javier_jimenez: {
            name: "Javier Jiménez",
            position: "AI / Partner",
            description:
              "Javier holds a BSc in Physics and a Master's degree in Artificial Intelligence and Deep Learning. He has more than 3 years of experience working as a consulting data scientist and has been collaborating with acceXible for almost 2 years. He is a specialist in data analysis and Machine Learning.",
          },
          pablo_delaguardia: {
            name: "Pablo de la Guardia",
            position: "Partner / Mathematician",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et imperdiet urna, in rutrum leo. Donec lacus urna, posuere in tellus nec, consectetur fermentum turpis. Proin fringilla volutpat augue vel mattis. Nunc at libero iaculis, luctus nisl quis, fermentum neque. Nam faucibus mi quis blandit tincidunt. Aenean eu arcu a tortor egestas auctor ac nec diam. Nulla sed mauris felis. Fusce ac ultricies ex. Praesent finibus vitae velit nec venenatis. Aliquam lorem felis, ornare eget consequat eget, fringilla a tortor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et imperdiet urna, in rutrum leo. Donec lacus urna, posuere in tellus nec, consectetur fermentum turpis. Proin fringilla volutpat augue vel mattis. Nunc at libero iaculis, luctus nisl quis, fermentum neque. Nam faucibus mi quis blandit tincidunt. Aenean eu arcu a tortor egestas auctor ac nec diam. Nulla sed mauris felis. Fusce ac ultricies ex. Praesent finibus vitae velit nec venenatis. Aliquam lorem felis, ornare eget consequat eget, fringilla a tortor.",
          },
          donald_a_davidoff: {
            name: "Donald A. Davidoff",
            position: "Psychology Harvard Medical School and Chief of Neuropsychology in McLean Hospital",
            description:
              "Donald Davidoff, PhD, is chief of the Neuropsychology Department at McLean Hospital and an assistant professor of psychology in the Department of Psychiatry at Harvard Medical School. Responsibilities include management of clinical and administrative staff, clinical care, and training of post-doctoral fellows and psychiatry residents. He founded the Geriatric Neuropsychiatry Unit and for 16 years was the psychologist in charge.",
          },
          pedro_montejo: {
            name: "Pedro Montejo",
            position: "Doctor in Medicine specialist in psychiatry",
            description:
              "Dr. Pedro Montejo Carrasco is a doctor in medicine and a specialist in psychiatry. He is the head of the Center for the Prevention of Cognitive Impairment, a center founded in 2006 specialised in early detection and cognitive stimulation in cognitive diagnosis. He carries out his professional work in the field of Psychogeriatrics in Madrid Salud (Madrid City Council). For 6 years he has been head of the Health Programs Management Section of the Madrid City Council. He is an expert in the field of evaluation and cognitive stimulation. He is the co-author of the UNAM Memory Training Method, which has been the base for several published documents and more than 50 training courses in the field of psychogeriatrics. Currently, he is participating in studies about memory complaints at different ages and in various researches carried out by the Center for the Prevention of Cognitive Risk in collaboration with hospitals and other centers specialized in early diagnosis of cognitive impairment. He is a professor in various courses, some in Master's degree, about aging, evaluation and detection of impairment, mainly in Psychogerontology and Neuropsychology. Aditionally, he is an author and co-author of several works in the field of memory complaints, early diagnosis of cognitive impairment, memory, etc. Finally, he is a reviewer of scientific papers in national and international journals: SAGE Open Medicine, Annals of Psychology, etc.",
          },
        },
      },
      partnerships: {
        header: "Partnerships & support",
        partners: {
          feder: "ICEX Next Program",
          quiron_salud: "QuirónSalud",
          cdti: "Neotec/CDTI",
          enisa: "Enisa",
          beaz: "BEAZ",
          masschallenge: "Masschallenge",
          aenor: "AENOR - Joven Empresa Innovadora",
          spri: "Grupo SPRI Taldea - Hazitek",
          award: "Healthy Ageing Innovation Award ",
        },
      },
    },
  },
  authentication: {
    sign_in: {
      welcome: "Welcome to Accexible's platform demo.",
      create_account_button: "new user",
      create_account_message: "Don't have an account? Create a",
      email_address: "Email address",
      enter_email_address: "Enter the email address",
      enter_password: "Enter the password",
      invalid_data_error: "The email address and/or the password you entered is incorrect. Please, try again.",
      password: "Password",
    },
    sign_up: {
      accept_terms_and_conditions: "I accept the terms and conditions of use of acceXible",
      email_address_error: "The format of the email address is invalid.",
      enter_repeat_password: "Enter the password again",
      password_error: "The format of the password is invalid. The length of the password must be at least of 8 characters and must contain at least a number, a capital letter and a symbol.",
      password_repeat_error: "The passwords do not match. Please, try again.",
      repeat_password: "Repeat password",
      terms_and_conditions_error: "The terms and conditions of use must be accepted.",
    },
  },
  profile: {
    patient: {
      home_information:
        'Here you have a list of the test batteries you have previously performed. To review any of them tap on any of them. If you wish to delete a test battery profile, tap on the (-) button and select the test battery you want to delete. If you want to start a new test battery tap on the "START TEST BATTERY" button.',
    },
    medical_staff: {
      home_information:
        "Here you have a list of your patients. In order to review any test batteries previously done by a patient tap on one of them. If you wish to create a new patient profile, tap on the (+) button. If you wish to delete a patient profile, tap on the (-) button and select the patient you want to delete.",
    },
    patient_list: {
      new_patient_formulary: {
        information:
          "In order to create a new patient profile please, fill the following form. If you want the patient to do the tests on his/her own please enter a valid email address and its user credentials will be notified by email.",
        patient_identifier: "Patient identifier",
        patient_email_address: "Patient's email address",
        gender: "Gender",
        date_of_birth: "Date of birth",
        level_of_studies: "Level of studies",
        gender_options: {
          male: "Male",
          female: "Female",
        },
        level_of_studies_options: {
          no_education: "No education",
          childhood: "Childhood education",
          primary: "Primary education",
          secondary: "Secondary education",
          high: "Higher education",
        },
        buttons: {
          create: "CREATE",
          close: "CLOSE",
        },
      },
      patient_search: {
        placeholder: "Patient ID",
        patient_search_error: "ID not found. Try again, please.",
      },
    },
    patient_details: {
      patient_information: "Patient information",
      date_of_birth: "Date of birth",
      age: "Age",
      years: "years",
      gender: "Gender",
      level_of_studies: "Level of studies",
      test_battery_list: "Previous test batteries",
      buttons: {
        start_test_battery: "START TEST BATTERY",
      },
    },
    test_battery_details: {
      date: "Date of the battery",
      test_battery_completed: "Battery completed",
      test_battery_uploaded: "Battery uploaded",
      test_battery_result: "Result",
      information: "Information",
      file_list: "File list",
      graphic_score: "Graphic Score",
      show_score: "SHOW",
      test_battery_result_modal: {
        DCL: {
          explanation_1: "Tests administered: Semantic verbal fluency (SVF), Picture description and the open question have been uploaded correctly.",
          explanation_2: "Evaluation results: We are working on validating the detection models for each test. In the coming weeks we will have them ready for you.",
          explanation_3:
            "Below you can see the overall result of the SVF prototype model and a graph with different SVF variables analyzed. It is concluded that the global cognitive performance is " +
            "possibly altered (score less than or equal to the cut-off point for MCI). An in-depth professional evaluation is recommended. The recorded audios with the test answers are available on the platform.",
        },
        non_DCL: {
          explanation_1: "Tests: Semantic verbal fluency (SVF), Picture description and an open question have been uploaded correctly.",
          explanation_2: "Evaluation results:</b> We are working on validating the detection models for each test. In the coming weeks we will have them ready for you.",
          explanation_3:
            "Below you can see the overall result of the SVF prototype model and a graph with different SVF variables analyzed. It is concluded that the global cognitive performance is " +
            "normal (score more than or equal to the cut-off point for MCI). It is recommended, for preventive purposes, repeating the evaluation in approximately 6-12 months.",
          explanation_4: "The recorded audios with the test answers are available on the platform.",
        },
      },
    },
  },
  testBattery: {
    buttons: {
      cancelTestBattery: "Cancel battery",
      close: "CLOSE",
      end: "EXIT",
      uploadFiles: "Upload files",
      finishTestBattery: "FINALISE",
      nextTestStep: "NEXT TEST",
      play: "PLAY",
      startTestBattery: "START TEST BATTERY",
      startTestStep: "START TEST",
    },
    testBatteryExplanation: {
      explanation:
        "This test battery is made up of 3 tests that must be completed consecutively." +
        "<br><br>" +
        "The battery consists of the following tests: semantic verbal fluency, image description and an open question." +
        "<br><br>" +
        "The test battery should be completed in less than 5 minutes.",
      header: "Test battery",
    },
    testStepFinish: {
      next_step_information: "Please, tap the 'Next Test' button to continue",
      participation_thank: "Thank you.",
      step_finalised: "TEST COMPLETED",
      test_finalise_information: "Please, tap the 'Finish' button to continue.",
    },
    testStep: {
      ASVF: {
        explanation_1: "In the next test you will be given two categories or set of things.",
        explanation_2:
          'You will have 1 minute to say words that belong to these two categories alternating (switching back and forth between). For example, if the categories are "trees" and "means of transport", you will have to say first a tree and then a means of transport, in this way: Pine-Train, Apple tree-Bus, Cypress-Bicycle, etc.',
        explanation_3: "The categories are ",
        explanation_4: "SPORTS",
        explanation_5: " and ",
        explanation_6: "FRUITS",
        explanation_7: ". Please, say all the words you can alternating a sport with a fruit.",
        name: "Alternating verbal fluency",
      },
      CERAD_Codify: {
        explanation_1: "In the following test you will be shown 10 words consecutively, one at a time. Read every word out loud.",
        explanation_2: "Read every word out loud. After the words are shown you will hear a sound and after that sound you should try to recall as many words as you can.",
        explanation_3: "In order to end the test tap on the red button",
        interstep_explanation: "Next you will be shown 10 words consecutively, one at a time so please, read every word out loud. Press the button to start.",
        name: "CERAD Word List Memory Test - Word List Learning ",
        word_lists: [
          ["butter", "arm", "shore", "letter", "queen", "cabin", "pole", "ticket", "grass", "engine"],
          ["ticket", "cabin", "butter", "shore", "engine", "arm", "queen", "letter", "pole", "grass"],
          ["queen", "grass", "arm", "cabin", "pole", "shore", "butter", "engine", "ticket", "letter"],
        ],
      },
      CERAD_Recognise: {
        name: "CERAD Word List Memory Test - Delayed Recognition",
        explanation_1: "In the following test a list of words will appear on the screen. Some of them have been part of the list that has been shown to you a few minutes ago, others not.",
        explanation_2: 'State which of them have appeared and which of them not. Please, say only "YES" or "NO".',
        explanation_3: "In order to see the next word tap on the red button.",
        word_list: [
          "church",
          "coffee",
          "butter",
          "dollar",
          "arm",
          "shore",
          "five",
          "letter",
          "hotel",
          "mountain",
          "queen",
          "cabin",
          "shoes",
          "pole",
          "valley",
          "tape",
          "ticket",
          "army",
          "grass",
          "engine",
        ],
      },
      CERAD_Remember: {
        explanation_1: "A little while ago, you were asked to learn and remember a list of words, which you read out one at a time. Now, please recall as many of those 10 words as you can remember.",
        explanation_2: "Tap on the 'Start test' button and after the sound start to list them.",
        explanation_3: "In order to end the test tap on the red button.",
        name: "CERAD Word List Memory Test - Delayed Recall",
      },
      Conversation: {
        explanation: "In the following test you will have one minute to describe a positive experience you have had in the last month. Please, provide as many details as you can.",
        name: "Personal experience",
      },
      Question1: {
        explanation: "What day of the week are we on?",
        commonExplanation1: "In the following test we ask you several questions.",
        commonExplanation2: "Click on the 'Start test' button and after the sound answer it.",
        commonExplanation3: "In order to end the test click on the red button.",
        commonExplanation4: "The question is:",
        name: "Question 1",
      },
      Question2: {
        explanation: "What is the month?",
        commonExplanation2: "Click on the 'Start test' button and after the sound answer it.",
        commonExplanation3: "In order to end the test click on the red button.",
        commonExplanation4: "The question is:",
        name: "Question 2",
      },
      Question3: {
        explanation: "What is the year?",
        commonExplanation2: "Click on the 'Start test' button and after the sound answer it.",
        commonExplanation3: "In order to end the test click on the red button.",
        commonExplanation4: "The question is:",
        name: "Question 3",
      },
      Question4: {
        explanation: "What date is today?",
        commonExplanation2: "Click on the 'Start test' button and after the sound answer it.",
        commonExplanation3: "In order to end the test click on the red button.",
        commonExplanation4: "The question is:",
        name: "Question 4",
      },
      Question5: {
        explanation: "What time is it?",
        commonExplanation2: "Click on the 'Start test' button and after the sound answer it.",
        commonExplanation3: "In order to end the test click on the red button.",
        commonExplanation4: "The question is:",
        name: "Question 5",
      },
      Image_Description: {
        explanation: "In the next test an image will be displayed. Please describe everything you see that is going on in the picture.",
        name: "Picture Description",
      },
      PVF: {
        explanation_1: "In the next test you will have 1 minute to say as many words as you can that begin with a given letter of the alphabet.",
        explanation_2: "None of the words can be names of people, or places, or numbers. Also, do not give the same word with different endings. For example, take, taking, takes",
        explanation_3: "The letter is: ",
        explanation_4: "F",
        name: "Phonetic verbal fluency",
      },
      SVF: {
        explanation:
          "You will have 1 minute to say as many things belonging to a given category as you can. For example, if the category is “flowers”, you could say rose, tulip, daisy, etc. " +
          "<br><br>" +
          "The category is <strong>ANIMALS</strong>. ",

        name: "Semantic verbal fluency",
      },
      Watch_Picture: {
        explanation_1: "In this test you should paint a rounded and big watch with all the hours and the clock hands set at ten to eleven.",
        explanation_2: "Before you start painting the watch you will have a test screen in which you could test whether the pencil works correctly or not.",
        name: "Watch picture test",
      },
    },
    results: {
      explanation:
        "The test battery has ended successfully. Below you can see the list of files produced during the test battery. In order to get the result, please UPLOAD the files to our service. IMPORTANT: before closing the window, click on the UPLOAD FILES button and wait until all the files are uploaded.",
      header: "Test battery results",
      results_table: {
        header: {
          file_name: "File",
          upload_progress: "Upload progress",
        },
        body: {
          test_1: "Test 1",
          test_2: "Test 1",
          test_3: "Test 3",
          test_4: "Test 4",
          test_5: "Test 5",
          test_6: "Test 6",
          test_7: "Test 7",
          test_8: "Test 8",
          test_9: "Test 9",
          test_10: "Test 10",
          test_11: "Test 11",
          test_12: "Test 12",
          test_13: "Test 13",
          test_14: "Test 14",
          test_15: "Test 15",
          test_16: "Test 16",
          test_17: "Test 17",
          test_18: "Test 18",
          test_19: "Test 19",
          test_20: "Test 20",
          unique: "Unique",
          upload_error: "Error uploading file",
        },
      },
    },
  },
};

export default en;
