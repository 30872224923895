// Import libraries
import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Form } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, CircularProgress } from "@material-ui/core";
import { FaPlusCircle, FaMinusCircle, FaSearch, FaEraser } from "react-icons/fa";

// Import Firebase
import app from "firebase/app";
import * as firebase from "firebase/app";
import "firebase/firestore";

// Import authentication context
import AuthUserContext from "../../../../../contexts/session/index";

// Import high order components
import { withFirebase } from "../../../../../contexts/firebase/index";
import { withLanguage } from "../../../../../contexts/language/index";

// Import CSS
import "../../../../../styles/components/private/profile/navigation_components/medical_staff_version/PatientList.css";

class PatientList extends React.Component {
  constructor() {
    super();
    this.state = {
      user_patient_profiles: [],
      current_patient_profiles: [],
      delete_patient_profile_active: false,
      patient_profile_delete_id: "",
      new_patient_profile_modal: false,
      patient_identifier: "",
      patient_email_address: "",
      patient_email_format_error: false,
      patient_gender: 0,
      patient_date_of_birth: Date.now(),
      patient_level_of_studies: 0,
      creation_date: Date.now(),
      creating_patient_profile_loading: false,
      error_creating_patient_profile: false,
      error_creating_patient_exists: false,
      patient_search_id: "",
      searched_patient: [],
      patient_search_error: false,
    };

    this.function_open_new_patient_profile_modal = this.function_open_new_patient_profile_modal.bind(this);
    this.function_close_new_patient_profile_modal = this.function_close_new_patient_profile_modal.bind(this);
    this.function_on_change = this.function_on_change.bind(this);
    this.function_select_date_of_birth = this.function_select_date_of_birth.bind(this);
    this.function_create_new_patient_profile = this.function_create_new_patient_profile.bind(this);
    this.function_activate_option_delete_patient = this.function_activate_option_delete_patient.bind(this);
    this.function_change_search_id = this.function_change_search_id.bind(this);
    this.function_search_patient = this.function_search_patient.bind(this);
    this.function_clean_search = this.function_clean_search.bind(this);
  }

  componentDidMount() {
    this.function_retrieve_user_patient_profiles();
  }

  function_retrieve_user_patient_profiles() {
    this.database = app.firestore();
    this.database
      .collection("Patients")
      .where("UserID", "==", this.context.uid)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          let patient_profile_list = [];
          snapshot.forEach((document) => {
            patient_profile_list.push(document.data());
          });
          const new_profile_list = patient_profile_list.map(({ creationDate: CreationDate, ...rest }) => ({
            CreationDate,
            ...rest,
          }));
          const profile_list = new_profile_list.sort(function (a, b) {
            return (
              b.CreationDate !== undefined &&
              b.CreationDate !== null &&
              a.CreationDate !== undefined &&
              a.CreationDate !== null &&
              new Date(b.CreationDate.toString().includes(".") ? b.CreationDate : b.CreationDate / 1000) - new Date(a.CreationDate.toString().includes(".") ? a.CreationDate : a.CreationDate / 1000)
            );
          });
          this.setState({ user_patient_profiles: profile_list, current_patient_profiles: profile_list });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function_open_new_patient_profile_modal = () => {
    let max = 999999;
    let min = 0;
    let randomPatientID = String(Math.floor(Math.random() * (max - min + 1)) + min).padStart(6, "0");

    // check if patientID exists
    this.database = app.firestore();
    this.database
      .collection("Patients")
      .where("PatientID", "==", randomPatientID)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          this.setState({
            new_patient_profile_modal: true,
            patient_identifier: randomPatientID,
          });
        } else {
          this.function_open_new_patient_profile_modal(); // patientID exists so we call the function again
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function_close_new_patient_profile_modal = () => {
    this.setState({ new_patient_profile_modal: false, error_creating_patient_profile: false, error_creating_patient_exists: false });
  };

  function_on_change = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  function_select_date_of_birth = (date) => {
    let date_of_birth = new Date(date);
    this.setState({ patient_date_of_birth: date_of_birth.getTime() });
  };

  function_change_search_id = (event) => {
    this.setState({ patient_search_id: event.target.value, patient_search_error: false });
  };

  function_search_patient = () => {
    if (this.state.patient_search_id) {
      let searchedPatient = this.state.user_patient_profiles.filter((item) => item.CustomPatientID === this.state.patient_search_id);
      if (searchedPatient.length) {
        this.setState({ current_patient_profiles: searchedPatient });
        this.props.function_select_patient_profile(null);
      } else {
        this.setState({ patient_search_error: true });
      }
    }
  };

  function_clean_search = () => {
    this.setState({ current_patient_profiles: this.state.user_patient_profiles, patient_search_id: "", patient_search_error: false });
    this.props.function_select_patient_profile(null);
  };

  function_create_new_patient_profile = () => {
    this.setState({ error_creating_patient_profile: false, error_creating_patient_exists: false });
    this.props.function_select_patient_profile(null);
    if (this.state.patient_email_address !== "") {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.patient_email_address)
      ) {
        let logged_user_uid = this.context.uid;

        this.setState({ creating_patient_profile_loading: true });

        const database = firebase.firestore();

        this.database
          .collection("Users")
          .where("UserID", "==", this.context.uid)
          .limit(1)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              this.setState({ creating_patient_profile_loading: false });
            } else {
              let logged_user_data = null;

              let new_user_password = this.function_generate_random_password();

              snapshot.forEach((document) => {
                logged_user_data = document.data();
              });

              this.props.firebase
                .CreateUserWithEmailAndPassword(this.state.patient_email_address, new_user_password)
                .then((authUser) => {
                  this.props.firebase
                    .SignInWithEmailAndPassword(logged_user_data.Username, logged_user_data.Password)
                    .then(() => {
                      database
                        .collection("Users")
                        .add({
                          MedicalStaff: false,
                          UserID: authUser.user.uid,
                          MedicalStaffID: logged_user_uid,
                          Username: this.state.patient_email_address,
                          Password: new_user_password,
                        })
                        .then(() => {
                          database
                            .collection("Patients")
                            .add({
                              ID: this.function_create_uuid_v4(),
                              CustomPatientID: this.state.patient_identifier,
                              PatientID: authUser.user.uid,
                              UserID: logged_user_uid,
                              CreationDate: Date.now(),
                              DateOfBirth: this.state.patient_date_of_birth,
                              LevelOfStudies: this.state.patient_level_of_studies,
                              Sex: this.state.patient_gender,
                            })
                            .then(() => {
                              this.setState({
                                new_patient_profile_modal: false,
                                patient_identifier: "",
                                patient_email_address: "",
                                patient_email_format_error: false,
                                patient_gender: 0,
                                patient_date_of_birth: Date.now(),
                                patient_level_of_studies: 0,
                                creation_date: Date.now(),
                                creating_patient_profile_loading: false,
                                error_creating_patient_profile: false,
                                error_creating_patient_exists: false,
                              });
                              this.function_retrieve_user_patient_profiles();
                            })
                            .catch((error) => {
                              this.setState({
                                creating_patient_profile_loading: false,
                                error_creating_patient_profile: true,
                              });
                            });
                        })
                        .catch((error) => {
                          this.setState({
                            creating_patient_profile_loading: false,
                            error_creating_patient_profile: true,
                          });
                        });
                    })
                    .catch((error) => {
                      this.setState({
                        creating_patient_profile_loading: false,
                        error_creating_patient_profile: true,
                      });
                    });
                })
                .catch((error) => {
                  if (error.code === "auth/email-already-in-use") {
                    this.setState({
                      creating_patient_profile_loading: false,
                      error_creating_patient_exists: true,
                    });
                  } else {
                    this.setState({
                      creating_patient_profile_loading: false,
                      error_creating_patient_profile: true,
                    });
                  }
                });
            }
          })
          .catch((error) => {
            this.setState({
              creating_patient_profile_loading: false,
              error_creating_patient_profile: true,
            });
          });
      } else {
        this.setState({
          patient_email_format_error: true,
          creating_patient_profile_loading: false,
        });
      }
    } else {
      this.database
        .collection("Patients")
        .add({
          ID: this.function_create_uuid_v4(),
          CustomPatientID: this.state.patient_identifier,
          PatientID: this.state.patient_identifier,
          UserID: this.context.uid,
          DateOfBirth: this.state.patient_date_of_birth,
          CreationDate: this.state.creation_date,
          LevelOfStudies: this.state.patient_level_of_studies,
          Sex: this.state.patient_gender,
        })
        .then(() => {
          this.setState({
            new_patient_profile_modal: false,
            patient_identifier: "",
            patient_email_address: "",
            patient_email_format_error: false,
            patient_gender: 0,
            patient_date_of_birth: Date.now(),
            patient_level_of_studies: 0,
            creation_date: Date.now(),
            creating_patient_profile_loading: false,
            error_creating_patient_profile: false,
            error_creating_patient_exists: false,
          });
          this.function_retrieve_user_patient_profiles();
        })
        .catch((error) => {
          this.setState({
            creating_patient_profile_loading: false,
            error_creating_patient_profile: true,
          });
        });
    }
  };

  function_create_uuid_v4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
  }

  function_generate_random_password() {
    var length = 10,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  function_activate_option_delete_patient = () => {
    this.setState((prevState) => ({
      delete_patient_profile_active: !prevState.delete_patient_profile_active,
      test_battery_profile_delete_id: "",
    }));
  };

  function_activate_option_delete_patient_object = (batteryID) => {
    this.setState({ patient_profile_delete_id: batteryID });
  };

  // Function that deletes the selected patient profile from the patient list and all the test battery profiles and the files uploaded to Firebase Storage
  function_delete_patient_profile = (patient_id) => {
    // Sets a reference to the Firestore service
    this.props.function_select_patient_profile(null);
    this.database = app.firestore();

    // Gets the document attached to the patient in the 'Patients' collection
    this.database
      .collection("Patients")
      .where("PatientID", "==", patient_id)
      .limit(1)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((patient_document) => {
            // Gets all the test battery profiles linked to the patient
            this.database
              .collection("TestBatteries")
              .where("PatientID", "==", patient_id)
              .get()
              .then((snapshot) => {
                if (!snapshot.empty) {
                  snapshot.forEach((test_battery_document) => {
                    // Gets the data from the Firestore document since the PatientID and the BatteryID are needed to delete the files from Firebase Storage
                    let data = test_battery_document.data();

                    // Delete all the files uploaded to Firebase Storage service

                    // Gets the Firebase Storage reference
                    let storage_reference = firebase.storage().ref();

                    // Deletes all the files from the path of Firebase Storage
                    storage_reference
                      .child(`AcceXibleApp/Audios/${data.PatientID}/${data.BatteryID}`)
                      .delete()
                      .then(() => {
                        // Once all the files from Firebase Storage have been deleted the document in Firestore is removed too
                        test_battery_document.ref.delete();
                      })
                      .catch((error) => {
                        // If there is any error a log appears in the console
                        console.log(error);
                      });
                  });

                  // Once all the test battery profiles and the Storage files have been deleted the patient profile is deleted
                  patient_document.ref.delete();

                  // Retrieves all the patient profiles
                  this.function_retrieve_user_patient_profiles();
                } else {
                  // If no test battery profiles have been found the patient profile is deleted
                  patient_document.ref.delete();

                  // Retrieves all the patient profiles
                  this.function_retrieve_user_patient_profiles();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.function_activate_option_delete_patient();
    this.props.function_select_patient_profile(null);
  };

  render() {
    return (
      <div id="profile_page_medical_staff_version_navigation_profile_list_container" className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
        <div id="profile_page_medical_staff_version_navigation_profile_list_parent_container">
          <div id="profile_page_medical_staff_version_navigation_profile_list_child_container">
            <div id="profile_page_medical_staff_version_navigation_profile_list_subparent_container">
              <div id="profile_page_medical_staff_version_navigation_profile_list_button_container">
                <div>
                  <Button id="profile_page_patient_version_navigation_profile_list_button_new" onClick={this.function_open_new_patient_profile_modal} style={{ color: "blue" }}>
                    <FaPlusCircle />
                  </Button>
                  <Button
                    id="profile_page_medical_staff_version_navigation_profile_list_button_delete"
                    onClick={this.function_activate_option_delete_patient}
                    style={this.state.delete_patient_profile_active === false ? { color: "red" } : { color: "gray" }}
                  >
                    <FaMinusCircle />
                  </Button>
                </div>
                <div id="patient_search">
                  <input
                    id="patient_search_id"
                    name="patient_search_id"
                    value={this.state.patient_search_id}
                    onChange={this.function_change_search_id}
                    maxLength="6"
                    placeholder={this.props.language.retrieve_string("profile.patient_list.patient_search.placeholder")}
                  />
                  <Button onClick={this.function_search_patient} style={{ color: "blue" }}>
                    <FaSearch />
                  </Button>
                  <Button onClick={this.function_clean_search} style={{ color: "red" }}>
                    <FaEraser />
                  </Button>
                </div>
                {this.state.patient_search_error && <div id="patient_search_error">{this.props.language.retrieve_string("profile.patient_list.patient_search.patient_search_error")}</div>}
              </div>
              <div id="profile_page_medical_staff_version_navigation_profile_list">
                <div id="profile_page_medical_staff_version_navigation_profile_list_content_wrapper">
                  <div id="profile_page_medical_staff_version_navigation_profile_list_content">
                    <ul>
                      {this.state.current_patient_profiles.map((object) => (
                        <li
                          style={
                            this.props.selected_patient_id === object.PatientID
                              ? {
                                  backgroundColor: "#D0DEFF",
                                  fontWeight: "900",
                                  color: "#515fc9",
                                }
                              : { backgroundColor: "transparent" }
                          }
                          key={object.ID}
                        >
                          {this.state.delete_patient_profile_active === false ? (
                            <div className="row profile_page_medical_staff_version_navigation_profile_list_object" onClick={() => this.props.function_select_patient_profile(object.PatientID)}>
                              <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">{object.CustomPatientID}</div>
                            </div>
                          ) : this.state.patient_profile_delete_id === object.CustomPatientID ? (
                            <div className="row">
                              <div className="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 text-center">{object.CustomPatientID}</div>
                              <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center">
                                <Button
                                  onClick={() => this.function_delete_patient_profile(object.PatientID)}
                                  style={{
                                    fontSize: "0.6925vw",
                                    height: "100%",
                                    backgroundColor: "red",
                                    color: "white",
                                    padding: 0,
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                <Button
                                  style={{
                                    color: "red",
                                    padding: 0,
                                    fontSize: "1.25vw",
                                    left: 0,
                                  }}
                                  onClick={() => {
                                    this.function_activate_option_delete_patient_object(object.CustomPatientID);
                                  }}
                                >
                                  <FaMinusCircle />
                                </Button>
                              </div>
                              <div className="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">{object.CustomPatientID}</div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog open={this.state.new_patient_profile_modal} onClose={this.function_close_new_patient_profile_modal}>
          <div>
            <DialogContent>
              <DialogContentText id="new_patient_profile_modal_explanation" className="text-justify">
                {this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.information")}
              </DialogContentText>
              <Form>
                <FormControl fullWidth style={{ marginBottom: "1vw" }}>
                  <TextField
                    disabled={true}
                    id="standard-basic"
                    label={this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.patient_identifier")}
                    name="patient_identifier"
                    style={{ backgroundColor: "transparent" }}
                    value={this.state.patient_identifier}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "1vw" }}>
                  <TextField
                    error={this.state.patient_email_format_error}
                    id="standard-basic"
                    label={this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.patient_email_address")}
                    name="patient_email_address"
                    onChange={this.function_on_change}
                    helperText={this.state.email_format_error ? this.props.language.retrieve_string("home.home_header.email_format_error") : null}
                    style={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "1vw" }}>
                  <InputLabel>{this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.gender")}</InputLabel>
                  <Select labelId="demo-simple-select-label" value={this.state.patient_gender} name="patient_gender" onChange={this.function_on_change}>
                    <MenuItem value={0}>{this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.gender_options.male")}</MenuItem>
                    <MenuItem value={1}>{this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.gender_options.female")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "1vw" }}>
                  <KeyboardDatePicker
                    label={this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.date_of_birth")}
                    disableToolbar
                    variant="inline"
                    format={this.props.language.language === "es-ES" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                    margin="normal"
                    id="date-picker-inline"
                    value={this.state.patient_date_of_birth}
                    onChange={this.function_select_date_of_birth}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    style={{ display: "inline-flex", width: "100%" }}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "1vw" }}>
                  <InputLabel>{this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.level_of_studies")}</InputLabel>
                  <Select labelId="demo-simple-select-label" value={this.state.patient_level_of_studies} name="patient_level_of_studies" onChange={this.function_on_change}>
                    <MenuItem value={0}>{this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.level_of_studies_options.no_education")}</MenuItem>
                    <MenuItem value={1}>{this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.level_of_studies_options.childhood")}</MenuItem>
                    <MenuItem value={2}>{this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.level_of_studies_options.primary")}</MenuItem>
                    <MenuItem value={3}>{this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.level_of_studies_options.secondary")}</MenuItem>
                    <MenuItem value={4}>{this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.level_of_studies_options.high")}</MenuItem>
                  </Select>
                </FormControl>
                {this.state.error_creating_patient_exists && <p className="new_patient_profile_modal_error">{this.props.language.retrieve_string("common.errors.patient_exists")}</p>}
                {this.state.error_creating_patient_profile && <p className="new_patient_profile_modal_error">{this.props.language.retrieve_string("common.errors.generic_error")}</p>}
              </Form>
            </DialogContent>
            <DialogActions>
              <div className="row mx-auto">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                  <Button id="new_patient_profile_modal_create_button" onClick={this.function_create_new_patient_profile}>
                    {this.state.creating_patient_profile_loading && (
                      <CircularProgress
                        style={{
                          color: "#f8f8ff",
                          width: "2.25vw",
                          height: "2.25vw",
                        }}
                      />
                    )}
                    {!this.state.creating_patient_profile_loading && this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.buttons.create")}
                  </Button>
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                  <Button id="new_patient_profile_modal_close_button" onClick={this.function_close_new_patient_profile_modal}>
                    {this.props.language.retrieve_string("profile.patient_list.new_patient_formulary.buttons.close")}
                  </Button>
                </div>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}

PatientList.contextType = AuthUserContext;

PatientList.propTypes = {
  function_select_patient_profile: PropTypes.func.isRequired,
  selected_patient_id: PropTypes.string,
};

export default compose(withLanguage, withFirebase)(PatientList);
