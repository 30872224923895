import React from 'react';

// Import contexts
import { LanguageConsumer } from '../../../contexts/language/context/LanguageContext';

function LanguageSettings() {
    return (
        <LanguageConsumer>
            {({ language, setLanguage }) => (
                <div id="widget_navbar_language_settings_container">
                    <button className={language === "es-ES" ? 'btn bg-transparent widget_navbar_language_settings_language_button widget_navbar_language_settings_language_button_selected' : 'btn bg-transparent widget_navbar_language_settings_language_button'} onClick={(event) => {
                        event.persist();
                        setLanguage('es-ES');
                    }} data-language='es-ES'>
                        ESP
                    </button>
                    <button className={language === "en-UK" ? 'btn bg-transparent widget_navbar_language_settings_language_button widget_navbar_language_settings_language_button_selected' : 'btn bg-transparent widget_navbar_language_settings_language_button'} onClick={(event) => {
                        event.persist();
                        setLanguage('en-UK');
                    }} data-language='en-UK'>
                        ENG
                    </button>
                </div>
            )}
        </LanguageConsumer>
    )
}

export default LanguageSettings;