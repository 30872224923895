// Import libraries
import React from "react";
import { Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FaLinkedinIn, FaTwitter, FaEnvelopeOpenText } from "react-icons/fa";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, Button } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";

// Import Firebase
import * as firebase from "firebase/app";
import "firebase/firestore";

// Import high order components
import { withLanguage } from "../../../contexts/language/index";

// Import resources
import logo from "../../../resources/images/logo/accexible_logo.png";

// Import CSS
import "../../../styles/widgets/footer/Footer.css";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      show_contact_us_modal: false,
      show_privacy_policy_modal: false,
      name: "",
      email_address: "",
      message: "",
      email_format_error: false,
      email_send_loading: false,
    };

    this.function_open_contact_us_modal_view = this.function_open_contact_us_modal_view.bind(this);
    this.function_close_contact_us_modal_view = this.function_close_contact_us_modal_view.bind(this);
    this.function_open_privacy_policy_modal_view = this.function_open_privacy_policy_modal_view.bind(this);
    this.function_close_privacy_policy_modal_view = this.function_close_privacy_policy_modal_view.bind(this);
    this.function_on_change = this.function_on_change.bind(this);
  }

  function_open_contact_us_modal_view() {
    this.setState({ show_contact_us_modal: true });
  }

  function_close_contact_us_modal_view() {
    this.setState({ show_contact_us_modal: false });
  }

  function_open_privacy_policy_modal_view() {
    this.setState({ show_privacy_policy_modal: true });
  }

  function_close_privacy_policy_modal_view() {
    this.setState({ show_privacy_policy_modal: false });
  }

  function_on_change = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  function_submit_contact_us_formulary = () => {
    this.setState({ email_send_loading: true, email_format_error: false });

    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email_address)) {
      const database = firebase.firestore();

      database
        .collection("ContactUs")
        .add({
          name: this.state.name,
          email_address: this.state.email_address + " enviado desde demo.accexible.com",
          message: this.state.message,
        })
        .then(() => {
          this.setState({
            show_contact_us_modal: false,
            email_send_loading: false,
          });
        })
        .catch((error) => {
          this.setState({ email_send_loading: false });
          console.error("Error adding document: ", error);
        });
    } else {
      this.setState({ email_format_error: true, email_send_loading: false });
    }
  };

  render() {
    return (
      <div className="widget_footer_container">
        <footer className="container-fluid">
          <div id="widget_footer_primary_container" className="row d-flex container-fluid">
            <div className="col-12 col-xs-12 col-md-12 col-lg-3 col-xl-3">
              <div className="container d-flex h-100 justify-content-center align-self-center">
                <div className="widget_footer_brand_image row justify-content-center align-self-center">
                  <img src={logo} alt="acceXible logo" />
                </div>
              </div>
            </div>

            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-3">
              <div className="footer_address_container container d-flex h-100">
                <div className="widget_footer_address_container row justify-content-center align-self-center">
                  <ul>
                    <li>
                      <strong>{this.props.language.retrieve_string("common.footer.address.barcelona.city")}</strong>
                    </li>
                    <li>{this.props.language.retrieve_string("common.footer.address.barcelona.name")}</li>
                    <li>{this.props.language.retrieve_string("common.footer.address.barcelona.street")}</li>
                    <li>{this.props.language.retrieve_string("common.footer.address.barcelona.postal_code")}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-3">
              <div className="footer_address_container container d-flex h-100">
                <div className="widget_footer_address_container row justify-content-center align-self-center">
                  <ul>
                    <li>
                      <strong>{this.props.language.retrieve_string("common.footer.address.bilbo.city")}</strong>
                    </li>
                    <li>{this.props.language.retrieve_string("common.footer.address.bilbo.name")}</li>
                    <li>{this.props.language.retrieve_string("common.footer.address.bilbo.street")}</li>
                    <li>{this.props.language.retrieve_string("common.footer.address.bilbo.postal_code")}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="widget_footer_hyperlinks_container justify-content-center align-items-center col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
              <Button id="widget_footer_contact_us_button" onClick={this.function_open_contact_us_modal_view}>
                {this.props.language.retrieve_string("common.footer.contact_us")}
                <FaEnvelopeOpenText id="widget_footer_contact_us_button_image"></FaEnvelopeOpenText>
              </Button>
              <a href="https://es.linkedin.com/company/accexible" rel="noopener noreferrer" target="_blank" className="widget_footer_hyperlink_outside_link">
                <FaLinkedinIn />
              </a>
              <a href="https://twitter.com/accexible" rel="noopener noreferrer" target="_blank" className="widget_footer_hyperlink_outside_link">
                <FaTwitter />
              </a>
            </div>
          </div>
          <div id="widget_footer_secondary_container" className="row container-fluid mx-auto align-content-center justify-content-center">
            <div>
              <span>
                <strong>© 2024 acceXible</strong>
              </span>
              <span className="widget_footer_secondary_interpunct">•</span>
              <span>
                <Button id="widget_footer_privacy_policy_button" onClick={this.function_open_privacy_policy_modal_view}>
                  {this.props.language.retrieve_string("common.footer.privacy_policy.header")}
                </Button>
              </span>
            </div>
          </div>
        </footer>

        <Dialog open={this.state.show_contact_us_modal} onClose={this.function_close_contact_us_modal_view}>
          <DialogTitle className="mx-auto text-center" style={{ color: "#515fc9", fontWeight: "500" }}>
            {this.props.language.retrieve_string("common.footer.contact_us")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="widget_footer_contact_us_form_explanation" className="text-justify">
              {this.props.language.retrieve_string("common.footer.contact_us_form_explanation")}
            </DialogContentText>
            <Form>
              <FormControl fullWidth style={{ marginBottom: "1vw" }}>
                <TextField
                  id="standard-basic"
                  label={this.props.language.retrieve_string("common.footer.contact_us_form_name")}
                  name="name"
                  onChange={this.function_on_change}
                  style={{ backgroundColor: "transparent" }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: "1vw" }}>
                <TextField
                  error={this.state.email_format_error}
                  id="standard-basic"
                  label={this.props.language.retrieve_string("authentication.sign_in.email_address")}
                  name="email_address"
                  onChange={this.function_on_change}
                  helperText={this.state.email_format_error ? this.props.language.retrieve_string("home.home_header.email_format_error") : null}
                  style={{ backgroundColor: "transparent" }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: "1vw" }}>
                <TextField
                  id="standard-basic"
                  label={this.props.language.retrieve_string("common.footer.contact_us_form_message")}
                  name="message"
                  onChange={this.function_on_change}
                  style={{ backgroundColor: "transparent" }}
                />
              </FormControl>
            </Form>
          </DialogContent>
          <DialogActions>
            <div className="row mx-auto" style={{ width: "100%" }}>
              <div className="col-6 col-xs-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                <Button id="widget_footer_contact_us_formulary_send_button" variant="contained" onClick={this.function_submit_contact_us_formulary} disabled={this.state.email_send_loading}>
                  {this.state.email_send_loading && (
                    <CircularProgress
                      style={{
                        color: "#f8f8ff",
                        width: "1.75rem",
                        height: "1.75rem",
                      }}
                    />
                  )}
                  {!this.state.email_send_loading && this.props.language.retrieve_string("common.buttons.send")}
                </Button>
              </div>
              <div className="col-6 col-xs-6 col-md-6 col-xl-6 col-lg-6 mx-auto">
                <Button id="widget_footer_contact_us_formulary_close_button" onClick={this.function_close_contact_us_modal_view}>
                  {this.props.language.retrieve_string("common.buttons.close")}
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.show_privacy_policy_modal} onClose={this.function_close_privacy_policy_modal_view}>
          <DialogTitle
            className="mx-auto text-center"
            style={{
              color: "#515fc9",
              fontWeight: "500",
              fontFamily: '"Montserrat", sans-serif',
            }}
          ></DialogTitle>
          <DialogContent>
            <DialogContentText id="widget_footer_privacy_policy_explanation" className="text-justify">
              <p>{ReactHtmlParser(this.props.language.retrieve_string("common.footer.privacy_policy.explanation"))}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="row mx-auto justify-content-center" style={{ width: "100%" }}>
              <div className="col-12 col-xs-12 col-md-12 col-xl-12 col-lg-12 mx-auto">
                <Button id="widget_footer_privacy_policy_close_button" onClick={this.function_close_privacy_policy_modal_view}>
                  {this.props.language.retrieve_string("common.buttons.close")}
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withLanguage(Footer);
